/**
 * Format bytes into compact readable string.
 *
 * @param bytes: number
 * @param decimals: number
 * @returns: string
 */
export function formatBytes(bytes: number, decimals = 2): string {
  if (bytes === 0) return '0 KB';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function getFileExtension(filename: string): string {
  let re = /(?:\.([^.]+))?$/;
  return re.exec(filename)[1];
}
