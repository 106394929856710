<div id="settings-container">
  <div class="spacer"></div>

  <h2>Update user information</h2>
  <form [formGroup]="updateUserForm" #formDirective="ngForm" (ngSubmit)="updateUserButtonClick(formDirective)">

    <mat-form-field class="user-input" appearance="fill">
      <mat-label>Given name</mat-label>
      <input matInput formControlName="givenName" placeholder="Given Name">
    </mat-form-field>

    <br>

    <mat-form-field class="user-input" appearance="fill">
      <mat-label>Surname</mat-label>
      <input matInput formControlName="surname" placeholder="Surname">
    </mat-form-field>
    <br>


    <button *ngIf="!isLoading; else loadingSpinner" mat-flat-button color="primary" type="submit">Update your
      information</button>
  </form>


  <div class="spacer"></div>
  <div class="spacer"></div>

  <h2>Change password</h2>

  <form [formGroup]="changePasswordForm" #formDirectivePassword="ngForm"
    (ngSubmit)="changePasswordButtonClick(formDirectivePassword)"
   >

    <mat-form-field class="user-input" appearance="fill">
      <mat-label>Current password</mat-label>
      <input matInput [type]="hidePassword ? 'password' : 'text'" type="password" placeholder="Current password"
        formControlName="currentPassword">
      <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}
      </mat-icon>
    </mat-form-field>


    <div *ngFor="let error of changePasswordErrors" class="error-message animation">
      {{error}}
    </div>

    <br>

    <mat-form-field class="user-input" appearance="fill">
      <mat-label>New password</mat-label>
      <input matInput [type]="hidePassword ? 'password' : 'text'" type="password" placeholder="New password"
        formControlName="newPassword">
      <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}
      </mat-icon>
    </mat-form-field>

    <mat-form-field class="user-input" appearance="fill">
      <mat-label>Repeat new password</mat-label>
      <input matInput [type]="hidePassword ? 'password' : 'text'" placeholder="Repeat new password"
        formControlName="repeatPassword">
      <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}
      </mat-icon>
    </mat-form-field>

    <div class="error-message animation"
      *ngIf="(changePasswordForm.dirty) && ( changePasswordForm.value.repeatPassword !== '' ) && (changePasswordForm.value.newPassword !== changePasswordForm.value.repeatPassword)">
      Passwords doesn't match.
    </div>

    <div *ngIf="changePasswordForm.dirty; then dirtyPassword"></div>

    <br>

    <div *ngIf="changePasswordSuccess" class="success-message animation">
      Password changed successfully!
      <div class="spacer"></div>
    </div>

    <button mat-flat-button color="primary" type="submit"
      [disabled]="(changePasswordForm.invalid) || (changePasswordForm.value.newPassword !== changePasswordForm.value.repeatPassword)">Change
      password</button>
  </form>

</div>

<!-- Templates -->

<!-- Templates for password strength annimation -->
<ng-template #dirtyPassword>
  <div class="pw-meter-flex-container animation">
    <div class="pw-meter-container"
      *ngIf="passwordStrength(changePasswordForm.value.newPassword, passwordLength) > 0; then strongPw else weakPw">
    </div>
    <div class="pw-meter-container"
      *ngIf="passwordStrength(changePasswordForm.value.newPassword, passwordLength) > 1; then strongPw else weakPw">
    </div>
    <div class="pw-meter-container"
      *ngIf="passwordStrength(changePasswordForm.value.newPassword, passwordLength) > 2; then strongPw else weakPw">
    </div>
    <div class="pw-meter-container"
      *ngIf="passwordStrength(changePasswordForm.value.newPassword, passwordLength) > 3; then strongPw else weakPw">
    </div>
  </div>

  <mat-list class="animation">
    <mat-list-item>
      <div *ngIf="isLongerThanN(changePasswordForm.value.newPassword, passwordLength); then check else uncheck"></div>
      Is at least eight characters long
    </mat-list-item>
    <mat-list-item>
      <div *ngIf="containsLowerCase(changePasswordForm.value.newPassword); then check else uncheck"></div>
      Contain at least one lower case character
    </mat-list-item>
    <mat-list-item>
      <div *ngIf="containsUpperCase(changePasswordForm.value.newPassword); then check else uncheck"></div>
      Contain at least one upper case character
    </mat-list-item>
    <mat-list-item>
      <div *ngIf="containsNumber(changePasswordForm.value.newPassword); then check else uncheck"></div>
      Contain at least one number
    </mat-list-item>
  </mat-list>
</ng-template>

<!-- Templates for icon on list -->
<ng-template #check>
  <mat-icon class='check'>done</mat-icon>
</ng-template>

<ng-template #uncheck>
  <mat-icon class="uncheck">clear</mat-icon>
</ng-template>

<!-- Tamplates for password strength -->
<ng-template #strongPw>
  <div class="strong-password-meter"></div>
</ng-template>

<ng-template #weakPw>
  <div class="weak-password-meter"></div>
</ng-template>

<ng-template #loadingSpinner>
  <mat-spinner diameter="25"></mat-spinner>
</ng-template>
