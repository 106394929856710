import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpRequest,
} from '@angular/common/http';
import { LCRUDMixin, PaginatedListResponse } from '../api-mixins';
import { VibrationReportInterface, SiteReportStatus } from './vibration-report';
import * as FileSaver from 'file-saver';
import { LRCache } from '../api-cache';

@Injectable({
  providedIn: 'root',
})
export class VibrationReportService extends LCRUDMixin<VibrationReportInterface> {
  protected lrCache = new LRCache(5 * 60 * 1000);
  constructor(protected http: HttpClient) {
    super(environment.BACKEND_BASE_URL + 'report/', http);
  }

  approve(id: number): Promise<any> {
    return this.http.get<any>(`${this.modelBaseUrl}${id}/approve/`).toPromise();
  }

  list(
    filter?: { [id: string]: string | number | boolean | string[] | number[] },
    search?: string,
    ordering?: string,
    pageSize?: number,
    customUrl?: string
  ): Promise<PaginatedListResponse<VibrationReportInterface>> {
    if (filter) {
      filter = { ...filter, published: true };
    } else {
      filter = { published: true };
    }
    return super.list(filter, search, ordering, pageSize, customUrl);
  }

  /**
   * Returns the latest published report on each machine on a site. Cache the results.
   * @param siteId
   */
  getSitePublishedReportStatus(
    siteId: number,
    serializationParameters: any = {}
  ): Promise<SiteReportStatus[]> {
    let url = `${this.modelBaseUrl}site-published-report-status/`;
    
    url = this.getListUrl(
      { site: siteId, ...serializationParameters },
      null,
      null,
      null,
      url
    );

    if (this.lrCache) {
      if (this.lrCache.inCache(url)) return this.lrCache.getCachePromise(url);
      return this.lrCache.setCacheReturnPromise<SiteReportStatus[]>(
        url,
        this.modelBaseUrl,
        this.http.get<SiteReportStatus[]>(url).toPromise()
      );
    }
    return this.http.get<SiteReportStatus[]>(url).toPromise();
  }

  downloadReportPdf(id: number): Promise<void> {
    const url = `${this.modelBaseUrl}${id}/get-pdf-report/`;

    const downloadRequest = new HttpRequest('GET', url, {
      reportProgress: true,
      responseType: 'blob',
    });

    let filename = 'report.pdf';

    const request = this.http.request(downloadRequest);
    return new Promise<void>((resolve, reject) => {
      request.subscribe(
        (event: HttpEvent<unknown>) => {
          // Code to get http headers does not work in skaiwatch, but works in skailab.
          // The http header contains the filename of the pdf.
          // This should be solved in the future. Possible solution: Upgrade angular to version 10.

          // if (event && event.type === HttpEventType.ResponseHeader) {
          //   const contentDispositionHeader = event.headers.get(
          //     'Content-Disposition'
          //   );
          //   filename = contentDispositionHeader.slice(
          //     contentDispositionHeader.search('filename') + 10
          //   );
          //   filename = filename.slice(0, filename.length - 1);
          // }

          if (event && event.type === HttpEventType.Response) {
            FileSaver.saveAs(event.body, filename, true);
            resolve();
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  clearCache(): void {
    this.lrCache.clearCache();
  }
}
