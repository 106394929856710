<div class="container">
  <div class="contentrow">
    <span class="span">Machine status updates</span>
    <button mat-icon-button attr.aria-label="{{ siteSubscription?.machine_status ? 'Unsubscribe' : 'Subscribe' }}"
      matTooltip="{{ siteSubscription?.machine_status ? 'Unsubscribe to machine status update email' : 'Subscribe to machine status update email' }}"
      (click)="toggleMachineStatus()">
      <mat-icon>{{ siteSubscription?.machine_status ? 'notifications' : 'notifications_off' }}</mat-icon>
    </button>
  </div>
  <div class="contentrow">
    <span class="span">No new measurement warning</span>
    <button mat-icon-button *ngIf="!isLoadingMissingMeasurementEmail else loadingSpinner"
      attr.aria-label="MissingMeasurementEmail" matTooltip="Send missing measurement status update on Email now"
      (click)="sendMissingMeasurementEmail()">
      <mat-icon>email</mat-icon>
    </button>
    <button mat-icon-button
      attr.aria-label="{{ siteSubscription?.machine_missing_measurement ? 'Unsubscribe' : 'Subscribe' }}"
      matTooltip="{{ siteSubscription?.machine_missing_measurement ? 'Unsubscribe to missing measurement warning email' : 'Subscribe to missing measurement warning email' }}"
      (click)="toggleMachineMissingMeasurement()">
      <mat-icon>{{ siteSubscription?.machine_missing_measurement ? 'notifications' : 'notifications_off' }}</mat-icon>
    </button>
  </div>
</div>


<ng-template #loadingSpinner>
  <div>
    <mat-spinner diameter="25"></mat-spinner>
  </div>
</ng-template>
