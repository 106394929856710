/**
 *  Saves setTimeouts and gives the ability to flush.
 *  Flushing (flush()) clears all the saved setTimeouts and runs the callbacks immediately.
 */
class TimeoutScheduler {
  queue = [];
  nextId = 0;
  constructor() {}

  add(fn: any, delay: number): number {
    const id = this.nextId++;
    const timeout = window.setTimeout(() => {
      this.queue = this.queue.filter((d) => d.id !== id);
      fn();
    }, delay);
    this.queue.push({ id, fn, timeout });
    return id;
  }
  flush() {
    this.queue.forEach((d) => {
      window.clearTimeout(d.timeout);
      d.fn();
    });
    this.queue = [];
  }
}

export const lrCacheTimeoutScheduler = new TimeoutScheduler();

/**
 * List Retrieve cache.
 */
export class LRCache {
  cache = new Map<string, any>();
  loadingPromise = new Map<string, Promise<any>>();
  timeout: number;

  constructor(timeout: number) {
    this.timeout = timeout;
  }
  clearCache(): void {
    this.cache = new Map<string, any>();
  }

  setCache(key: string, values: any): void {
    this.cache.set(key, values);
    lrCacheTimeoutScheduler.add(() => {
      this.deleteCache(key);
    }, this.timeout);
  }

  getCache(key: string): any {
    return this.cache.get(key);
  }

  inCache(key: string): boolean {
    return this.cache.has(key) || this.loadingPromise.has(key);
  }

  deleteCache(key): void {
    this.cache.delete(key);
  }

  getCachePromise<T>(key: string): Promise<T> {
    if (this.loadingPromise.has(key)) {
      return this.loadingPromise.get(key);
    } else {
      return new Promise<T>((resolve, reject) => {
        resolve(this.getCache(key));
      });
    }
  }

  setCacheReturnPromise<T>(
    url: string,
    baseUrl: string,
    promise: Promise<T>
  ): Promise<T> {
    const cachePromise = new Promise<T>((resolve, reject) => {
      promise.then(
        (data) => {
          this.setCache(url, data);
          this.loadingPromise.delete(url);
          // if paginated list response then add each item to retrieve cache
          if (data['count'] !== undefined && data['results'] !== undefined) {
            data['results'].forEach((item) => {
              this.setCache(`${baseUrl}${item['id']}/`, item);
            });
          }
          resolve(data);
        },
        (error) => {
          this.loadingPromise.delete(url);
          reject(error);
        }
      );
    });
    this.loadingPromise.set(url, cachePromise);
    return cachePromise;
  }
}
