import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { OilReport } from '../backend-api/oil-report/oil-report';
import { getMachineType } from '../ts-tools/machine-type-from-description';
import { SiteInterface } from '../backend-api/site/site';
import { SiteService } from '../backend-api/site/site.service';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { OilReportRoutingService } from '../backend-api/oil-report-routing/oil-report-routing.service';
import { OilReportRoutingInterface } from '../backend-api/oil-report-routing/oil-report-routing';
import {
  getOilReportSeverityLevelColorClass,
  getOilReportSeverityLevelVerbose,
} from '../backend-api/oil-report/tools';

@Component({
  selector: 'app-oil-report-view',
  templateUrl: './oil-report-view.component.html',
  styleUrls: ['./oil-report-view.component.scss'],
})
export class OilReportViewComponent implements OnInit {
  @Input() oilReport: OilReport;

  @ViewChild('machineIconContainer')
  machineIconContainerElement: ElementRef<HTMLElement>;

  oilReportData: OilReport;

  site: SiteInterface;

  statusColorClass: string;
  kmLogoURL = 'assets/img/logo/km.svg';

  severityLevel: string;
  severityLevelClass: string;

  get oilReportRouting(): OilReportRoutingInterface {
    return this.oilReport?.routing;
  }

  constructor(
    private siteService: SiteService,
    public dialog: MatDialog,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.updateFieldsFromOilReport();
  }

  async updateFieldsFromOilReport(): Promise<void> {
    this.severityLevel = getOilReportSeverityLevelVerbose(this.oilReport);

    this.severityLevelClass = getOilReportSeverityLevelColorClass(
      this.oilReport
    );

    this.site = await this.siteService.retrieve(this.oilReportRouting.site.id);

    this.cdr.detectChanges(); // Detect changes to reveal machineIconContainerElement hidden behind *ngIf="oilReport"
    this.updateMachineIcon(
      getMachineType(this.oilReport?.descriptionofmachine)
    );
  }

  private static machineType2machineIconPath(machineType: string) {
    let svgFileName = machineType || 'unknown';
    return `assets/img/icon/${svgFileName}.svg`;
  }

  async updateMachineIcon(machineType: string) {
    const machineIconURL =
      OilReportViewComponent.machineType2machineIconPath(machineType);
    const machineIconURLSafe =
      this.sanitizer.bypassSecurityTrustResourceUrl(machineIconURL);

    // Create SVG Element
    const machineIconSVGElement = await this.iconRegistry
      .getSvgIconFromUrl(machineIconURLSafe)
      .toPromise();

    // Add new element to #machine-icon-container
    this.machineIconContainerElement.nativeElement.innerHTML = ''; // Remove children
    this.machineIconContainerElement.nativeElement.appendChild(
      machineIconSVGElement
    );
  }
}
