import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  Thread,
  Comment,
} from 'src/app/site-dashboard/machine-comments/machine-comments.component';
import { LCRUDMixin } from 'src/app/backend-api/api-mixins';

@Injectable({
  providedIn: 'root',
})
export class ThreadService extends LCRUDMixin<Thread> {
  constructor(protected http: HttpClient) {
    super(environment.BACKEND_BASE_URL + 'thread/', http);
  }
}

@Injectable({
  providedIn: 'root',
})
export class CommentsService extends LCRUDMixin<Comment> {
  constructor(protected http: HttpClient) {
    super(environment.BACKEND_BASE_URL + 'comment/', http);
  }
}
