import { Component, Inject } from '@angular/core';
import { AuthService } from '../../../_services/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ImageDialogData {
  imgUrl?: string;
  svgIcon?:string;
  width?:string;
  height?:string;
}

@Component({
  selector: 'app-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.scss'],
})
export class ImageDialogComponent {
  constructor(
    public authService: AuthService,
    public dialogRef: MatDialogRef<ImageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public input: ImageDialogData
  ) {}
}
