// environment.ts when building
// This file will be replaced with environment.dev.ts in local development


const current_domain = window.location.hostname; // E.g. portal.skaiwatch.no
const backend_domain = current_domain.replace('portal', 'backend'); // E.g. backend.skaiwatch.no

export const environment = {
  enableAngularProductionMode: true,
  BACKEND_BASE_URL: `https://${backend_domain}/api/v1/`,

  // Microsoft login
  // Skaiwatch/Karsten Moholt Azure Active Directory Multi Tentent login
  // Admin: portal.azure.com -> Azure Active Directory -> App registrations -> Skaiwatch
  MSAL_CLIENT_ID: 'f82c90ec-9ea2-4443-a450-c5e6e4f2602e',
  MSAL_AUTHORITY: 'https://login.microsoftonline.com/common/',
  MSAL_REDIRECT_URL: `https://${current_domain}/login`,
};
