<div class="container selectable-text light-theme">
  <div class="report-container" *ngIf="oilReport && site && oilReportRouting">
    <!---------------------------------------------------------------------->
    <!--                    Header                     									  -->
    <!---------------------------------------------------------------------->
    <div class="header-container">
      <div style="width:160px;"><img class="customer-logo" src="{{oilReport.company_logo}}"
          alt="{{oilReport.company_name}}"></div>
      <div style="text-align: center;">Oil report {{oilReport.id}}, {{oilReportRouting.unique_code}}</div>
      <div style="width:160px; text-align:right;">
        <img class="km-logo" src="{{kmLogoURL}}" alt="Karsten Moholt">
      </div>
    </div>

    <!---------------------------------------------------------------------->
    <!--                    Title                     									  -->
    <!---------------------------------------------------------------------->
    <div class="title-container">
      <div class="site">
        <div>{{site.name}}&nbsp; <span class="imo" *ngIf="site.location_identifier">IMO:&nbsp;
            {{site.location_identifier}}</span></div>
      </div>

      <div class="machine-status-container">
        <div>
          <div class="icon-title-container">
            <div #machineIconContainer class="machine-icon-container" [ngClass]="severityLevelClass">
              <!-- A svg dom element is inserted here at runtime by updateMachineIcon() -->
            </div>
            <div>
              <div class="machine-name">{{oilReport.descriptionofmachine}}
              </div>
              <div class="sfi" *ngIf="oilReportRouting.customer_reference">SFI/TAG&nbsp;&nbsp;
                {{oilReportRouting.customer_reference}}</div>
            </div>
          </div>
        </div>
        <div>
          <div class="status" [ngClass]="severityLevelClass">
            {{severityLevel}}</div>
          <div class="report-date">{{oilReport.sample_date | date: "d MMMM yyyy"}}</div>
        </div>
      </div>

      <div class="color-bar" [ngClass]="severityLevelClass"></div>
    </div>

    <p style="margin-bottom: 1rem"> This oil analysis is performed by Spectro. </p>

    <div class="table-container">
      <table>
        <tbody>
          <tr>
            <td class="tb-head">Sample date</td>
            <td class="tb-td">{{oilReport.sample_date || '–'}}</td>
          </tr>
          <tr>
            <td class="tb-head">Report Date</td>
            <td class="tb-td">{{oilReport.laboratory_report_date || '–'}}</td>
          </tr>
          <tr>
            <td class="tb-head">Report reference</td>
            <td class="tb-td">{{oilReport.laboratory_report_reference || '–'}}</td>
          </tr>
          <tr>
            <td class="tb-head">Serial number</td>
            <td class="tb-td">{{oilReportRouting.serial_number || '–'}}</td>
          </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          <tr>
            <td class="tb-head">Manufacturer</td>
            <td class="tb-td">{{oilReport.manufacturer || '–'}}</td>
          </tr>
          <tr>
            <td class="tb-head">Model</td>
            <td class="tb-td">{{oilReport.model || '–'}}</td>
          </tr>
          <tr>
            <td class="tb-head">Oil fluid type</td>
            <td class="tb-td">{{oilReport.oilfluidtype || '–'}}</td>
          </tr>
          <tr>
            <td class="tb-head">Oil life</td>
            <td class="tb-td">{{oilReport.oillife || '–'}} <span *ngIf="oilReport.oillife">hours</span></td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>
</div>
