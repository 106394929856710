import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MeasurementService } from '../backend-api/measurement/measurement.service';
import { MeasurementData } from '../backend-api/measurement/measurement';
import {
  VibrationReport,
  VibrationReportVerboseInterface,
} from '../backend-api/vibration-report/vibration-report';
import { VibrationReportVerboseService } from '../backend-api/vibration-report/vibration-report-verbose.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit {
  url_report_id: string;
  report: VibrationReport;
  graph_option: any;
  graph_instance: any;

  constructor(
    private route: ActivatedRoute,
    private vibrationReportVerboseService: VibrationReportVerboseService,
    private measurementService: MeasurementService
  ) {
    this.url_report_id = this.route.snapshot.paramMap.get('report_id');
  }

  ngOnInit() {
    this.vibrationReportVerboseService.retrieve(+this.url_report_id)
      .then((data: VibrationReportVerboseInterface) => {
        this.report = new VibrationReport(data);
      });
    this.get_graph_test_data();
    this.graph_option = {
      tooltip: {},
      xAxis3D: {
        type: 'value',
      },
      yAxis3D: {
        type: 'value',
      },
      zAxis3D: {
        type: 'value',
      },
      grid3D: {
        viewControl: {
          projection: 'orthographic',
        },
      },
      series: [],
    };
  }

  get_graph_test_data() {
    let measurement_ids = [1122, 1124, 1126, 1128];
    for (let i = 0; i < measurement_ids.length; i++) {
      this.measurementService.retrieveData(measurement_ids[i]).then(
        (data: MeasurementData) => {
          this.update_graph(data.y);
        },
        (err) => {
          console.warn(err);
        }
      );
    }
  }

  update_graph(Y_data) {
    let data = [];
    let z = this.graph_option.series.length;
    for (let i = 0; i < Y_data.length; i++) {
      data.push([i, z, Y_data[i]]);
    }
    this.graph_option.series.push({
      type: 'line3D',
      data: data,
    });
    this.graph_instance.setOption(this.graph_option);
  }

  onChartInit(ec) {
    this.graph_instance = ec;
  }
}
