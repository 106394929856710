import {
  Component,
  Input,
  OnChanges,
  SimpleChange,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';

import {
  Analyst,
  VibrationReport,
} from '../../backend-api/vibration-report/vibration-report';
import { ImageDialogComponent } from '../../widgets/dialogs/image-dialog/image-dialog.component';
import { OilReport } from '../../backend-api/oil-report/oil-report';
import { VibrationReportService } from '../../backend-api/vibration-report/vibration-report.service';
import { sortObjects } from 'src/app/ts-tools/sort';

export interface MachineReportOverviewInput {
  machineName?: string;
  machineSfi?: string;
  machineId?: number;
  siteId?: number;
  latestOilReport?: OilReport;
  latestVibrationReport?: VibrationReport;
}

interface OilReportStatus {
  colorClass?: string;
  severityLevelNumber?: number;
  severityLevelString?: string;
}

@Component({
  selector: 'app-machine-report-overview',
  templateUrl: './machine-report-overview.component.html',
  styleUrls: ['./machine-report-overview.component.scss'],
  providers: [DatePipe],
})
export class MachineReportOverviewComponent implements OnChanges {
  @Input() input: MachineReportOverviewInput;
  @ViewChild('machineIconContainer')
  machineIconContainerElement: ElementRef<HTMLElement>;

  // Fields derived from report verbose
  vibrationReport: VibrationReport;
  oilReport: OilReport;
  vibrationReportDate: Date;
  oilReportDate: Date;

  machineId: number;
  machineName: string;
  analysedBy: string;
  approvedBy: string;

  kmLogoURL = 'assets/img/logo/km.svg';
  vibrationStatusColorClass: string;
  oilReportStatus: OilReportStatus;
  oilStatusColorClass: string;

  isLoadingPdf = false;

  constructor(
    public dialog: MatDialog,
    private vibrationReportService: VibrationReportService
  ) {}

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    this.updateFromInput();
  }

  updateFromInput(): void {
    this.vibrationReport = this.input?.latestVibrationReport;
    if (this.vibrationReport?.report_images.length > 0) {
      // sort report images
      this.vibrationReport.report_images = sortObjects(
        this.vibrationReport.report_images,
        'index'
      );
    }
    const rv = this.input?.latestVibrationReport;
    // Fields derived from report verbose
    this.vibrationReportDate = rv?.date_created
      ? new Date(rv.date_created)
      : null;
    this.machineId = rv?.machine;
    this.analysedBy = MachineReportOverviewComponent.getNameFromAnalyst(
      rv?.analysed_by
    );
    this.approvedBy = MachineReportOverviewComponent.getNameFromAnalyst(
      rv?.approved_by
    );
    this.vibrationStatusColorClass =
      MachineReportOverviewComponent.severityLevel2statusColorClass(
        rv?.severity_level
      );

    this.oilReport = this.input?.latestOilReport;
    const or = this.input?.latestOilReport;
    this.oilReportDate = or?.laboratory_report_date
      ? new Date(or?.laboratory_report_date)
      : null;
    this.oilReportStatus = MachineReportOverviewComponent.getOilReportStatus(
      or?.severitylevel
    );
  }

  openImage(imgUrl: string): void {
    this.dialog.open(ImageDialogComponent, {
      width: 'auto',
      data: {
        imgUrl: imgUrl,
      },
      panelClass: ['dialog__no-padding'],
    });
  }
  async saveVibrationReportPdf(): Promise<void> {
    if (!this.vibrationReport) return;
    this.isLoadingPdf = true;
    await this.vibrationReportService.downloadReportPdf(
      this.vibrationReport.id
    );
    this.isLoadingPdf = false;
  }

  private static getNameFromAnalyst(analyst: Analyst): string {
    if (!analyst?.user?.first_name) return '–';
    else return `${analyst.user.first_name} ${analyst.user.last_name}`;
  }

  private static severityLevel2statusColorClass(severityLevel: string): string {
    switch (severityLevel) {
      case 'Good':
      case 'Normal':
        return 'status-good';
      case 'Acceptance Report':
        return 'status-good';
      case 'Warning':
      case 'Early Warning':
        return 'status-warning';
      case 'Incomplete Data':
        return 'status-warning';
      case 'Alarm':
      case 'Advanced Warning':
        return 'status-alarm';
      case '':
      case 'Unknown':
      case 'new config':
        return 'status-none';
      default:
        return 'status-none';
    }
  }

  public static getOilReportStatus(status: string): OilReportStatus {
    let res: OilReportStatus = {};
    switch (status) {
      case 'A':
        res.colorClass = 'status-alarm';
        res.severityLevelString = 'Advanced Warning';
        res.severityLevelNumber = 2;
        break;
      case 'E':
        res.colorClass = 'status-warning';
        res.severityLevelString = 'Early Warning';
        res.severityLevelNumber = 1;
        break;
      case 'N':
        res.colorClass = 'status-good';
        res.severityLevelString = 'Normal';
        res.severityLevelNumber = 0;
        break;
      case 'U':
      default:
        res.colorClass = 'status-none';
        res.severityLevelString = 'Unknown';
        res.severityLevelNumber = -1;
        break;
    }
    return res;
  }
}
