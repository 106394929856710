import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LCRUDMixin } from '../api-mixins';
import { environment } from 'src/environments/environment';
import { UploadJobInterface } from './upload-job';

@Injectable({
  providedIn: 'root',
})
export class UploadJobService extends LCRUDMixin<UploadJobInterface> {
  protected modelBaseUrl: string;

  constructor(protected http: HttpClient) {
    super(environment.BACKEND_BASE_URL + 'upload-job/', http);
  }

  finishUploadJob(id: number): Promise<any> {
    return this.http
      .post<any>(this.modelBaseUrl + `${id}/finish-upload-job/`, {})
      .toPromise();
  }
}
