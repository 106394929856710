import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { MachineInterface } from '../../../backend-api/machine/machine';
import { OilReportInterface } from '../../../backend-api/oil-report/oil-report';
import { OilReportService } from '../../../backend-api/oil-report/oil-report.service';
import { generateOilReportFileName } from '../../../backend-api/oil-report/tools';
import { SiteInterface } from '../../../backend-api/site/site';
import { VibrationReportInterface } from '../../../backend-api/vibration-report/vibration-report';

import { OilReportDialogComponent } from '../../../dialogs/oil-report-dialog/oil-report-dialog.component';
import {
  getOilReportSeverityLevelVerbose,
  getOilReportSeverityLevelLabelClass,
} from '../../../backend-api/oil-report/tools';
import { FilterParameters } from '../report-archive.component';
import { MatTableDataSource } from '@angular/material/table';
import { ApiListController } from '../../../backend-api/api-list-controller';

interface ReportTableRow {
  id: number;
  status: string;
  statusLabelClass: string;
  reportDate: string;
  sampleDate: string;
  isDownloading: boolean;
  siteName: string;
  siteId: number;
  machineName: string;
  machineGroup: string;
  machineId: number;
  oilReport: OilReportInterface;
}

@Component({
  selector: 'app-oil-report-archive-table',
  templateUrl: './oil-report-archive-table.component.html',
  styleUrls: ['./oil-report-archive-table.component.scss'],
})
export class OilReportArchiveTableComponent implements OnInit, OnChanges {
  reportTableDataSource = new MatTableDataSource<ReportTableRow>([]);
  reportApiFilterForm: UntypedFormGroup;
  reportPaginationSize = 50;
  reportApiListController: ApiListController<OilReportInterface, any>;

  @Input() filterParameters: FilterParameters;

  @Input() selectedMachine: MachineInterface;
  @Input() selectedSite: SiteInterface;

  @Output() internalSiteToggle = new EventEmitter<SiteInterface>();
  @Output() internalMachineToggle = new EventEmitter<MachineInterface>();

  @ViewChild('reportPaginator') reportPaginator: MatPaginator;

  destroy$ = new Subject<void>();
  displayedColumns: string[] = [
    'siteName',
    'machineName',
    'reportDate',
    'sampleDate',
    'status',
    'buttons',
  ];

  private currentReportFilter = {};
  private currentReportSearch = '';

  constructor(
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private oilReportService: OilReportService
  ) {}

  initDone = false;
  async ngOnInit(): Promise<void> {
    this.initReportForm();
    this.initPaginator();
    this.applyReportApiSearchOnChanges();

    await this.loadInitialData();
    this.initDone = true;
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  ngOnChanges(changes) {
    if (this.initDone) {
      this.reportFilterOnFormChanges(this.filterParameters);
    }
  }

  filterParametersToOilReportApiParameters(filterParameters: FilterParameters) {
    let oilReportApiParameters = {};

    if (filterParameters.siteGroupFamily)
      oilReportApiParameters['routing__site__site_group__site_group_family'] =
        filterParameters.siteGroupFamily;
    if (filterParameters.siteGroup)
      oilReportApiParameters['routing__site__site_group'] =
        filterParameters.siteGroup;
    if (filterParameters.site)
      oilReportApiParameters['routing__site'] = filterParameters.site;
    if (filterParameters.machine)
      oilReportApiParameters['routing__machine'] = filterParameters.machine;

    return oilReportApiParameters;
  }

  /**
   * Loads site group families, and loads first page for sites, machines and reports.
   *  Select machine or site if url parameters ('machine' or 'site') are provided.
   */
  private async loadInitialData(): Promise<void> {
    this.reportApiListController.isLoading = true;
    this.reportApiListController.changePage(1);
  }

  private initReportForm() {
    this.reportApiFilterForm = this.fb.group({
      reportSearch: '',
    });
  }

  private initPaginator() {
    this.reportApiListController = new ApiListController<
      OilReportInterface,
      any
    >(this.reportPaginationSize, this.oilReportService);
    this.reportApiListController.responseData
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.reportTableDataSource = new MatTableDataSource(
          data.map((r) => this.report2ReportTableRow(r))
        );
      });
  }

  private report2ReportTableRow(report: OilReportInterface): ReportTableRow {
    return {
      id: report.id,
      status: getOilReportSeverityLevelVerbose(report),
      statusLabelClass: getOilReportSeverityLevelLabelClass(report),
      reportDate: report.laboratory_report_date,
      sampleDate: report.sample_date,
      isDownloading: false,
      siteName: report.routing.site.name,
      siteId: report.routing.site.id,
      machineName: report.routing.machine?.name,
      machineGroup: report.routing.machine?.group,
      machineId: report.routing.machine?.id,
      oilReport: report,
    };
  }

  private applyReportApiSearchOnChanges() {
    this.reportApiFilterForm.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(500))
      .subscribe((changes) => {
        if (changes.reportSearch === this.currentReportSearch) return; // skip if changes not from report search bar.

        this.currentReportSearch = changes.reportSearch;

        let nextFilter = { ...this.currentReportFilter };
        if (changes.reportSearch.trim())
          nextFilter = { ...nextFilter, search: changes.reportSearch.trim() };

        this.reportApiListController.applyApiFilter(nextFilter);
        this.reportPaginator.firstPage();
      });
  }

  private reportFilterOnFormChanges(filterParameters: FilterParameters) {
    this.currentReportFilter =
      this.filterParametersToOilReportApiParameters(filterParameters);
    let nextReportFilter = { ...this.currentReportFilter };

    if (this.currentReportSearch.trim()) {
      nextReportFilter = {
        ...nextReportFilter,
        search: this.currentReportSearch.trim(),
      };
    }

    this.reportApiListController.applyApiFilter({
      ...nextReportFilter,
    });
    this.reportPaginator.firstPage();
  }

  downloadReport(report: ReportTableRow, event?: MouseEvent): void {
    if (event) event.stopPropagation();

    report.isDownloading = true;
    this.oilReportService
      .downloadFullPdfReport(
        report.id,
        generateOilReportFileName(report.oilReport)
      )
      .then(
        () => {
          report.isDownloading = false;
        },
        (error) => {
          report.isDownloading = false;
        }
      );
  }

  openReportDialog(oilReport: OilReportInterface, event?: MouseEvent): void {
    if (event) event.stopPropagation();

    const dialogRef = this.dialog.open(OilReportDialogComponent, {
      width: '1050px',
      data: { oilReport: oilReport },
      panelClass: ['dialog__no-padding'],
    });
  }

  /**
   * When clicking on a site name in the report table. Converts ReportTableRow to a site object.
   * @param report
   */
  selectSiteFromReportTable(report: ReportTableRow) {
    this.internalSiteToggle.next({ name: report.siteName, id: report.siteId });
  }
  /**
   * When clicking on a machine name in the report table. Converts ReportTableRow to a machine object.
   * @param report
   */
  selectMachineFromReportTable(report: ReportTableRow) {
    this.internalMachineToggle.emit({
      name: report.machineName,
      id: report.machineId,
      group: report.machineGroup,
    });
  }

  deselectMachine() {
    this.internalMachineToggle.emit(null);
  }

  deselectSite() {
    this.internalSiteToggle.emit(null);
  }
}
