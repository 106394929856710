/**
 * Takes a machine description and makes a qualified guess of the machine type
 *
 * @param machineDescription String containing describing the machine, ideally containing information of the machine type
 */
export function getMachineType(machineDescription: string): string {
  if (machineDescription) {
    for (const type of machineTypeNames) {
      if (machineDescription.toLocaleLowerCase().includes(type)) return type;
    }

    if (machineDescription.toLocaleLowerCase().includes('propulsion'))
      return 'thruster';

    if (machineDescription.toLocaleLowerCase().includes('propeller'))
      return 'thruster';

    if (machineDescription.toLocaleLowerCase().includes('el - motor')) {
      return 'el_motor';
    }

    if (machineDescription.toLocaleLowerCase().includes('el-motor')) {
      return 'el_motor';
    }

    if (machineDescription.toLocaleLowerCase().includes('hpu ')) {
      return 'pump';
    }

    if (machineDescription.includes('HPU')) {
      return 'pump';
    }

    if (
      machineDescription.toLocaleLowerCase().includes('hydraulic power unit')
    ) {
      return 'pump';
    }

    if (machineDescription.toLocaleLowerCase().includes('(hpu)')) {
      return 'pump';
    }

    if (machineDescription.toLocaleLowerCase().includes(' fan ')) {
      return 'fan';
    }

    if (machineDescription.toLocaleLowerCase().includes(' crane ')) {
      return 'crane';
    }
  }

  return 'unknown';
}

export const machineTypeNames = [
  'el_motor',
  // 'engine',
  'gear',
  'generator',
  'pump',
  'thruster',
  'unknown',
  'measurement',
  'site',
  'company',
  'fan',
  'crane',
];
