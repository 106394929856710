<!---------------------------------------------------------------------->
<!--                    Event Log                                     -->
<!---------------------------------------------------------------------->
<div style="height: 650px; display: flex; flex-direction: column;">
  <mat-form-field style="width: 400px; margin: 0px 30px 10px 24px; font-size: 12px;">
    <mat-label>
      <p class="fa fa-search"></p> Search
    </mat-label>
    <input matInput type="text" [formControl]="searchForm" placeholder="Eg. machine name" />
    <button type="button" mat-button *ngIf="searchForm.value" matSuffix mat-icon-button aria-label="Clear"
      (click)="searchForm.setValue('')">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <table *ngIf="!isLoading; else loadingSpinner" class="container-fluid text-dark" mat-table
    [dataSource]="vibrationReportTableDataSource.dataSource">

    <!-- Date Column -->
    <ng-container matColumnDef="dateApproved">
      <th mat-header-cell *matHeaderCellDef> Date </th>
      <td mat-cell *matCellDef="let row"> {{row.dateApproved | date: "yyyy.MM.dd HH:mm" }} </td>
    </ng-container>

    <!-- Machine Column -->
    <ng-container matColumnDef="machineName">
      <th mat-header-cell *matHeaderCellDef> Machine </th>
      <td mat-cell *matCellDef="let row"> {{row.machineName || '-'}} </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> Status </th>
      <td mat-cell *matCellDef="let row" [ngClass]="{
            'text-success': [0].includes(row.status),
            'text-warning': [1,1.1].includes(row.status),
            'text-danger': [2].includes(row.status)
        }">
        <span *ngIf="row.status==0">
          Good
        </span>
        <span *ngIf="row.status==0.1">
          Acceptance Report
        </span>
        <span *ngIf="row.status==1">
          Warning
        </span>
        <span *ngIf="row.status==1.1">
          Incomplete Data
        </span>
        <span *ngIf="row.status==2">
          Alarm
        </span>
        <span *ngIf="row.status==-0.9">
          New Config
        </span>
        <span *ngIf="row.status==-1">
          Unknown
        </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"
      (click)="openDialog(row.vibReportId)"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter "{{searchForm.value}}"</td>
    </tr>
  </table>

  <mat-paginator style="margin-top: auto;" hidePageSize=true [length]="vibrationReportTableDataSource.nTotalItems"
    [pageSize]="vibrationReportTableDataSource.paginationSize" (page)="updatePage($event)">
  </mat-paginator>
</div>


<ng-template #loadingSpinner>
  <div style="height: 100%; width: 100%; margin:30px; display: flex; align-items: center; justify-content: center;">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
