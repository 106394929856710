import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Machine } from '../../backend-api/machine/machine';
import { MachineService } from '../../backend-api/machine/machine.service';
import { Site } from '../../backend-api/site/site';
import {
  SiteReportStatus,
  VibrationReport,
  VibrationReportInterface,
} from '../../backend-api/vibration-report/vibration-report';
import { VibrationReportService } from '../../backend-api/vibration-report/vibration-report.service';
import {
  getOilReportStatus,
  getVibrationReportStatus,
} from '../../ts-tools/utils';
import { MatSort } from '@angular/material/sort';
import { VibrationReportDialogComponent } from '../../dialogs/vibration-report-dialog/vibration-report-dialog.component';
import { OilReportService } from '../../backend-api/oil-report/oil-report.service';
import {
  OilReport,
  OilReportInterface,
  SiteOilReportStatus,
} from '../../backend-api/oil-report/oil-report';

export interface SiteMachineListSelected {
  machineId: number;
  machineName: string;
  machineSfi: string;
  latestOilReportId: number;
  latestVibrationReportId: number;
}

interface MachineTableDataSource {
  machineId: number;
  machineSfi: string;
  name: string;
  group: string;
  description: string;
  category: string;
  machineIconColorClass: string;
  status: number;
  vibrationReport: VibrationReport;
  reportStatusWithoutOilString: string;
  reportStatusWithoutOilColorClass: string;
  vibrationNotMeasured: boolean;
  vibrationIsMaintenancePeriod: boolean;
  oilReport: OilReport;
  oilStatusNumber: number;
  oilStatusString: string;
  oilStatusColorClass: String;
  display: boolean; // used in filtering by group
  numberOfUnresolvedMachineComment: number;
  severityDisplay: string;
  severity: number;
}

@Component({
  selector: 'app-site-machine-list',
  templateUrl: './site-machine-list.component.html',
  styleUrls: ['./site-machine-list.component.scss'],
})
export class SiteMachineListComponent implements OnInit {
  @Input() site: Site;
  @Output() selected = new EventEmitter<SiteMachineListSelected>();
  @Output() isLoading = new EventEmitter<boolean>();
  @ViewChild('sortMain') sortMain: MatSort;

  mainMachinesTableDataSource: MatTableDataSource<MachineTableDataSource>;
  displayedColumns: string[] = ['name', 'status', 'severity'];
  selectedMachineId: number;
  machineGroups = ['All'];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private machineService: MachineService,
    private vibReportService: VibrationReportService,
    private oilReportService: OilReportService
  ) {}

  ngOnInit(): void {
    this.updateMachines(this.site);
  }

  public goToMachine(id: number) {
    this.router.navigate(['machine-overview', id]);
  }

  public openReportInDialog(
    event: MouseEvent,
    latestVibReportId: number
  ): void {
    event.stopPropagation();

    if (!latestVibReportId) return;

    const dialogRef = this.dialog.open(VibrationReportDialogComponent, {
      width: '1050px',
      data: { vibrationReportId: latestVibReportId },
      panelClass: ['dialog__no-padding'],
    });
  }

  /**
   * If description does not match any registered icons, return 'default-machine'
   * @param category
   * @returns description or 'default-machine'
   */
  getIconFromCategory(category: string): string {
    let registeredIcons = [
      'propeller',
      'modbus',
      'ER-fan',
      'fan',
      'crane',
      'engine',
      'generator',
      'HPU',
      'pump',
      'motor',
      'el_motor',
      'gear',
      'gearbox',
      'thruster',
    ];
    return registeredIcons.includes(category) ? category : 'default-machine';
  }

  private async updateMachines(site: Site) {
    this.isLoading.emit(true);

    // fetch as set possible?
    let latestOilReports = await this.oilReportService.getSiteOilReportStatus(
      site.id
    );

    let machines = (
      await this.machineService.list({
        site: site.id,
        page_size: 1000,
        extend: ['latest_published_report'],
        sets: [
          'latest_published_report__oilstatus_set',
          'latest_published_report__machinestatus_set',
          'latest_published_report__bearingstatus_set',
          'latest_published_report__electricalstatus_set',
        ],
      })
    ).results.map((m) => new Machine(m));

    let mainMachines: MachineTableDataSource[] = [];

    for (let machine of machines) {
      if (machine.group) {
        let group = machine.group.split('\\')[0];
        if (!this.machineGroups.includes(group)) this.machineGroups.push(group);
      }
      let latestVibrationReport =
        machine.latest_published_report as VibrationReportInterface;

      let latestOilReport = this.getLatestOilReportFromMachine(
        latestOilReports,
        machine
      );

      let reportStatusWithoutOilStatus =
        this.getMostSevereReportStatusWithoutOilStatus(latestVibrationReport);

      let vibrationStatus = getVibrationReportStatus(
        latestVibrationReport?.severity_level
      );

      let oilReportStatus = this.getMostSevereOilReportStatus(
        latestOilReport,
        latestVibrationReport
      );

      let severityLevel = this.getBearingSeverityLevel(latestVibrationReport);

      let tableData: MachineTableDataSource = {
        machineId: machine.id,
        machineSfi: machine.SFI_number,
        name: machine.name,
        group: machine.group,
        description: machine.description,
        category: this.getIconFromCategory(machine.category),
        status: this.getCombinedSeverityLevel(
          vibrationStatus.number,
          oilReportStatus.number
        ),
        vibrationReport: latestVibrationReport
          ? new VibrationReport(latestVibrationReport)
          : null,
        reportStatusWithoutOilString: reportStatusWithoutOilStatus.text,
        reportStatusWithoutOilColorClass: this.getVibrationStatusColorClass(
          reportStatusWithoutOilStatus.text
        ),
        vibrationNotMeasured: latestVibrationReport?.no_new_measurement,
        vibrationIsMaintenancePeriod:
          latestVibrationReport?.is_maintenance_period,
        oilReport: latestOilReport ? new OilReport(latestOilReport) : null,
        oilStatusNumber: oilReportStatus.number,
        oilStatusString: oilReportStatus.text,
        oilStatusColorClass: this.getOilStatusColorClass(oilReportStatus.text),
        machineIconColorClass: this.getMachineIconColorClass(
          vibrationStatus.number,
          oilReportStatus.number
        ),
        display: true,
        numberOfUnresolvedMachineComment:
          machine.number_of_unresolved_public_machine_comment,
        severityDisplay: severityLevel.display,
        severity: severityLevel.sortval,
      };

      if (machine.main) mainMachines.push(tableData);
    }

    this.mainMachinesTableDataSource =
      new MatTableDataSource<MachineTableDataSource>(mainMachines);

    // Set timeout to make the HTML update, creating matSort before sorting
    setTimeout(() => {
      this.mainMachinesTableDataSource.sort = this.sortMain;

      if (this.sortMain) this.sortByVibrationStatus(this.sortMain);

      if (this.mainMachinesTableDataSource.data.length > 0) {
        const machineId = parseInt(
          this.route.snapshot.queryParamMap.get('machine')
        );

        if (machineId) {
          const selectedMachine = this.mainMachinesTableDataSource.data.filter(
            (machine) => {
              return machine.machineId == machineId;
            }
          )[0];

          if (selectedMachine) {
            this.selectMachine(selectedMachine);
          } else {
            console.error(
              `machine=${selectedMachine} is not found on current site`
            );
          }
        } else {
          this.selectMachine(
            this.mainMachinesTableDataSource.sortData(
              this.mainMachinesTableDataSource.data,
              this.sortMain
            )[0]
          );
        }
      }
    });

    this.isLoading.emit(false);
  }

  getBearingSeverityLevel(latestVibrationReport: VibrationReportInterface): {
    display: string;
    sortval: number;
  } {
    let highestLevel = { display: '', sortval: 0 };
    latestVibrationReport?.bearing_statuses.map((r) => {
      if (r.bearing_severity_level_display) {
        if (r.bearing_severity_level_display > highestLevel.display) {
          highestLevel.display = r.bearing_severity_level_display;

          if (highestLevel.display === 'L1') highestLevel.sortval = 1;
          else if (highestLevel.display === 'L2') highestLevel.sortval = 2;
          else if (highestLevel.display === 'L3') highestLevel.sortval = 3;
        }
      }
    });
    return highestLevel;
  }

  getMostSevereReportStatusWithoutOilStatus(
    latestVibrationReport: VibrationReportInterface
  ) {
    if (!latestVibrationReport) return { text: 'Unknown', number: -1 };

    let machineStatus = latestVibrationReport.machine_statuses.map((r) =>
      getVibrationReportStatus(r.status)
    );
    let bearingStatus = latestVibrationReport.bearing_statuses.map((r) =>
      getVibrationReportStatus(r.status)
    );
    let electricalStatus = latestVibrationReport.electrical_statuses.map((r) =>
      getVibrationReportStatus(r.status)
    );

    let allStatusesWithoutOil = machineStatus.concat(
      bearingStatus,
      electricalStatus
    );

    if (allStatusesWithoutOil.length == 0)
      return { text: 'Unknown', number: -1 };

    let allStatusNumbers = allStatusesWithoutOil.map((s) => s.number);
    let maxStatusNumberIndex = allStatusNumbers.indexOf(
      Math.max(...allStatusNumbers)
    );

    return allStatusesWithoutOil[maxStatusNumberIndex];
  }

  getMostSevereOilReportStatus(
    latestOilReport: OilReportInterface,
    latestVibrationReport: VibrationReportInterface
  ): { text: string; number: number } {
    let oilReportStatus = getOilReportStatus(latestOilReport?.severitylevel);

    if (!latestVibrationReport) return oilReportStatus;

    let vibReportOilStatuses = latestVibrationReport.oil_statuses.map((r) =>
      getVibrationReportStatus(r.status)
    );
    let maxVibReportOilStatus = Math.max(
      ...vibReportOilStatuses.map((s) => s.number)
    );

    if (maxVibReportOilStatus > oilReportStatus.number) {
      oilReportStatus.number = maxVibReportOilStatus;
      oilReportStatus.text = vibReportOilStatuses.filter((status) => {
        return status.number === maxVibReportOilStatus;
      })[0].text;
    }
    return oilReportStatus;
  }

  selectMachine(machine: MachineTableDataSource): void {
    this.selectedMachineId = machine.machineId;

    // Update URL
    this.router.navigate(['.'], {
      queryParams: { machine: machine.machineId },

      replaceUrl: true,
      relativeTo: this.route,
      queryParamsHandling: 'merge',
    });

    this.selected.emit({
      machineId: machine.machineId,
      machineName: machine.name,
      machineSfi: machine.machineSfi,
      latestOilReportId: machine.oilReport?.id,
      latestVibrationReportId: machine.vibrationReport?.id,
    });
  }

  selectMachineGroup(group: string, event: any): void {
    if (event.isUserInput) {
      this.mainMachinesTableDataSource.data.forEach((element) => {
        if (group == 'All') element.display = true;
        else if (element.group == null) element.display = false;
        else if (element.group.split('\\')[0] == group) element.display = true;
        else element.display = false;
      });
    }
  }

  /**
   * Returns the combined severity level considering both statuses combined
   * prioritizing vibration report over oil.
   * The combined severity level is calculated by the sum of the exp() of each severity level
   * @param vibrationReportStatus
   * @param oilReportStatus
   */
  getCombinedSeverityLevel(
    vibrationReportStatus: number,
    oilReportStatus: number
  ) {
    let vibrationReportOffset = 0.0001; // give vibration report status priority
    return (
      Math.exp(vibrationReportStatus + vibrationReportOffset) +
      Math.exp(oilReportStatus)
    );
  }
  /**
   * Returns a whole number of the max value of the severity level.
   * @param vibrationReportStatus
   * @param oilReportStatus
   */
  getMachineIconColorClass(
    vibrationReportStatus: number,
    oilReportStatus: number
  ) {
    let status = Math.floor(
      Math.max(...[vibrationReportStatus, oilReportStatus])
    );
    if (status == -1) return 'color-status-nm';
    if (status == -0.9) return 'color-status-none';
    if (status == 0) return 'color-status-good';
    if (status == 0.1) return 'color-status-good';
    if (status == 1) return 'color-status-warning';
    if (status == 1.1) return 'color-status-warning';
    if (status == 2) return 'color-status-alarm';
  }

  private getLatestOilReportFromMachine(
    reportStatusResponse: SiteOilReportStatus[],
    machine: Machine
  ): OilReportInterface {
    for (let report of reportStatusResponse) {
      if (
        report.machine_name === machine.name &&
        report.machine_group === machine.group
      ) {
        return report.latest_report;
      }
    }
  }

  private sortByVibrationStatus(sort: MatSort) {
    sort.active = 'status';
    sort.direction = 'desc';
    sort.sortChange.emit({
      active: 'status',
      direction: 'desc',
    });
  }

  getVibrationStatusColorClass(status: string) {
    switch (status.toLowerCase()) {
      case 'good':
      case 'acceptance report':
        return 'status-good';

      case 'warning':
      case 'incomplete data':
        return 'status-warning';

      case 'alarm':
        return 'status-alarm';

      case 'new config':
        return 'status-none';

      default:
        return 'status-nm';
    }
  }

  getOilStatusColorClass(status: string) {
    switch (status.toLowerCase()) {
      case 'good':
      case 'acceptance report':
      case 'normal':
        return 'status-good';

      case 'warning':
      case 'incomplete data':
      case 'early warning':
        return 'status-warning';

      case 'alarm':
      case 'advanced warning':
        return 'status-alarm';

      default:
        return 'status-nm';
    }
  }
}
