import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { MsalService } from '@azure/msal-angular';
import { User } from '../backend-api/user/user';
import { FeatureToggleInterface } from '../backend-api/company/company';
import { CompanyService } from 'src/app/backend-api/company/company.service';
import { PopupRequest } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private userLoginUrl = environment.BACKEND_BASE_URL + 'login/';
  userTokenRefreshUrl = environment.BACKEND_BASE_URL + 'token-refresh/';
  private userAzureLoginUrl = environment.BACKEND_BASE_URL + 'microsoft-login/';
  private jwtHelper = new JwtHelperService();
  _token: string;
  loggedInUser: User;
  featureToggle: FeatureToggleInterface;

  constructor(
    private http: HttpClient,
    private router: Router,
    private msalService: MsalService,
    private companyService: CompanyService
  ) {}

  get token(): string {
    if (!this._token) {
      this._token = localStorage.getItem('token');
      this.updateLoggedInUserFromToken(this._token);
    }
    return this._token;
  }

  set token(token: string) {
    this._token = token;
    localStorage.setItem('token', token);
    this.updateLoggedInUserFromToken(token);
  }

  /**
   * Updates user object
   * @param userToken JWT user token
   */
  private updateLoggedInUserFromToken(token: string) {
    if (token) {
      const decodedToken = this.jwtHelper.decodeToken(this.token);
      const user = new User(
        decodedToken.user_id,
        decodedToken.email,
        decodedToken.is_superuser,
        decodedToken.first_name,
        decodedToken.last_name,

        decodedToken.company_id,
        decodedToken.company_name,
        decodedToken.company_logo_light_bg,
        decodedToken.company_logo_dark_bg,
        decodedToken.company_is_supercompany
      );
      this.loggedInUser = user;

      this.companyService
        .retrieve(this.loggedInUser.company_id)
        .then((company) => {
          this.featureToggle = company.feature_toggle;
        });
    }
  }

  /**
   * Logins auth service
   * @param user
   * @param callback
   * @returns
   */

  isLoggedIn() {
    try {
      return !this.jwtHelper.isTokenExpired(this.token);
    } catch (error) {
      // If token is corrupted.
      console.warn(error);
      this.logout();
      return false;
    }
  }

  async refreshToken() {
    const refreshResponse = await this.http
      .post(this.userTokenRefreshUrl, { token: this.token })
      .toPromise();
    this.token = refreshResponse['token'];
  }

  logout() {
    this.token = '';
    this.loggedInUser = null;
    this.router.navigate(['login']);
  }

  async login(email: string, password: string): Promise<void> {
    this.logout();
    const response = await this.http
      .post(this.userLoginUrl, { email: email, password: password })
      .toPromise();
    this.token = response['token'];
  }

  // Login given a Microsoft Token (can be received by calling openMicrosoftLoginWindowAndGetMicrosoftToken)
  public async loginWithMicrosoftToken(microsoftToken: string): Promise<void> {
    const response = await this.http
      .post(this.userAzureLoginUrl, { token: microsoftToken })
      .toPromise();
    this.token = response['token'];
  }

  // Open Microsoft login window (if the user needs to login) and returns a Microsoft Token when and if the user finishes the login process successfully
  public async openMicrosoftLoginWindowAndGetMicrosoftToken(): Promise<string> {
    let authRequest: PopupRequest = {
      scopes: ['openid', 'profile', 'User.Read'],
    };

    try {
      // Try to acquire the token silently first (if the user is logged in on their microsoft account)
      const authenticationResults = await this.msalService
        .acquireTokenSilent(authRequest)
        .toPromise();
      return authenticationResults.accessToken;
    } catch {
      console.info(
        'Silent token acquisition fails. Acquiring the Microsoft token using popup login window'
      );
      const authenticationResults = await this.msalService
        .acquireTokenPopup(authRequest)
        .toPromise();
      return authenticationResults.accessToken;
    }
  }
}
