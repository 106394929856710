import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../_services/auth.service';

import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BrowserAuthError } from '@azure/msal-browser';

export interface ILoginError {
  email: string;
  password: string;
  other: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  isRedirecting: boolean;
  error: ILoginError = { email: '', password: '', other: '' };
  loading: boolean = false;
  loginForm: UntypedFormGroup;
  selectedLogin: number = null;
  resetPasswordUrl: string;

  currentYear = new Date().getFullYear();

  constructor(
    private formBuilder: UntypedFormBuilder,
    public authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
    this.resetPasswordUrl =
      environment.BACKEND_BASE_URL.split('api/v1/')[0] + 'reset-password/';
  }

  ngOnDestroy(): void {}

  get f() {
    return this.loginForm.controls;
  }

  resetValues() {
    this.isRedirecting = false;
  }

  async onSubmit() {
    const username = this.f.email.value;
    const password = this.f.password.value;
    this.login(username, password);
  }

  async login(username: string, password: string) {
    // Reset user error messages
    this.error = { email: '', password: '', other: '' };

    try {
      this.loading = true;
      await this.authService.login(username, password);
    } catch (error) {
      this.handleLoginError(error);
    } finally {
      this.loading = false;
    }

    // Login successful
    this.router.navigate(['']);
  }

  private handleLoginError(error: any): void {
    console.error(error);

    if (error instanceof HttpErrorResponse) {
      switch (error.status) {
        case 400:
          if (error.error.email) {
            this.error.email = error.error.email;
          } else if (error.error.password) {
            this.error.password = error.error.password;
          } else if (error.error.non_field_errors) {
            this.error.other = error.error.non_field_errors.join('\n');
          } else {
            this.error.other = `Unknown error occurred. Please try again`;
          }
          break;

        default:
          this.error.other = `Unknown error occurred. Please try again`;
      }
    } else {
      this.error.other = `Unknown error occurred. Please try again`;
    }
  }

  async startMicrosoftPopUpLogin() {
    // Reset user error messages
    this.error = { email: '', password: '', other: '' };

    try {
      this.loading = true;
      const microsoftToken =
        await this.authService.openMicrosoftLoginWindowAndGetMicrosoftToken();
      await this.authService.loginWithMicrosoftToken(microsoftToken);

      // Login successful
      this.router.navigate(['']);
    } catch (error) {
      let userErrorMessage;

      if (error instanceof BrowserAuthError) {
        // Errors from Microsoft login popup
        userErrorMessage = error.errorMessage;
      } else if (error instanceof HttpErrorResponse) {
        const serverResponse = error?.error?.detail;
        if (error.status == 401) {
          userErrorMessage = `No user associated with this email. Contact administrator to create new users.`;
        } else if (serverResponse) {
          userErrorMessage = `Login error ${error.status}: ${serverResponse}`;
        } else {
          userErrorMessage = `Login error: ${error.message}`;
        }
      } else {
        userErrorMessage = `Unknown error occurred. Please try again`;
        throw error;
      }

      this.error.other = userErrorMessage;
    } finally {
      this.loading = false;
    }
  }

  public selectLogin(loginType) {
    this.selectedLogin = loginType;
  }
}
