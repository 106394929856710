<div class="main-container">
  <!-- SITE FILTER -->
  <div class="filter" [class.site-expanded]="isSiteFilterExpanded">
    <!-- Site filter header -->
    <div class="archive-container__header" [class.height-100]="!isSiteFilterExpanded">
      <div class="expand-icon" (click)="toggleSiteFilter()">
        <i style="margin: 0 6px 20px auto;" class="far fa-chevron-right"></i>
      </div>
      <div *ngIf="!isSiteFilterExpanded" class="filter-title__vertical">SITE FILTER</div>

      <div class="collapse-hidden">
        <div class="flex-ai-center">
          <div class="filter-title">SITE FILTER</div>
          <mat-spinner *ngIf="siteApiPaginator.isLoading" style="margin-left: 15px;" diameter="20"></mat-spinner>
        </div>
        <!-- Site filter form -->
        <form class="filter-form" [formGroup]="reportApiFilterForm">
          <!-- Search -->
          <mat-form-field>
            <mat-label>Search</mat-label>
            <input matInput type="text" formControlName="siteSearch" placeholder="Eg. name, fleet, sub-fleet" />
            <button type="button" mat-button *ngIf="reportApiFilterForm.get('siteSearch').value" matSuffix
              mat-icon-button aria-label="Clear" (click)="reportApiFilterForm.get('siteSearch').setValue('')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <!-- Select fleet -->
          <mat-form-field>
            <mat-label>Select fleet</mat-label>
            <mat-select formControlName="selectedFleet">
              <mat-option [value]="0">
                Select all
              </mat-option>
              <mat-option *ngFor="let fleet of siteGroupFamilies" [value]="fleet.id">
                {{fleet.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- Select Sub fleet -->
          <mat-form-field>
            <mat-label>Select sub fleet</mat-label>
            <mat-select formControlName="selectedSubFleet">
              <mat-option [value]="0">
                Select all
              </mat-option>
              <mat-option *ngFor="let subFleet of siteGroups" [value]="subFleet.id">
                {{subFleet.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
    </div>

    <!-- Site filter rows -->
    <div class="archive-container collapse-hidden filter-list">
      <div class="table-row" [ngClass]="{'selected': site.id === selectedSite?.id}"
        *ngFor="let site of siteApiPaginator.responseData.value" (click)="toggleSite(site)">
        {{site.name}}
      </div>
    </div>

    <!-- Site paginator -->
    <mat-paginator #sitePaginator class="collapse-hidden" hidePageSize=true [length]="siteApiPaginator.dataTotal"
      [pageSize]="siteApiPaginator.pageSize" (page)="siteApiPaginator.changePage($event.pageIndex + 1)">
    </mat-paginator>
  </div>


  <!-- MACHINE FILTER -->
  <div class="filter" [class.machine-expanded]="isMachineFilterExpanded"
    [ngClass]="{'div-disable':selectedTabIndex===2}">
    <!-- Machine filter header -->
    <div class="archive-container__header" [class.height-100]="!isMachineFilterExpanded">
      <div class="expand-icon" (click)="toggleMachineFilter()">
        <i style="margin: 0 6px 20px auto;" class="far fa-chevron-right"></i>
      </div>
      <div *ngIf="!isMachineFilterExpanded" class="filter-title__vertical">MACHINE FILTER</div>
      <div class="collapse-hidden">
        <div class="flex-ai-center">
          <div class="filter-title">MACHINE FILTER</div>
          <mat-spinner *ngIf="machineApiPaginator.isLoading" style="margin-left: 15px;" diameter="20"></mat-spinner>
        </div>
        <!-- Machine filter form -->
        <form class="filter-form" [formGroup]="reportApiFilterForm">
          <mat-form-field>
            <mat-label>Search</mat-label>
            <input matInput type="text" formControlName="machineSearch" placeholder="Eg. name, group, sfi" />
            <button type="button" mat-button *ngIf="reportApiFilterForm.get('machineSearch').value" matSuffix
              mat-icon-button aria-label="Clear" (click)="reportApiFilterForm.get('machineSearch').setValue('')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </form>
      </div>
    </div>
    <!-- Machine filter list -->
    <div class="archive-container collapse-hidden filter-list">
      <div class="table-row" [ngClass]="{'selected': machine.id === selectedMachine?.id}"
        *ngFor="let machine of machineApiPaginator.responseData.value" (click)="toggleMachine(machine)">
        <div style="width: 100%;">
          <div class="machine-group-sfi">
            <div>
              {{machine.group}}
            </div>
            <div style="margin-left:5px"> {{machine.SFI_number}}</div>
          </div>
          <div>
            {{machine.name}}
          </div>
        </div>
      </div>
    </div>

    <!-- Machine paginator -->
    <mat-paginator #machinePaginator class="collapse-hidden" hidePageSize=true [length]="machineApiPaginator.dataTotal"
      [pageSize]="machineApiPaginator.pageSize" (page)="machineApiPaginator.changePage($event.pageIndex + 1)">
    </mat-paginator>
  </div>

  <div class="tab-group">
    <mat-tab-group (selectedTabChange)="onTabChanged($event)">
      <mat-tab label="Machine status reports">
        <app-vibration-report-archive-table [filterParameters]="currentFilterParameters"
          [selectedMachine]="selectedMachine" [selectedSite]="selectedSite"
          (internalMachineToggle)="selectMachine($event)" (internalSiteToggle)="selectSite($event)">
        </app-vibration-report-archive-table>
      </mat-tab>
      <mat-tab label="Oil reports">
        <app-oil-report-archive-table [filterParameters]="currentFilterParameters" [selectedMachine]="selectedMachine"
          [selectedSite]="selectedSite" (internalMachineToggle)="selectMachine($event)"
          (internalSiteToggle)="selectSite($event)"></app-oil-report-archive-table>
      </mat-tab>
      <mat-tab label="Offline measurements">
        <app-source-file-archive-table [filterParameters]="currentFilterParameters" [selectedSite]="selectedSite"
          (internalSiteToggle)="selectSite($event)"></app-source-file-archive-table>
      </mat-tab>
    </mat-tab-group>
  </div>


</div>

<ng-template #loadingSpinner>
  <mat-spinner diameter="20"></mat-spinner>
</ng-template>
