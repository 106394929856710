import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { UploadSourceFilesComponent } from '../../../upload-source-files/upload-source-files.component';

@Injectable({
  providedIn: 'root',
})
export class UploadSourceFilesComponentCanDeactivateGuardService
  implements CanDeactivate<UploadSourceFilesComponent>
{
  canDeactivate(component: UploadSourceFilesComponent) {
    if (component.isSubmitting || component.isSubmitError) {
      return confirm(
        'Your upload is not finished. Are you sure you want to discard your changes?'
      );
    }
    return true;
  }
}
