import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LRMixin } from '../api-mixins';
import { MeasurementData, Measurement } from './measurement';
import { PaginatedListResponse } from '../api-mixins';
import { LRCache } from '../api-cache';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MeasurementService extends LRMixin<Measurement> {
  protected lrCache = new LRCache(5 * 60 * 1000);

  constructor(protected http: HttpClient) {
    super(environment.BACKEND_BASE_URL + 'measurement/', http);
  }

  public retrieveData(id: number): Promise<MeasurementData> {
    return this.http
      .get<MeasurementData>(`${this.modelBaseUrl}${id}/data/`)
      .toPromise();
  }

  public listData(
    filter?: { [id: string]: string | number | boolean | string[] },
    search?: string,
    ordering?: string,
    pageSize?: number
  ): Promise<PaginatedListResponse<MeasurementData>> {
    let url = this.getListUrl(
      filter,
      search,
      ordering,
      pageSize,
      this.modelBaseUrl + 'data/'
    );
    return this.http
      .get<PaginatedListResponse<MeasurementData>>(url)
      .toPromise();
  }
}
