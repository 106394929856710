import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MachineGraphic } from '../backend-api/machine-graphic/machine-graphic';
import { Machine } from '../backend-api/machine/machine';
import { MachineService } from '../backend-api/machine/machine.service';
import { SiteGraphicService } from '../backend-api/site-graphic/site-graphic.service';
import { Site } from '../backend-api/site/site';
import { VibrationReportInterface } from '../backend-api/vibration-report/vibration-report';

export interface GraphicObject {
  id: string;
  label: string;
  file_url: string;
  position: { x: number; y: number };
  scale?: number;
  status: string;
  width?: number;
  height?: number;
  img?: HTMLImageElement;
}

@Component({
  selector: 'app-site-map',
  templateUrl: './site-map.component.html',
  styleUrls: ['./site-map.component.scss'],
})
export class SiteMapComponent implements OnInit, AfterViewInit {
  graphicMargin = { x: 50, y: 50 };

  @ViewChild('siteCanvas') siteCanvas: ElementRef<HTMLCanvasElement>;
  @Input() site: Site;

  siteGraphic: GraphicObject;
  machineGraphics: GraphicObject[];

  get canvas(): HTMLCanvasElement {
    return this.siteCanvas.nativeElement;
  }

  get canvasContext(): CanvasRenderingContext2D {
    return this.canvas.getContext('2d');
  }

  constructor(
    private siteGraphicService: SiteGraphicService,
    private http: HttpClient,
    private machineService: MachineService
  ) {}

  ngOnInit(): void {}

  async ngAfterViewInit() {
    await this.loadGraphics(this.site);
    this.onResize(null);
  }

  public onResize(event: Event): void {
    this.adjustCanvasSize();
    this.redraw();
  }

  private adjustCanvasSize(): void {
    const devicePixelRatio = window.devicePixelRatio || 1.0;
    this.canvas.width = devicePixelRatio * this.canvas.offsetWidth;
    this.canvas.height = devicePixelRatio * this.canvas.offsetHeight;
  }

  private async loadGraphics(site: Site): Promise<void> {
    this.machineGraphics = [];
    if (!(site && site.graphic)) return;
    await this.loadModels(site);
  }

  private async fetchSiteGraphic(site: Site): Promise<GraphicObject> {
    let siteGraphic = await this.siteGraphicService.retrieve(site.graphic);

    let graphicObject: GraphicObject = {
      id: `site_graphic_${siteGraphic.id}`,
      label: site.name,
      file_url: siteGraphic.file_url,
      position: {
        x: site.graphic_pos_X,
        y: site.graphic_pos_Y,
      },
      status: 'unknown',
    };

    graphicObject.img = await this.createGraphicImgElement(graphicObject);

    return graphicObject;
  }

  private async updateMachines(siteId: number): Promise<GraphicObject[]> {
    let machines = (
      await this.machineService.list({
        site: siteId,
        page_size: 1000,
        graphic__isnull: false,
        extend: ['latest_published_report', 'graphic'],
      })
    ).results.map((m) => new Machine(m));

    let machineGraphics = [];

    machines.forEach(async (machine) => {
      if (!machine.graphic) return;

      let machineGraphic = machine?.graphic as MachineGraphic;
      let res: GraphicObject = {
        id: `machine_graphic_${machine.id}_${machineGraphic.id}`,
        label: machine.name,
        file_url: machineGraphic.file,
        position: {
          x: machine.graphic_pos_X,
          y: machine.graphic_pos_Y,
        },
        scale: machine.graphic_scale,
        status: (machine?.latest_published_report as VibrationReportInterface)
          ?.severity_level
          ? (machine?.latest_published_report as VibrationReportInterface)
              ?.severity_level
          : 'unknown',
      };

      this.createGraphicImgElement(res).then((result) => {
        res.img = result;
      });

      machineGraphics.push(res);
    });

    return machineGraphics;
  }

  private async loadModels(site: Site): Promise<void> {
    this.siteGraphic = await this.fetchSiteGraphic(site);
    this.clear();
    if (!this.siteGraphic) return;

    this.machineGraphics = await this.updateMachines(site.id);
  }

  private clear(): void {
    this.canvasContext.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  private redraw(): void {
    this.clear();
    if (!this.siteGraphic) return;
    let boundingbox = this.calculateBoundingBox(
      this.siteGraphic,
      this.machineGraphics,
      1
    );
    let scale = this.calculateBoundingBoxScale(boundingbox);

    this.drawSite(this.canvasContext, this.siteGraphic, scale);
    for (let i = 0; i < this.machineGraphics.length; i++) {
      if (this.machineGraphics[i].img)
        this.drawMachine(
          this.canvasContext,
          this.machineGraphics[i],
          this.siteGraphic,
          scale
        );
    }
  }

  private calculateBoundingBox(
    site: GraphicObject,
    machines: GraphicObject[],
    scale: number
  ) {
    let siteImgElem = site.img;

    let boundingbox = {
      height: siteImgElem.height * scale,
      width: siteImgElem.width * scale,
    };

    for (const machine of machines) {
      if (!machine.img) continue;

      let machineImgElem = machine.img;
      let machineWidth = machineImgElem.width * scale;
      let machineHeight = machineImgElem.height * scale;

      let x = (machine.position.x / 100) * site.width * scale;
      let y = (machine.position.y / 100) * site.height * scale;

      let rightMostPixel = x + machineWidth;
      let bottomMostPixel = y + machineHeight;

      boundingbox.width =
        rightMostPixel > boundingbox.width ? rightMostPixel : boundingbox.width;

      boundingbox.height =
        bottomMostPixel > boundingbox.height
          ? bottomMostPixel
          : boundingbox.height;
    }
    return boundingbox;
  }

  private calculateBoundingBoxScale(boundingbox: {
    height: number;
    width: number;
  }) {
    let cWidth = this.canvas.width;
    let cHeight = this.canvas.height;

    let imgWRatio = cWidth / boundingbox.width;
    let imgHRatio = cHeight / boundingbox.height;

    let scale = Math.min(imgWRatio, imgHRatio);
    return scale;
  }

  private drawSite(ctx, graphicObj: GraphicObject, scale: number) {
    let imgElem = graphicObj.img;

    let width = graphicObj.width * scale;
    let height = graphicObj.height * scale;

    let offsetX = (this.canvas.width - width) / 2;
    let offsetY = (this.canvas.height - height) / 2;

    let x = graphicObj.position.x + offsetX;
    let y = graphicObj.position.y + offsetY;

    graphicObj.width = imgElem.width;
    graphicObj.height = imgElem.height;

    ctx.drawImage(imgElem, x, y, width, height);
  }

  private drawMachine(
    ctx: CanvasRenderingContext2D,
    machine: GraphicObject,
    _site: GraphicObject,
    externalScale: number
  ): void {
    let imgElem = machine.img;

    let width = imgElem.width * externalScale * machine.scale;
    let height = imgElem.height * externalScale * machine.scale;

    let siteWidth = _site.width * externalScale;
    let siteHeight = _site.height * externalScale;

    let offsetX = (this.canvas.width - siteWidth) / 2;
    let offsetY = (this.canvas.height - siteHeight) / 2;

    let x = (machine.position.x / 100) * siteWidth + offsetX;
    let y = (machine.position.y / 100) * siteHeight + offsetY;

    ctx.drawImage(imgElem, x, y, width, height);
    // ctx.font = String(25 * scale) + "px Arial";
    // ctx.fillStyle = "#FFFFFF";
    // ctx.fillText(machine.label, x + (width / 6), y - 5);
  }

  private async createGraphicImgElement(
    elem: GraphicObject
  ): Promise<HTMLImageElement> {
    if (elem.img) return elem.img;

    let svgString = await this.http
      .get(elem.file_url, {
        headers: {
          'Content-Type': 'image/svg+xml',
          'prevent-intercept': 'true',
        },
        responseType: 'text',
      })
      .toPromise();
    let elem_img = new Image();
    let color = this.getStatusColor(elem.status);
    elem_img.style.display = 'none';
    elem_img.style.fill = 'none';
    elem_img.id = elem.id;
    elem_img.onload = this.onResize.bind(this);

    // Replace all fill except when 'none'
    svgString = svgString.replace(
      /(fill[:=]{1}["]?)((?!["]?none).+?)([;"\n]{1})/g,
      `$1${color}$3`
    );

    // Replace all stroke except when 'none'
    svgString = svgString.replace(
      /(stroke[:=]{1}["]?)((?!["]?none).+?)([;"\n]{1})/g,
      `$1${color}$3`
    );

    let svgContent = encodeURIComponent(svgString);
    (elem_img as any).src = 'data:image/svg+xml;charset=utf-8,' + svgContent;
    document.body.appendChild(elem_img);
    elem.img = elem_img;
    return elem_img;
  }

  private getStatusColor(status: string): string {
    switch (status) {
      case 'good':
      case 'acceptance report':
      case 'new config':
      case 'loadtest':
        return '#6bae4f';
      case 'warning':
      case 'incomplete data':
        return '#f0ab34';
      case 'alarm':
        return '#c9433e';
      default:
        return '#FFF';
    }
  }
}
