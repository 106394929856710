import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AlarmOverTotalInterface, NmOverTotalInterface } from './kpi';

@Injectable({
  providedIn: 'root', // just before your class
})
export class KpiService {
  constructor(protected http: HttpClient) {}

  alarmOverTotal(
    id: number,
    start: Date,
    end: Date
  ): Promise<AlarmOverTotalInterface> {
    let url = environment.BACKEND_BASE_URL + 'kpi/';
    return this.http
      .get<AlarmOverTotalInterface>(
        `${url}${id}/alarm-over-total/?startdate=${start.valueOf()}&enddate=${end.valueOf()}`
      )
      .toPromise();
  }

  nmOverTotal(
    id: number,
    start: Date,
    end: Date
  ): Promise<NmOverTotalInterface> {
    let url = environment.BACKEND_BASE_URL + 'kpi/';
    return this.http
      .get<NmOverTotalInterface>(
        `${url}${id}/nm-over-total/?startdate=${start.valueOf()}&enddate=${end.valueOf()}`
      )
      .toPromise();
  }
}
