import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  SiteManagementData,
  IMonthSummaryData,
} from '../../_services/site-management-sample.service';
import { Site } from '../../backend-api/site/site';

@Component({
  selector: 'app-site-management-detail-dialog',
  templateUrl: './site-management-detail.component.html',
  styleUrls: ['./site-management-detail.component.scss'],
})
export class SiteManagementDetailComponent {
  site: Site;
  month_summary_data: Array<IMonthSummaryData>;
  pie_chart_option: any;

  constructor(
    public dialogRef: MatDialogRef<SiteManagementDetailComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { siteManagementData: SiteManagementData }
  ) {
    this.site = data.siteManagementData.site;
    this.month_summary_data = data.siteManagementData.month_summary;
  }

  onChartInit(event) {}

  close(): void {
    this.dialogRef.close();
  }

  display_month(month: IMonthSummaryData) {
    this.pie_chart_option = {
      title: {
        text: `${month.date.getDay()}/${
          month.date.getMonth() + 1
        }/${month.date.getFullYear()}`,
        subtext: this.site.name,
        left: 'center',
      },
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c} ({d}%)',
      },
      series: [
        {
          type: 'pie',
          radius: '55%',
          data: [
            {
              name: 'Received on time',
              value: month.measurements_received_on_time,
              itemStyle: { color: 'green' },
            },
            {
              name: 'Not received on time',
              value:
                month.total_measurements - month.measurements_received_on_time,
              itemStyle: { color: 'red' },
            },
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    };
  }
}
