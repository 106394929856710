import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LCRUDMixin } from '../api-mixins';
import { LRCache } from '../api-cache';
import { SiteGroupFamily } from './site-group-family';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SiteGroupFamilyService extends LCRUDMixin<SiteGroupFamily> {
  protected modelBaseUrl: string;

  protected lrCache = new LRCache(5 * 60 * 1000);

  constructor(protected http: HttpClient) {
    super(environment.BACKEND_BASE_URL + 'site-group-family/', http);
  }
}
