import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LCRUDMixin } from '../api-mixins';
import { LRCache } from '../api-cache';
import { ISiteSubscription } from './site-subscription';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SiteSubscriptionService extends LCRUDMixin<ISiteSubscription> {
  protected modelBaseUrl: string;
  //protected lrCache = new LRCache(5 * 60 * 1000);

  constructor(protected http: HttpClient) {
    super(environment.BACKEND_BASE_URL + 'site-subscription/', http);
  }

  toggleMachineStatus(subID: number, subscription: ISiteSubscription) {
    let url = `${this.modelBaseUrl}${subscription.id}/toggle-machine-status/`;
    return this.http.post(url, {}).toPromise();
  }

  toggleMachineMissingMeasurement(
    subID: number,
    subscription: ISiteSubscription
  ) {
    let url = `${this.modelBaseUrl}${subscription.id}/toggle-machine-missing-measurement/`;
    return this.http.post(url, {}).toPromise();
  }
}
