export function DefaultStringIfNull(description: string): string {
  if (!description) return 'Unknown';
  return description;
}

export function getVibrationReportStatus(status: string): {
  text: string;
  number: number;
} {
  let res: { text: string; number: number } = { text: '', number: 0 };
  if (!status) return { text: 'Unknown', number: -1 };

  switch (status.trim().toLowerCase()) {
    case 'good':
      res.text = 'Good';
      res.number = 0;
      break;
    case 'acceptance report':
      res.text = 'Acceptance Report';
      res.number = 0.1;
      break;
    case 'warning':
      res.text = 'Warning';
      res.number = 1;
      break;
    case 'incomplete data':
      res.text = 'Incomplete Data';
      res.number = 1.1;
      break;
    case 'alarm':
      res.text = 'Alarm';
      res.number = 2;
      break;
    case 'new config':
      res.text = 'New Config';
      res.number = -0.9;
      break;
    default:
      res.text = 'Unknown';
      res.number = -1;
      break;
  }
  return res;
}
export function getOilReportStatus(status: string): {
  text: string;
  number: number;
} {
  let res: { text: string; number: number } = { text: '', number: 0 };
  switch (status) {
    case 'A':
      res.text = 'Advanced Warning';
      res.number = 2;
      break;
    case 'E':
      res.text = 'Early Warning';
      res.number = 1;
      break;
    case 'N':
      res.text = 'Normal';
      res.number = 0;
      break;
    case 'U':
    default:
      res.text = 'Unknown';
      res.number = -1;
      break;
  }

  return res;
}
export function getAnyReportStatus(status: string): {
  text: string;
  number: number;
} {
  let vibStatus = getVibrationReportStatus(status);
  let oilStatus = getOilReportStatus(status);

  if (vibStatus.number !== -1) {
    return vibStatus;
  } else {
    return oilStatus;
  }
}
export interface ReportStatusSummary {
  good: number;
  warning: number;
  alarm: number;
  unknown: number;
}
