import { OilReportInterface } from './oil-report';

export function generateOilReportFileName(
  oilReport: OilReportInterface
): string {
  let filename =
    oilReport.laboratory_report_date +
    '_oil_report_' +
    oilReport.descriptionofmachine;

  // Filename safe
  filename.replace(/[^a-z0-9]/gi, '_').toLowerCase();
  return filename;
}

export function getOilReportSeverityLevelColorClass(
  oilReport: OilReportInterface
): string {
  let severityLevel = oilReport.severitylevel.toLowerCase();

  if (severityLevel == 'n') return 'status-good';
  if (severityLevel == 'a') return 'status-alarm';
  if (severityLevel == 'e' || severityLevel == 'u') return 'status-warning';

  return 'status-neutral';
}

export function getOilReportSeverityLevelLabelClass(
  oilReport: OilReportInterface
): string {
  let severityLevel = oilReport.severitylevel.toLowerCase();

  if (severityLevel == 'n') return 'app-label__good';
  if (severityLevel == 'a') return 'app-label__alarm';
  if (severityLevel == 'e' || severityLevel == 'u') return 'app-label__warning';

  return 'app-label__neutral';
}

export function getOilReportSeverityLevelVerbose(
  oilReport: OilReportInterface
): string {
  let severityLevel = oilReport.severitylevel.toLowerCase();

  if (severityLevel == 'n') return 'Normal';
  if (severityLevel == 'e') return 'Early Warning';
  if (severityLevel == 'a') return 'Advanced Warning';
  if (severityLevel == 'u') return 'Unknown';

  return 'Unknown';
}
