import { MatTableDataSource } from '@angular/material/table';
import { ListMixin } from './api-mixins';
import { PageEvent } from '@angular/material/paginator';

/**
 * Combines mat table data source with mat paginator and backend api calls when changing page.
 * Access mat table data source with dataSource attribute.
 */
export class BackendPaginatedTableDataSource<T, S> {
  nTotalItems = 0;

  currentPage = 1;
  pageLoading: { [page: number]: { loading: boolean; loaded: boolean } } = {};
  dataSource: MatTableDataSource<S>;
  search: string;
  filter = {};

  constructor(
    private backendApiService: ListMixin<T>,
    private listType2TableType: (response: T[]) => S[],
    public paginationSize = 20,
    initialFilter = {},
    initialData?: S[]
  ) {
    this.filter = initialFilter;
    this.dataSource = new MatTableDataSource<S>(initialData);
    this.paginationSize = paginationSize;
  }

  /**
   * Load a page into the table
   * @param page : Page number
   */
  async loadPage(page: number): Promise<void> {
    const currentPageSize = this.paginationSize;

    if (!(page in this.pageLoading))
      this.pageLoading[page] = { loading: false, loaded: false };

    if (!(!this.pageLoading[page].loading && !this.pageLoading[page].loaded))
      return;

    this.pageLoading[page].loading = true;
    let response = await this.backendApiService.list(
      { ...this.filter, ...{ page: page } },
      this.search,
      undefined,
      this.paginationSize,
      undefined
    );
    if (currentPageSize !== this.paginationSize) return;

    this.nTotalItems = response.count;
    this.pageLoading[page].loading = false;
    this.pageLoading[page].loaded = true;

    const startIdx = (page - 1) * this.paginationSize;

    if (startIdx > this.dataSource.data.length) {
      this.dataSource.data.push(...this.listType2TableType(response.results));
    } else {
      this.dataSource.data.splice(
        startIdx,
        0,
        ...this.listType2TableType(response.results)
      );
    }
    if (
      !this.isPrevPagesLoading(this.currentPage) &&
      !this.pageLoading[this.currentPage].loading
    ) {
      this.dataSource._updateChangeSubscription();
      this.dataSource._updatePaginator(this.nTotalItems);
      return;
    }
  }
  /**
   * Link this function to the mat-paginator "page" output to automatically update page when clicking on the paginator.
   * Pass the page event as input.
   * @param event : Page event from mat paginator emitted on the "page" output.
   */
  updatePage(event: PageEvent): Promise<void> {
    if (event.pageSize !== this.paginationSize) {
      return this.handlePageSizeChange(event.pageSize);
    }
    this.currentPage = event.pageIndex + 1;
    return this.loadPage(event.pageIndex + 1);
  }

  /**
   * Updated page size, resets and loads page 1
   * @param newSize : new page size
   */
  private handlePageSizeChange(newSize: number): Promise<void> {
    this.paginationSize = newSize;
    return this.resetAndLoadPage1();
  }

  /**
   * Use rest API to filter the queryset and update the data source with the new filtered data.
   * Resets and loads page 1 when the filtering is done.
   * @param filter : List API filter parameters. {param1: "value", param2:"value"}
   */
  filterBy(filter?: {
    [id: string]: string | number | boolean | string[] | number[];
  }): Promise<void> {
    this.filter = filter;
    return this.resetAndLoadPage1();
  }

  /**
   * Clears the filter, resets and loads page 1.
   */
  clearFilter(): Promise<void> {
    this.filter = {};
    return this.resetAndLoadPage1();
  }

  /**
   * Clears all loading, data and set current page to 1, then load page 1.
   */
  private resetAndLoadPage1(): Promise<void> {
    this.pageLoading = {};
    this.dataSource.data = [];
    this.dataSource._updateChangeSubscription();
    this.currentPage = 1;
    return this.loadPage(1);
  }

  /**
   * Returns true if any of the previous pages, down to page 1, is loading. 
   * @param page 
   */
  private isPrevPagesLoading(page: number): boolean {
    let prevPage = page - 1;
    while (prevPage > 0) {
      if (prevPage in this.pageLoading && this.pageLoading[prevPage].loading)
        return true;
      prevPage--;
    }
    return false;
  }
}
