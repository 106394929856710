import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LCRUDMixin } from '../api-mixins';
import { User } from './user';
import { LRCache } from '../api-cache';

@Injectable({
  providedIn: 'root',
})
export class UserService extends LCRUDMixin<User> {
  protected lrCache = new LRCache(5 * 60 * 1000);
  constructor(protected http: HttpClient) {
    super(environment.BACKEND_BASE_URL + 'user/', http);
  }
  changePassword(
    currentPassword: string,
    newPassword: string,
    newPasswordRepeat: string
  ): Promise<any> {
    return this.http
      .post(this.modelBaseUrl + 'change-password/', {
        old_password: currentPassword,
        new_password: newPassword,
        new_password_repeat: newPasswordRepeat,
      })
      .toPromise();
  }
}
