import { getOilReportStatus } from '../../ts-tools/utils';
import { OilReportRoutingInterface } from '../oil-report-routing/oil-report-routing';
export interface SiteOilReportStatus {
  machine_id: string;
  machine_name: string;
  machine_group: string;
  latest_report: OilReportInterface;
}

export interface OilReportInterface {
  routing: OilReportRoutingInterface;
  laboratory_report_reference: string | null;
  laboratory_report_date: string | null;
  sample_date: string | null;
  descriptionofmachine: string | null;
  manufacturer: string | null;
  model: string | null;
  oilfluidtype: string | null;
  unitlife: string | null;
  oillife: string | null;
  severitylevel: string;
  comment: string | null;
  company_name: string;
  company_logo: string;
  published: boolean;
  id: number;
}

export class OilReport implements OilReportInterface {
  id: number;
  comment: string;
  company_name: string;
  company_logo: string;
  customerreference: any;
  descriptionofmachine: string;
  laboratory_report_date: string;
  laboratory_report_reference: any;
  manufacturer: string;
  model: string;
  oilfluidtype: string;
  oillife: string;
  sample_date: string;
  serial_number: string;
  severitylevel: string;
  site: number;
  unique_code: string;
  unitlife: string;
  url: string;
  date_created: string;
  date_approved: string;
  machine: number;
  machineName: string;
  status: number;
  published: boolean;
  routing: OilReportRoutingInterface;

  constructor(oilReport: OilReportInterface) {
    this.id = oilReport.id;
    this.comment = oilReport.comment;
    this.company_name = oilReport.company_name;
    this.company_logo = oilReport.company_logo;
    this.descriptionofmachine = oilReport.descriptionofmachine;
    this.laboratory_report_date = oilReport.laboratory_report_date;
    this.laboratory_report_reference = oilReport.laboratory_report_reference;
    this.manufacturer = oilReport.manufacturer;
    this.model = oilReport.model;
    this.oilfluidtype = oilReport.oilfluidtype;
    this.oillife = oilReport.oillife;
    this.sample_date = oilReport.sample_date;
    this.severitylevel = oilReport.severitylevel;
    this.unitlife = oilReport.unitlife;
    this.date_created = oilReport.laboratory_report_date;
    this.date_approved = oilReport.laboratory_report_date;
    this.machineName = oilReport.descriptionofmachine;
    this.status = getOilReportStatus(this.severitylevel).number;
    this.published = oilReport.published;
    this.routing = oilReport.routing;
  }
}
export interface OilPdfResponse {
  pdf: any;
}
