<div>
  <app-oil-report-view *ngIf="data.oilReport" [oilReport]="data.oilReport"></app-oil-report-view>

  <div class="button-container">
    <button *ngIf="!loadingPdf; else loadingSpinner" mat-raised-button color="primary" (click)="savePdf()">Download full report</button>
    <button style="margin-left: 10px;" mat-raised-button color="primary" (click)="close()">Close</button>
  </div>
</div>

<ng-template #loadingSpinner>
  <div style="margin-right: 50px;">
    <mat-spinner diameter="25"></mat-spinner>
  </div>
</ng-template>
