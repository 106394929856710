<div class="source-file-main-container">
  <!-- Table header -->
  <div class="archive-container__header">
    <div style="display: flex;">
      <div class="flex-ai-center">
        <h2>Offline measurements</h2>
        <mat-spinner *ngIf="sourceFileApiListController.isLoading" style="margin-left: 15px;" diameter="25">
        </mat-spinner>
      </div>
      <!-- Table form -->
      <form [formGroup]="searchForm" class="table-form">
        <mat-form-field class="search-field">
          <!-- Search -->
          <mat-label>Search</mat-label>
          <input matInput type="text" formControlName="search" placeholder="Eg. analysed by, machine, date" />
          <button type="button" mat-button *ngIf="searchForm.get('search').value" matSuffix mat-icon-button
            aria-label="Clear" (click)="searchForm.get('search').setValue('')">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </form>
    </div>
    <!-- Selected site/machine arrows -->
    <div style="display: flex">
      <!-- Selected site -->
      <div *ngIf="selectedSite" class="select-arrow mat-elevation-z2">
        <div class="select-arrow-deselect-icon" (click)="deselectSite()">
          <i class="fal fa-times"></i>
        </div>
        <div class="select-arrow-name">
          {{selectedSite.name}}
        </div>
      </div>
    </div>
  </div>

  <!-- Source file table -->
  <div class="archive-container table-container">
    <table mat-table [dataSource]="sourceFileTableDataSource" class="archive-container my-table">
      <ng-container matColumnDef="uploadedBy">
        <th mat-header-cell *matHeaderCellDef>Uploaded by</th>
        <td mat-cell *matCellDef="let sourceFileRow" class="table-column__uploaded-by"> {{sourceFileRow.uploadedBy}}
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Filename</th>
        <td mat-cell *matCellDef="let sourceFileRow" class="table-column__filename"> {{sourceFileRow.name}} </td>
      </ng-container>

      <ng-container matColumnDef="siteName">
        <th mat-header-cell *matHeaderCellDef>Site</th>
        <td mat-cell *matCellDef="let sourceFileRow" class="table-column__site-name">
          <span class="selectable" (click)="selectSiteFromTable(sourceFileRow)">{{sourceFileRow.siteName || '–'}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="uploadDate">
        <th mat-header-cell *matHeaderCellDef>Upload date</th>
        <td mat-cell *matCellDef="let sourceFileRow" class="table-column__upload-date">
          {{sourceFileRow.uploadDate | date: 'dd MMM yyyy, HH:mm'}} </td>
      </ng-container>

      <ng-container matColumnDef="size">
        <th mat-header-cell *matHeaderCellDef>Size</th>
        <td mat-cell *matCellDef="let sourceFileRow" class="table-column__size"
          [ngClass]="{'text-align-center': !sourceFileRow.size}"> {{sourceFileRow.size || '–'}} </td>
      </ng-container>

      <ng-container matColumnDef="buttons">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let sourceFileRow" class="table-column__buttons">
          <i *ngIf="!sourceFileRow.isDownloading else loadingSpinner" class="fal fa-download icon-button"
            matTooltip="Download file" matTooltipPosition="below" (click)="downloadSourceFile(sourceFileRow)"></i>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  <!-- Paginator -->
  <mat-paginator #paginator [length]="sourceFileApiListController.dataTotal"
    [pageSize]="sourceFileApiListController.pageSize"
    (page)="sourceFileApiListController.changePage($event.pageIndex + 1)">
  </mat-paginator>
</div>


<ng-template #loadingSpinner>
  <mat-spinner diameter="20"></mat-spinner>
</ng-template>
