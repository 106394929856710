import { HttpClient } from '@angular/common/http';
import { LRCache } from './api-cache';

export class CreateMixin<T> {
  constructor(protected modelBaseUrl: string, protected http: HttpClient) {}
  public create(data: Partial<T>, customUrl?: string): Promise<T> {
    return this.http
      .post<T>(customUrl ? customUrl : this.modelBaseUrl, data)
      .toPromise();
  }
}

export class RetrieveMixin<T> {
  constructor(protected modelBaseUrl: string, protected http: HttpClient) {}
  protected lrCache: LRCache;

  public retrieve(
    id?: number,
    urlParms?: {
      [id: string]: string | number | boolean | string[] | number[];
    },
    customUrl?: string
  ): Promise<T> {
    let url = customUrl ? customUrl : `${this.modelBaseUrl}${id}/`;

    if (urlParms) {
      url = addUrlParams(url, urlParms);
    }

    if (this.lrCache) {
      if (this.lrCache.inCache(url)) return this.lrCache.getCachePromise(url);
      return this.lrCache.setCacheReturnPromise<T>(
        url,
        this.modelBaseUrl,
        this.http.get<T>(url).toPromise()
      );
    }

    return this.http.get<T>(url).toPromise();
  }
}

export class UpdateMixin<T> {
  constructor(protected modelBaseUrl: string, protected http: HttpClient) {}

  public update(id: number, data: Partial<T>, customUrl?: string): Promise<T> {
    if (!customUrl) customUrl = `${this.modelBaseUrl}${id}/`;
    return this.http.patch<T>(customUrl, data).toPromise();
  }
}

export class DestroyMixin {
  constructor(protected modelBaseUrl: string, protected http: HttpClient) {}

  public destroy(id: number, customUrl?: string): Promise<any> {
    if (!customUrl) customUrl = `${this.modelBaseUrl}${id}/`;
    return this.http.delete(customUrl, { observe: 'response' }).toPromise();
  }
}

export interface PaginatedListResponse<T> {
  count: number;
  next: string;
  previous: string;
  results: T[];
}

export class ListMixin<T, S = PaginatedListResponse<T>> {
  protected lrCache: LRCache;

  constructor(protected modelBaseUrl: string, protected http: HttpClient) {}

  public list(
    filter?: { [id: string]: string | number | boolean | string[] | number[] },
    search?: string,
    ordering?: string,
    pageSize?: number,
    customUrl?: string
  ): Promise<S> {
    const url = this.getListUrl(
      filter,
      search,
      ordering,
      pageSize,
      customUrl ? customUrl : this.modelBaseUrl
    );

    if (this.lrCache) {
      if (this.lrCache.inCache(url)) return this.lrCache.getCachePromise(url);

      return this.lrCache.setCacheReturnPromise<S>(
        url,
        this.modelBaseUrl,
        this.http.get<S>(url).toPromise()
      );
    }

    return this.http.get<S>(url).toPromise();
  }

  public getListUrl(
    filter?: { [id: string]: string | number | boolean | string[] | number[] },
    search?: string,
    ordering?: string,
    pageSize?: number,
    customUrl?: string,
    page?: number
  ): string {
    let url = customUrl ? customUrl : this.modelBaseUrl;

    const validPage: boolean = page && page > 1;

    if (filter || search || ordering || pageSize || validPage) url += '?';

    if (filter) {
      for (const f in filter) {
        url += `${f}=${filter[f]}&`;
      }
    }

    if (search) url += `search=${search}&`;
    if (ordering) url += `ordering=${ordering}&`;
    if (pageSize) url += `page_size=${pageSize}&`;
    if (validPage) url += `page=${page}&`;

    if (url.substr(url.length - 1) == '&') url = url.slice(0, url.length - 1);

    return url;
  }
}

export function addUrlParams(
  url: string,
  params: {
    [id: string]: string | number | boolean | string[] | number[];
  }
): string {
  url += '?';

  for (const f in params) {
    url += `${f}=${params[f]}&`;
  }

  url = url.slice(0, url.length - 1);
  return url;
}

// From https://www.typescriptlang.org/docs/handbook/mixins.html
export function applyMixins(derivedCtor: any, baseCtors: any[]) {
  baseCtors.forEach((baseCtor) => {
    Object.getOwnPropertyNames(baseCtor.prototype).forEach((name) => {
      Object.defineProperty(
        derivedCtor.prototype,
        name,
        Object.getOwnPropertyDescriptor(baseCtor.prototype, name)
      );
    });
  });
}

// List Create Retrieve Update Destroy
export class LCRUDMixin<T, S = PaginatedListResponse<T>> {
  constructor(protected modelBaseUrl: string, protected http: HttpClient) {}
  protected lrCache: LRCache;
}

export interface LCRUDMixin<T, S = PaginatedListResponse<T>>
  extends ListMixin<T, S>,
    CreateMixin<T>,
    RetrieveMixin<T>,
    UpdateMixin<T>,
    DestroyMixin {}

applyMixins(LCRUDMixin, [
  ListMixin,
  CreateMixin,
  RetrieveMixin,
  UpdateMixin,
  DestroyMixin,
]);

// List Retrieve

export class LRMixin<T, S = PaginatedListResponse<T>> {
  constructor(protected modelBaseUrl: string, protected http: HttpClient) {}
  protected lrCache: LRCache;
}

export interface LRMixin<T, S = PaginatedListResponse<T>>
  extends ListMixin<T, S>,
    RetrieveMixin<T> {}
applyMixins(LRMixin, [ListMixin, RetrieveMixin]);

// List Create Retrieve Update

export class LCRUMixin<T, S = PaginatedListResponse<T>> {
  constructor(protected modelBaseUrl: string, protected http: HttpClient) {}
  protected lrCache: LRCache;
}

export interface LCRUMixin<T, S = PaginatedListResponse<T>>
  extends ListMixin<T, S>,
    CreateMixin<T>,
    RetrieveMixin<T>,
    UpdateMixin<T> {}

applyMixins(LCRUDMixin, [ListMixin, CreateMixin, RetrieveMixin, UpdateMixin]);

// List Retrieve Destroy
export class LRDMixin<T, S = PaginatedListResponse<T>> {
  constructor(protected modelBaseUrl: string, protected http: HttpClient) {}
  protected lrCache: LRCache;
}

export interface LRDMixin<T, S = PaginatedListResponse<T>>
  extends ListMixin<T, S>,
    RetrieveMixin<T>,
    DestroyMixin {}

applyMixins(LRDMixin, [ListMixin, RetrieveMixin, DestroyMixin]);

// List Retrieve Update Destroy
export class LRUDMixin<T, S = PaginatedListResponse<T>> {
  constructor(protected modelBaseUrl: string, protected http: HttpClient) {}
  protected lrCache: LRCache;
}

export interface LRUDMixin<T, S = PaginatedListResponse<T>>
  extends ListMixin<T, S>,
    RetrieveMixin<T>,
    UpdateMixin<T>,
    DestroyMixin {}

applyMixins(LRUDMixin, [ListMixin, RetrieveMixin, UpdateMixin, DestroyMixin]);
