import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BehaviorSubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SiteService } from '../backend-api/site/site.service';
import { MarineTrafficPS01 } from '../backend-api/marine-traffic-ps01/marine-traffic-ps01';
import { Site } from '../backend-api/site/site';
import {
  SiteReportStatus,
  VibrationReport,
} from '../backend-api/vibration-report/vibration-report';
import { VibrationReportService } from '../backend-api/vibration-report/vibration-report.service';
import { ISiteSubscription } from '../backend-api/site-subscription/site-subscription';
import { UserService } from '../backend-api/user/user.service';
import { AuthService } from '../_services/auth.service';
import { SiteSubscriptionService } from '../backend-api/site-subscription/site-subscription.service';
import { User } from '../backend-api/user/user';

export interface MachineStatusInterface {
  date: Date;
  machine_name: string;
  machine_id: number;
  report: VibrationReport;
}

export interface MachineListInterface {
  [site_id: number]: VibrationReport[];
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  destroy$ = new Subject<void>();

  selectedSite: Site;
  isDarkMode: boolean;
  tracking: MarineTrafficPS01;

  user: User;

  machineList: MachineListInterface = {};
  latestSiteReports: { [siteId: number]: SiteReportStatus[] } = {};
  siteSubscriptions: ISiteSubscription[] = [];
  siteTableColumns: string[];
  siteTableDataSource: MatTableDataSource<Site>;
  sites: Site[] = [];
  numberOfUnseenNotifications = 0;

  public siteTableLoadingProgress: BehaviorSubject<number> =
    new BehaviorSubject(0);

  constructor(
    private siteService: SiteService,
    private router: Router,
    private reportService: VibrationReportService,
    public dialog: MatDialog,
    public userService: UserService,
    private authService: AuthService,
    private siteSubscriptionService: SiteSubscriptionService
  ) {
    this.user = authService.loggedInUser;
    if (this.user.company_is_supercompany) {
      // Add company column
      this.siteTableColumns = [
        'subscribed',
        'company',
        'name',
        'site_group_family',
        'country',
        'site_type',
        'latestReportDate',
        'status',
      ];
    } else {
      this.siteTableColumns = [
        'subscribed',
        'name',
        'site_group_family',
        'country',
        'site_type',
        'latestReportDate',
        'status',
      ];
    }
  }

  ngOnInit() {
    this.getSites().then((sites) => {
      this.updateSiteTable(sites);
      this.selectSite(sites[0]);
      this.updateSubscriptions();
      this.updateSitePublishedReportStatus(sites);
    });
  }

  async updateSitePublishedReportStatus(sites: Site[]) {
    for (let site of sites) {
      //this.updateSitePublishedReportStatusOneSite(site);
      this.PublishedReportStatusOneSite(site);
    }
  }

  private async PublishedReportStatusOneSite(site: Site) {
    const latestReportAndStatus =
      await this.siteService.getLatestReportAndStatus(site.id);
    site.nGoodVibReports = latestReportAndStatus['good_reports'];
    site.nWarningVibReports = latestReportAndStatus['warning_reports'];
    site.nAlarmVibReports = latestReportAndStatus['alarm_reports'];
    site.latestReportDate = latestReportAndStatus['latest_report_date'];
  }

  private async updateSitePublishedReportStatusOneSite(site: Site) {
    const latestReports = await this.reportService.getSitePublishedReportStatus(
      site.id
    );
    this.latestSiteReports[site.id] = latestReports;
    let severityLevel: string;
    site.nGoodVibReports = 0;
    site.nWarningVibReports = 0;
    site.nAlarmVibReports = 0;
    for (let machineReportStatus of latestReports) {
      if (!machineReportStatus.latest_report) continue;
      let latestReportDate = new Date(
        machineReportStatus.latest_report.date_approved
      );
      if (site.latestReportDate < latestReportDate) {
        site.latestReportDate = latestReportDate;
      }
      severityLevel =
        machineReportStatus.latest_report.severity_level.toLowerCase();
      if (['good', 'acceptance report'].includes(severityLevel))
        site.nGoodVibReports++;
      if (['warning', 'incomplete data'].includes(severityLevel))
        site.nWarningVibReports++;
      if (severityLevel === 'alarm') site.nAlarmVibReports++;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  /**
   * Applys filter to site table
   * @param event
   */
  onSearchBoxChange(event: Event) {
    const searchString = (event.target as HTMLInputElement).value;
    this.siteTableDataSource.filter = searchString.trim().toLowerCase();
  }

  /**
   * Updates subscriptions
   * @param subscriptions
   */
  async updateSubscriptions() {
    this.siteSubscriptions = (
      await this.siteSubscriptionService.list({
        user: this.authService.loggedInUser.user_id, page_size: 10000
      })
    ).results;

    if (this.siteTableDataSource?.data) {
      this.siteTableDataSource.data.forEach((site) => {
        site.siteSubscription = this.siteSubscriptions.find(
          (e) => e.site === site.id
        );
      });
    }
  }

  /**
   * Get sites
   */
  async getSites(): Promise<Site[]> {
    let results = (
      await this.siteService.list({ active: true, page_size: 10000 })
    ).results;

    return results.map((s) => {
      return new Site(s);
    });
  }

  /**
   * Updates site table
   */
  async updateSiteTable(sites: Site[]) {
    this.siteTableDataSource = new MatTableDataSource(sites);
    this.siteTableDataSource.paginator = this.paginator;
    this.siteTableDataSource.sort = this.sort;
  }

  goToSite(siteId: number): void {
    this.router.navigate(['sites', siteId]);
  }

  /**
   * Gets coords string
   * @param lat
   * @param lon
   * @returns
   */
  get_coords_string(lat, lon) {
    let res = '';
    res += `${lat}°N ${lon}°E`;
    return res;
  }

  /**
   * Selects item
   * @param site
   * @returns
   */
  selectSite(site: Site) {
    if (!site) return;
    if (site.marine_traffic_info) {
      this.tracking = site.marine_traffic_info[0];
    } else {
      this.tracking = undefined;
    }
    this.selectedSite = site;
  }
}
