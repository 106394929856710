import { Injectable } from '@angular/core';
import { Site } from '../backend-api/site/site';

export interface IMonthSummaryData {
  date: Date;
  total_measurements: number;
  measurements_received_on_time: number;
}
export interface SiteManagementData {
  site_id: number;
  site_group_family: string;
  site_group: string;
  site_name: string;
  site: Site;
  good: number;
  month_summary: Array<IMonthSummaryData>;
}

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

@Injectable({
  providedIn: 'root',
})
export class SiteManagementSampleService {
  constructor() {}

  generate_data(sites: Array<Site>, startDate: Date, endDate: Date) {
    let final_data: Array<SiteManagementData> = [];

    for (let i = 0; i < sites.length; i++) {
      let element = sites[i];
      let monthSummaryData: IMonthSummaryData[] = [];
      let siteManagementData: SiteManagementData;
      let good_months = 0;

      for (
        let year = startDate.getFullYear();
        year <= endDate.getFullYear();
        year++
      ) {
        for (let month = 0; month < 12; month++) {
          let currentDate = new Date(year, month, 1, 1, 1, 1);
          let total = getRandomInt(12) + 1;
          let mrot = total;
          if (Math.random() > 0.5) {
            mrot = getRandomInt(total) + 1;
          } else {
            good_months++;
          }

          monthSummaryData.push({
            date: currentDate,
            measurements_received_on_time: mrot,
            total_measurements: total,
          });
        }
      }

      siteManagementData = {
        good: good_months,
        month_summary: monthSummaryData,
        site: element,
        site_group: element.site_group_detail
          ? element.site_group_detail.name
          : null,
        site_group_family: element.site_group_detail
          ? element.site_group_detail.site_group_family_detail.name
          : null,
        site_id: element.id,
        site_name: element.name,
      };

      final_data.push(siteManagementData);
    }

    return final_data;
  }
}
