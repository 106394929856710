<div mat-dialog-content>
  <p>New conversation</p>
  <div class="add-comment-form-field">
    <mat-form-field appearance="fill" class="add-comment-form-field">
      <mat-label>Title</mat-label>
      <input matInput [(ngModel)]="data.title">
    </mat-form-field>
  </div>
  <div class="add-comment-form-field">
    <mat-form-field class="add-comment-form-field">
      <mat-label>Add comment</mat-label>
      <textarea matInput [(ngModel)]="data.comment" cdkTextareaAutosize cdkAutosizeMinRows="4" cdkAutosizeMaxRows="8"
        cdkAutosizeMinRows="4" cdkAutosizeMaxCols="8"></textarea>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial
    [disabled]="!(data.title && data.comment)">Create</button>
</div>
