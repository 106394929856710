import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

// For mat-table
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MeasurementService } from '../backend-api/measurement/measurement.service';
import {
  Measurement,
  MeasurementData,
} from '../backend-api/measurement/measurement';
import { PaginatedListResponse } from '../backend-api/api-mixins';
import { Site } from '../backend-api/site/site';
import { takeUntil } from 'rxjs/operators';
import { OilReport } from '../backend-api/oil-report/oil-report';

export interface MeasurementPlot {
  path?: string;
  data: Measurement[];
}

export interface ActiveMeasurement {
  measurementPosition: number;
  measurementName: string;
  pathString: string;
}

@Component({
  selector: 'app-site-archive',
  templateUrl: './site-archive.component.html',
  styleUrls: ['./site-archive.component.scss'],
})
export class SiteArchiveComponent implements OnInit {
  @Input() site: Site;
  oilReports: OilReport[] = [];

  destroy$ = new Subject<void>();

  displayedColumns: string[] = [
    'component',
    'date',
    'type',
    'status',
    'maintenance',
  ];

  // For mat-table
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  machineStatusSubject: BehaviorSubject<{ [machineName: string]: string }>;
  activeMeasurementSubject: BehaviorSubject<ActiveMeasurement>;
  displayMeasurements: BehaviorSubject<MeasurementPlot>;
  activeMeasurementPath: string;

  isOilReportsLoading = false;
  isVibrationReportsLoading = false;
  isMeasurementsLoading = true;

  constructor(private measurementService: MeasurementService) {
    this.machineStatusSubject = new BehaviorSubject(null);
    this.activeMeasurementSubject = new BehaviorSubject(null);
    this.displayMeasurements = new BehaviorSubject(null);
  }

  ngOnInit() {
    this.setupSubs();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  setupSubs() {
    this.activeMeasurementSubject
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (!value) return;
        this.activeMeasurementPath = value.pathString;
        this.updateActiveMeasurement(
          value.measurementPosition,
          value.measurementName,
          value.pathString
        );
      });
  }

  updateActiveMeasurement(measurement_pos_id, measurement_name, path) {
    this.isMeasurementsLoading = true;
    this.measurementService
      .list(
        { measurement_position: measurement_pos_id, name: measurement_name },
        undefined,
        '-measurement_date',
        5
      )
      .then(
        (res: PaginatedListResponse<Measurement>) => {
          let results = res.results;
          if (!results) {
            this.isMeasurementsLoading = false;
            return;
          }
          let measurement_ids: number[] = [];
          results.forEach((measurement: Measurement) => {
            measurement_ids.push(measurement.id);
          });
          this.measurementService
            .listData({ id__in: measurement_ids.join(',') })
            .then((dataRes: PaginatedListResponse<MeasurementData>) => {
              dataRes.results.forEach((element) => {
                let measurment = results.find(
                  (measurement) => measurement.id === element.id
                );
                if (measurment) {
                  measurment.data = element;
                }
              });
              this.displayMeasurements.next({ path: path, data: results });
              this.isMeasurementsLoading = false;
            });
        },
        (err) => {
          console.warn(err);
          this.isMeasurementsLoading = false;
        }
      );
  }
}
