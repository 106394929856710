import { Component, OnInit, Input, ViewChild, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { OilReportService } from '../backend-api/oil-report/oil-report.service';
import { Site, SiteInterface } from '../backend-api/site/site';
import {
  OilReport,
  OilReportInterface,
} from '../backend-api/oil-report/oil-report';
import { SiteService } from '../backend-api/site/site.service';
import { environment } from '../../environments/environment';
import { BackendPaginatedTableDataSource } from '../backend-api/api-paginated-table-data-source';
import { getOilReportStatus } from '../ts-tools/utils';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { OilReportDialogComponent } from '../dialogs/oil-report-dialog/oil-report-dialog.component';

interface OilReportTableData {
  dateApproved: string;
  machineName?: string;
  machineDescription: string;
  status: number;
  oilReport: OilReport;
}

@Component({
  selector: 'app-oil-report-table',
  templateUrl: './oil-report-table.component.html',
  styleUrls: ['./oil-report-table.component.scss'],
})
export class OilReportTableComponent implements OnInit {
  @Input() siteId: number;
  @Input() machineId: number;
  isLoading = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  searchForm = new UntypedFormControl();
  destroy$ = new Subject<void>();

  public dataSource: MatTableDataSource<OilReport>;
  public displayedColumns: string[] = ['dateApproved', 'machineName', 'status'];

  assetFilter = {};

  oilReportTableDataSource = new BackendPaginatedTableDataSource<
    OilReportInterface,
    OilReportTableData
  >(
    this.oilReportService,
    (oilReports) => {
      let tableData: OilReportTableData[] = [];
      oilReports.forEach((oilReport) => {
        tableData.push({
          machineDescription: oilReport.descriptionofmachine,
          status: getOilReportStatus(oilReport.severitylevel)['number'],
          dateApproved: oilReport.laboratory_report_date,
          oilReport: new OilReport(oilReport),
        });
      });
      return tableData;
    },
    10
  );

  public usePaginator: boolean = true;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private oilReportService: OilReportService
  ) {}

  ngOnInit() {
    if (this.siteId) this.assetFilter = { routing__machine__site: this.siteId };
    if (this.machineId)
      this.assetFilter = {
        ...this.assetFilter,
        ...{ routing__machine: this.machineId },
      };

    this.isLoading = true;
    this.oilReportTableDataSource.filterBy(this.assetFilter).then(() => {
      this.isLoading = false;
    });

    this.searchForm.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(300))
      .subscribe((VibReportSearch) => {
        this.applyOilReportSearchFilter(VibReportSearch);
      });
  }

  ngAfterViewInit(): void {
    this.oilReportTableDataSource.dataSource.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  async updatePage(event: any) {
    this.isLoading = true;
    await this.oilReportTableDataSource.updatePage(event);
    this.isLoading = false;
  }

  async applyOilReportSearchFilter(search: string) {
    this.isLoading = true;
    await this.oilReportTableDataSource.filterBy({
      ...this.assetFilter,
      ...{ search: search },
    });
    this.isLoading = false;
  }
  redirect(siteId: string) {
    this.router.navigate(['/site', { id: siteId }]);
  }

  openDialog(oilReportId, siteId, machineId): void {
    const dialogRef = this.dialog.open(OilReportDialogComponent, {
      width: '1050px',
      data: {
        oilReportId: oilReportId,
        siteId: siteId,
        machineId: machineId,
      },
      panelClass: ['dialog__no-padding'],
    });
  }
}
