<div id="logo">
  <a routerLink="/" title="Go to fleet overview">
    <img src="assets/img/logo/IKM-KarstenMoholt-hori-white-on-transparent.svg">
  </a>
</div>

<mat-nav-list>
  <!-- Added active option 'exact' for root url with no link to work -->
  <a mat-list-item routerLink="/" [routerLinkActive]="['is-active']" [routerLinkActiveOptions]="{exact: true}">
    <mat-icon>directions_boat</mat-icon>
    <span class="router-text">Fleet overview</span>
  </a>

  <a *ngIf="featureToggle?.enable_kpi" mat-list-item routerLink="/kpi" [routerLinkActive]="['is-active']"
    matTooltip="Key performance indicators">
    <i style="margin-right: 15px; margin-left: 2px; font-size: 20px;" class="fas fa-tachometer"></i>
    <span class="router-text">&nbsp;KPI</span>
  </a>

  <a *ngIf="featureToggle?.enable_offline_data_uploader" mat-list-item routerLink="/upload"
    [routerLinkActive]="['is-active']">
    <i style="margin-right: 15px; margin-left: 2px; font-size: 20px; width: 20px;" class="fas fa-cloud-upload-alt"
      matTooltip="Upload data"></i>
    <span class="router-text">&nbsp;Data upload</span>
  </a>

  <a mat-list-item routerLink="/archive" [routerLinkActive]="['is-active']">
    <i style="margin-right: 15px; margin-left: 2px; font-size: 20px;" class="fas fa-archive"
      matTooltip="Archive"></i>
    <span class="router-text">&nbsp;Archive</span>
  </a>

  <a mat-list-item routerLink="/support/" [routerLinkActive]="['is-active']">
    <mat-icon>phone</mat-icon>
    <span class="router-text">Support</span>
  </a>
</mat-nav-list>

<!-- Bottom menu -->
<mat-nav-list class="bottom-menu">

  <a mat-list-item routerLink="/profile" [routerLinkActive]="['is-active']">
    <mat-icon>person</mat-icon>
    <span class="router-text">User profile</span>
  </a>

  <!-- <a mat-list-item (click)="startIntro()">
    <mat-icon>help_outline</mat-icon>
    <span class="router-text">Quick start</span>
  </a> -->

  <a mat-list-item target="_blank" href="http://manual.skaiwatch.no/" matTooltip="Opens in new tab">
    <mat-icon>open_in_new</mat-icon>
    <span class="router-text">User manual </span>
  </a>

  <a mat-list-item (click)="logout()">
    <mat-icon>lock</mat-icon>
    <span class="router-text">Log out</span>
  </a>
</mat-nav-list>
