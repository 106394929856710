<div>
  <ul class="legend" *ngIf="siteGraphic">
    <li class="alarm-box">
      <span>Alarm</span>
    </li>
    <li class="warning-box">
      <span>Warning</span>
    </li>
    <li class="good-box">
      <span>Good</span>
    </li>
  </ul>

  <canvas #siteCanvas (window:resize)="onResize($event)" height="400" width="800">
    Your browser does not support the HTML5 canvas tag.
  </canvas>
</div>
