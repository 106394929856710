import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ConfirmActionDialog {
  title?: string;
  message: string;
  buttonName: string;
  action?: () => void;
  secondaryButtonName?: string;
  secondaryAction: () => void;
  cancelCallback?: () => void;
}

/**
 * Dialog box to confirm actions like
 *
 * - Are you sure you want to delete X?
 * - Do you want to save before closing window?
 *
 * @example
 *
 * // Add MatDialog to component constructor
 * constructor(public dialog: MatDialog)
 *
 * // Open a dialog
 * this.dialog.open(ConfirmActionDialog, {
    data: {
      title: `Do you want to save the changes to report ${this.report.id} before closing?`,
      message: `Your changes will be lost if you don't save them.`,
      buttonName: 'Save',
      action: () => {
        // Save changes and close window
        console.log('Save changes');
        this.saveChanges();
        window.close();
      },
      secondaryButtonName: `Don't save`,
      secondaryAction: () => {
        console.log(`Don't save changes`);
        // Close window without saving changes
        window.close();
      },
      cancelCallback: () => {
        console.log('Cancel');
      },
  });
 *
 */
@Component({
  selector: 'confirm-action-dialog',
  templateUrl: './confirm-action-dialog.component.html',
  styleUrls: ['./confirm-action-dialog.component.scss'],
})
export class ConfirmActionDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmActionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmActionDialog
  ) {}

  primaryAction() {
    if (this.data.action) this.data.action();

    const userIsSure = true;
    this.dialogRef.close(userIsSure);
  }

  cancel() {
    if (this.data.cancelCallback) this.data.cancelCallback();

    const userIsSure = false;
    this.dialogRef.close(userIsSure);
  }

  secondaryAction() {
    if (this.data.secondaryAction) this.data.secondaryAction();

    const userIsSure = false;
    this.dialogRef.close(userIsSure);
  }
}
