import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthService } from '../_services/auth.service';
import { Router } from '@angular/router';
@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  private tokenWillRefresh = false;

  constructor(public authService: AuthService, private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = this.forceHttpsIfBackendApiUrlIsHttps(request);

    if (this.toBackendAndLoggedIn(request.url))
      request = this.injectBackendApiJwtToken(request);

    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // Refresh backend token after 60 seconds if request is to backend
            this.refreshBackendTokenAfter60SecondsIfRequestIsToBackend(request);
          }
        },
        (error: any) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
              // Unauthorized
              // redirect to the login route
              this.authService.logout();
              this.router.navigate(['login']);
            }
          }
        }
      )
    );
  }

  /**
   * Refresh backend token after 60 seconds if request is to backend
   * @param request : HttpRequest<any>
   */
  private refreshBackendTokenAfter60SecondsIfRequestIsToBackend(
    request: HttpRequest<any>
  ) {
    if (request.url === this.authService.userTokenRefreshUrl) return; // If request to refresh token, skip to avoid interception loop.

    if (this.toBackendAndLoggedIn(request.url)) {
      if (!this.tokenWillRefresh) {
        this.tokenWillRefresh = true;
        setTimeout(async () => {
          if (this.authService.isLoggedIn())
            await this.authService.refreshToken();
          this.tokenWillRefresh = false;
        }, 60000);
      }
    }
  }

  private toBackendAndLoggedIn(url: string) {
    return (
      this.isOfDomain(url, environment.BACKEND_BASE_URL) &&
      this.authService.isLoggedIn()
    );
  }

  private injectBackendApiJwtToken(
    request: HttpRequest<any>
  ): HttpRequest<any> {
    request = request.clone({
      setHeaders: {
        Authorization: `JWT ${this.authService.token}`,
      },
    });
    return request;
  }

  /** Django Rest Framework may not use https when displaying urls even if the hosting server is https.
   * This function force all backend urls to use https if environment.backendApiUrl uses https.*/
  private forceHttpsIfBackendApiUrlIsHttps(
    request: HttpRequest<any>
  ): HttpRequest<any> {
    const httpsUrl = this.forceHttps(request.url);

    if (this.isOfDomain(httpsUrl, environment.BACKEND_BASE_URL)) {
      request = request.clone({
        url: httpsUrl,
      });
    }
    return request;
  }

  private forceHttps(url: string): string {
    return url.replace(/^https?/, 'https');
  }

  private isOfDomain(url: string, domain: string): boolean {
    return url.search(`^${domain}`) > -1;
  }
}
