export function isNumber(n: any): boolean {
  return !isNaN(parseFloat(`${n}`));
}

/**
 * Return true if undefined, null or NaN
 */
export function isTrash(anything: any): boolean {
  const stringParsed = `${anything}`;
  if (stringParsed === 'undefined') return true;
  if (stringParsed === 'NaN') return true;
  if (stringParsed === 'null') return true;
  return false;
}
