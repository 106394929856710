<div class="overview-container">
  <div class="main-header">
    <div style="margin-right: auto;">
      <div class="main-header-title">Conversations</div>
    </div>
    <div class="icon-buttons">
      <i *ngIf="!isLoading else loadingSpinner" class="fas fa-comment-plus button" matTooltip="Create new thread"
        matTooltipPosition="below" (click)="openDialog()" aria-hidden="true"></i>
    </div>
  </div>
  <div style="margin-right: auto; display: flex; padding: 0 24px;">
    <div class="thread-header-resolve"></div>
    <div class="thread-header-date">Date</div>
    <div class="thread-header-user">User</div>
    <div class="thread-header-title">Title</div>
  </div>

  <mat-accordion>
    <div *ngFor="let thread of threads let i = index">

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <div class="thread-header-resolve">
            <i class="fas fa-comment-check" *ngIf="thread.is_resolved" matTooltip="Resolved"></i>
            <i class="fas fa-comments" *ngIf="!thread.is_resolved" matTooltip="Open"></i>
          </div>
          <p class="thread-date">{{thread.date_created | date:'d MMM yyyy, HH:mm'}}</p>
          <p class="thread-user">{{ showThreadUserName(thread) }}</p>
          <p class="thread-title">{{ thread?.title }}</p>
        </mat-expansion-panel-header>

        <div class="comment-container comment-app">
          <div class="comments">
            <div class="comment" [ngClass]="{'resolved-comment': thread.is_resolved}"
              *ngFor="let c of thread?.comments let i = index">
              <div class="container">
                <div class="comment-btn-group">
                  <div class="user-name">{{c.user.first_name + ' ' + c.user.last_name}}</div>
                  <div class="date-created">{{c.date_created | date:'d MMM yyyy, HH:mm'}}</div>

                  <i class="fal fa-pencil-alt comment-btn-icon" *ngIf="!thread.is_resolved && isSelf(c)"
                    (click)="editComment(c, i)" matTooltip="Edit comment"></i>
                  <i class="fal fa-trash comment-btn-icon" *ngIf="!thread.is_resolved && isSelf(c)"
                    (click)="deleteComment(c, i)" matTooltip="Delete comment"></i>
                </div>

                <markdown class="markdown selectable-text" [data]="c.comment" ngPreserveWhitespaces></markdown>
              </div>
            </div>
          </div>

          <form [formGroup]="commentForm" class="add-comment form-field">
            <div class="container">
              <mat-form-field class="add-comment-form-field">
                <mat-label>Add comment</mat-label>
                <textarea #comment matInput placeholder="Type a new comment" formControlName="commentInput"
                  cdkTextareaAutosize cdkAutosizeMinRows="4" cdkAutosizeMaxRows="8" cdkAutosizeMinRows="4"
                  cdkAutosizeMaxCols="8" (click)="selectComment(thread)"></textarea>
              </mat-form-field>
            </div>
          </form>

          <div class="container">
            <div class="buttons">
              <button *ngIf="!editingComment" class="send-btn" [disabled]="!isComment(thread?.id) || thread.is_resolved"
                mat-flat-button color="primary" (click)="sendComment(thread)">
                Post
              </button>

              <div class="update-cancel-btns">
                <button *ngIf="editingComment" class="update-btn" [disabled]="!isComment(thread?.id)" mat-button
                  color="primary" (click)="cancelEdit()"> Cancel </button>

                <button *ngIf="editingComment" class="update-btn" [disabled]="!isComment(thread?.id)" mat-flat-button
                  color="primary" (click)="sendComment(thread)"> Update </button>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
  </mat-accordion>


</div>


<ng-template #loadingSpinner>
  <div>
    <mat-spinner diameter="25"></mat-spinner>
  </div>
</ng-template>
