import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
  user2String,
  UserProfileInterface,
} from 'src/app/backend-api/user/user';
import { AuthService } from 'src/app/_services/auth.service';
import {
  ThreadService,
  CommentsService,
} from 'src/app/site-dashboard/machine-comments/machine-comments.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateThreadDialogComponent } from 'src/app/widgets/dialogs/create-thread-dialog/create-thread-dialog.component';

export interface Thread {
  id: number;
  machine: number;
  report: number;
  title: string;
  user: UserProfileInterface;
  date_created: string;
  is_resolved: boolean;
  is_public: boolean;
  comments?: Comment[];
}

export interface Comment {
  id: number;
  thread: number;
  comment: string;
  user: UserProfileInterface;
  date_created: string;
}

@Component({
  selector: 'app-machine-comments',
  templateUrl: './machine-comments.component.html',
  styleUrls: ['./machine-comments.component.scss'],
})
export class MachineCommentsComponent implements OnChanges {
  @Input() machineId: number;

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private threadService: ThreadService,
    private commentsService: CommentsService,
    public dialog: MatDialog
  ) {}
  @Output() nUnresolved = new EventEmitter<number>();
  @ViewChild('comment') commentInputElement: ElementRef;
  user = this.authService.loggedInUser;
  commentForm: UntypedFormGroup;
  isLoading = false;
  threads: Thread[];
  selectedThreadID: number = -1;

  openDialog(): void {
    const dialogRef = this.dialog.open(CreateThreadDialogComponent, {
      width: '450px',
      data: { title: '', comment: '' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      this.createThread(result);
    });
  }

  async ngOnChanges(): Promise<void> {
    this.commentForm = this.fb.group({
      commentInput: '',
    });

    if (this.machineId === null) return;
    this.threads = (
      await this.threadService.list({
        page_size: 1000,
        machine: this.machineId,
        is_public: true,
      })
    ).results;
  }

  async DoChanges(): Promise<void> {
    this.commentForm = this.fb.group({
      commentInput: '',
    });

    if (this.machineId === null) return;
    this.threads = (
      await this.threadService.list({
        page_size: 1000,
        machine: this.machineId,
        is_public: true,
      })
    ).results;
  }

  isSelf(comment: Comment): boolean {
    return this.user.user_id === comment.user.id;
  }

  editingComment = false;
  editCommentId: number;
  editCommentIndex: number;
  editComment(comment: Comment, index: number): void {
    this.editingComment = true;
    this.editCommentId = comment.id;
    this.editCommentIndex = index;
    this.commentForm.get('commentInput').setValue(comment.comment);
    this.commentInputElement.nativeElement.focus();
  }

  isComment(threadId: number): boolean {
    if (threadId === this.selectedThreadID)
      return this.commentForm.get('commentInput').value;
    else return false;
  }

  selectComment(thread: Thread): void {
    if (thread.id !== this.selectedThreadID) this.commentForm.reset();
    this.selectedThreadID = thread.id;
  }

  deleteComment(comment: Comment, index: number): void {
    this.commentsService.destroy(comment.id).then(
      () => {
        this.DoChanges();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  sendComment(thread: Thread): void {
    const comment = this.commentForm.get('commentInput').value;
    const threadId = thread.id;

    if (!comment) return;

    if (this.editingComment) {
      this.updateComment(comment, threadId);
    } else {
      this.createComment(comment, threadId);
    }
  }

  updateComment(comment: string, threadId: number): void {
    this.commentsService
      .update(this.editCommentId, {
        comment: comment,
        thread: threadId,
      })
      .then(
        () => {
          this.DoChanges();
          this.cancelEdit();
        },
        (error) => {
          console.log(error);
        }
      );
  }

  cancelEdit(): void {
    this.commentForm.reset();
    this.editCommentId = undefined;
    this.editCommentIndex = undefined;
    this.editingComment = false;
  }

  createComment(comment: string, threadId: number): void {
    this.commentsService
      .create({
        comment: comment,
        thread: threadId,
      })
      .then(
        (comment) => {
          this.DoChanges();
        },
        (error) => {
          console.log(error);
        }
      );
  }

  createThread(data): void {
    this.isLoading = true;
    this.threadService
      .create({
        machine: this.machineId,
        is_public: true,
        title: data.title,
      })
      .then(
        (thread) => {
          this.createComment(data.comment, thread.id);
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          console.log(error);
        }
      );
  }

  deleteThread(thread: Thread): void {
    this.threadService.destroy(thread.id).then(
      () => {
        this.DoChanges();
      },
      (error) => {
        console.log(error);
      }
    );
  }
  showThreadUserName(thread: Thread): string {
    if (thread.user) return user2String(thread.user);
    return '';
  }
}
