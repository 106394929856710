import { Component, OnInit, ViewChild } from '@angular/core';
import {
  SiteManagementSampleService,
  SiteManagementData,
} from '../_services/site-management-sample.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, BehaviorSubject } from 'rxjs';
import { SiteManagementDetailComponent } from './modal/site-management-detail.component';
import { SiteService } from '../backend-api/site/site.service';

export interface SiteTreeStructure {
  id: number;
  checked: boolean;
  name: string;
}

export interface SiteGroupTreeStructure {
  name: string;
  checked: boolean;
  site_nodes?: Array<SiteTreeStructure>;
}

export interface SiteGroupFamilyTreeStructure {
  name: string;
  checked: boolean;
  site_groups?: Array<SiteGroupTreeStructure>;
}
export class SiteManagementFilter {
  site_ids: BehaviorSubject<number[]> = new BehaviorSubject([]);
  from_date: BehaviorSubject<Date> = new BehaviorSubject(new Date());
  to_date: BehaviorSubject<Date> = new BehaviorSubject(new Date());
}

@Component({
  selector: 'app-site-management',
  templateUrl: './site-management.component.html',
  styleUrls: ['./site-management.component.scss'],
})
export class SiteManagementComponent implements OnInit {
  tableDataSource: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns: string[] = [
    'site_group_family',
    'site_group',
    'site_name',
    'good',
    'detail',
  ];

  data_observable: Observable<SiteManagementData[]>;
  sites: Array<any> = [];
  siteManagementData: BehaviorSubject<
    SiteManagementData[]
  > = new BehaviorSubject([]);
  siteTreeStructure: Array<SiteGroupFamilyTreeStructure> = [];
  minDate: Date = new Date('2010-01-01T00:00:00');
  maxDate: Date = new Date();

  original_data: SiteManagementData[] = [];
  filter: SiteManagementFilter = new SiteManagementFilter();

  constructor(
    private SMSService: SiteManagementSampleService,
    public dialog: MatDialog,
    private siteService: SiteService
  ) {
    this.siteService.list({ page_size: 1000 }).then((res) => {
      res.results.forEach((element: any) => {
        this.insert_into_site_management_data(element);
      });
    });

    this.data_observable = this.siteManagementData.asObservable();

    this.data_observable.subscribe((value: SiteManagementData[]) => {
      this.tableDataSource = new MatTableDataSource(value);
      this.tableDataSource.sort = this.sort;
      this.siteTreeStructure = [];
      value.forEach((element) => {
        if (element.site_group_family) {
          this.addKeyToSiteTreeStructure(
            element.site_group_family,
            element.site_group,
            { id: element.site_id, name: element.site_name, checked: true }
          );
        } else {
          this.addKeyToSiteTreeStructure('Other', 'Other', {
            id: element.site_id,
            name: element.site_name,
            checked: true,
          });
        }
      });
      this.post_site_treee_update();
    });

    this.filter.site_ids.subscribe((val) => {
      this.filterData();
    });
    this.filter.from_date.next(this.minDate);
    this.filter.from_date.subscribe((val) => {
      this.filterData();
    });
    this.filter.to_date.next(this.maxDate);
    this.filter.to_date.subscribe((val) => {
      this.filterData();
    });
  }

  insert_into_site_management_data(element) {
    this.sites.push(element);
    this.original_data = this.SMSService.generate_data(
      this.sites,
      this.minDate,
      this.maxDate
    );
    this.siteManagementData.next(this.original_data);
  }

  filterData() {
    let o_data: SiteManagementData[] = this.original_data;
    let result: SiteManagementData[] = [];

    for (let i = 0; i < o_data.length; i++) {
      if (this.filter.site_ids.value.includes(o_data[i].site_id)) {
        let tmp_site = Object.create(o_data[i]);
        tmp_site.good = 0;
        tmp_site.month_summary = [];

        for (let k = 0; k < o_data[i].month_summary.length; k++) {
          let data = o_data[i].month_summary[k];
          if (
            data.date <= this.filter.to_date.value &&
            data.date >= this.filter.from_date.value
          ) {
            tmp_site.month_summary.push(data);

            if (
              data.measurements_received_on_time === data.total_measurements
            ) {
              tmp_site.good++;
            }
          }
        }

        result.push(tmp_site);
      }
    }

    this.tableDataSource = new MatTableDataSource(result);
    this.tableDataSource.sort = this.sort;
  }

  updateDate(date_subject: BehaviorSubject<Date>, value: string) {
    date_subject.next(new Date(value));
  }

  addKeyToSiteTreeStructure(
    site_group_family: string,
    site_group: string,
    site_node
  ) {
    let family = this.siteTreeStructure.find(
      (e) => e.name === site_group_family
    );

    if (!family) {
      this.siteTreeStructure.push({
        name: site_group_family,
        checked: true,
        site_groups: [
          { name: site_group, checked: true, site_nodes: [site_node] },
        ],
      });
    } else {
      let group = family.site_groups.find((e) => e.name === site_group);

      if (!group) {
        family.site_groups.push({
          name: site_group,
          checked: true,
          site_nodes: [site_node],
        });
      } else {
        group.site_nodes.push(site_node);
      }
    }
  }
  ngOnInit() {}

  site_group_family_checked(
    site_group_family: SiteGroupFamilyTreeStructure
  ): boolean {
    for (let i = 0; i < site_group_family.site_groups.length; i++) {
      if (this.site_group_checked(site_group_family.site_groups[i])) {
        return true;
      }
    }

    return false;
  }

  site_group_checked(site_group: SiteGroupTreeStructure): boolean {
    for (let i = 0; i < site_group.site_nodes.length; i++) {
      if (site_group.site_nodes[i].checked) {
        return true;
      }
    }
    return false;
  }

  getAllSiteIdsFromTree() {
    let res: number[] = [];
    this.siteTreeStructure.forEach((family) => {
      family.site_groups.forEach((group) => {
        group.site_nodes.forEach((site) => {
          if (site.checked) {
            res.push(site.id);
          }
        });
      });
    });

    return res;
  }

  post_site_treee_update() {
    this.filter.site_ids.next(this.getAllSiteIdsFromTree());
  }

  toggle_site_group_family(checked, site_group_family_name) {
    let family = this.siteTreeStructure.find(
      (e) => e.name === site_group_family_name
    );

    family.site_groups.forEach((group) => {
      group.checked = checked;
      group.site_nodes.forEach((site_node) => {
        site_node.checked = checked;
      });
    });

    this.post_site_treee_update();
  }

  toggle_site_group(checked, site_group_name) {
    let family: SiteGroupFamilyTreeStructure = undefined;
    let group: SiteGroupTreeStructure = undefined;

    for (let i = 0; i < this.siteTreeStructure.length; i++) {
      let f = this.siteTreeStructure[i];
      let g = f.site_groups.find((e) => e.name === site_group_name);
      if (g) {
        group = g;
        family = f;
        break;
      }
    }

    group.site_nodes.forEach((site) => {
      site.checked = checked;
    });

    family.checked = this.site_group_family_checked(family);
    this.post_site_treee_update();
  }

  toggle_site(checked, site_id) {
    let search = this.find_site_in_structure(site_id);
    let family = search.family;
    let group = search.group;
    let site = search.site;

    site.checked = checked;
    group.checked = this.site_group_checked(group);
    family.checked = this.site_group_family_checked(family);

    this.post_site_treee_update();
  }

  find_site_in_structure(site_id) {
    let family: SiteGroupFamilyTreeStructure = undefined;
    let group: SiteGroupTreeStructure = undefined;
    let site: SiteTreeStructure = undefined;

    for (let i = 0; i < this.siteTreeStructure.length; i++) {
      let f = this.siteTreeStructure[i];
      for (let k = 0; k < f.site_groups.length; k++) {
        let g = f.site_groups[k];
        let s = g.site_nodes.find((e) => e.id == site_id);
        if (s) {
          return {
            family: f,
            group: g,
            site: s,
          };
        }
      }
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tableDataSource.filter = filterValue.trim().toLowerCase();
  }

  openDialog(_siteManagementData: SiteManagementData): void {
    const dialogRef = this.dialog.open(SiteManagementDetailComponent, {
      width: '1050px',
      data: { siteManagementData: _siteManagementData },
    });
  }
}
