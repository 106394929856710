import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
} from '@angular/common/http';
import { LCRUDMixin } from '../api-mixins';
import { OilReportInterface } from './oil-report';
import { LRCache } from '../api-cache';
import { environment } from 'src/environments/environment';
import { SiteOilReportStatus } from './oil-report';
import * as FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class OilReportService extends LCRUDMixin<OilReportInterface> {
  protected modelBaseUrl: string;
  protected lrCache = new LRCache(5 * 60 * 1000);

  constructor(protected http: HttpClient) {
    super(environment.BACKEND_BASE_URL + 'oil-report/', http);
  }

  /**
   * Returns the latest published report on each machine on a site. Cache the results.
   * @param siteId
   */
  getSiteOilReportStatus(siteId: number): Promise<SiteOilReportStatus[]> {
    let url = `${this.modelBaseUrl}site-oil-report-status/?site=${siteId}`;
    if (this.lrCache) {
      if (this.lrCache.inCache(url)) return this.lrCache.getCachePromise(url);
      return this.lrCache.setCacheReturnPromise<SiteOilReportStatus[]>(
        url,
        this.modelBaseUrl,
        this.http.get<SiteOilReportStatus[]>(url).toPromise()
      );
    }
    return this.http.get<SiteOilReportStatus[]>(url).toPromise();
  }

  downloadFullPdfReport(id: number, filename?: string): Promise<void> {
    const url = `${this.modelBaseUrl}${id}/pdf/`;

    const downloadRequest = new HttpRequest('GET', url, {
      reportProgress: true,
      responseType: 'blob',
    });

    if (!filename) filename = 'oil_report.pdf';

    const request = this.http.request(downloadRequest);
    return new Promise<void>((resolve, reject) => {
      request.subscribe(
        (event: HttpEvent<unknown>) => {
          if (event && event.type === HttpEventType.ResponseHeader) {
            const contentDispositionHeader = event.headers.get(
              'Content-Disposition'
            );
            if (contentDispositionHeader) {
              filename = contentDispositionHeader.slice(
                contentDispositionHeader.search('filename') + 10
              );
              filename = filename.slice(0, filename.length - 1);
            }
          }

          if (event && event.type === HttpEventType.Response) {
            FileSaver.saveAs(event.body, filename, true);
            resolve();
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}
