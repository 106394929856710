<div class="bg-image">
  <div class="bg-text intro-animation">

    <div class="head-container">
      <!-- skAIWatch logo -->
      <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 271.4 135.32">
        <g id="Layer_4" data-name="Layer 4">
          <path class="cls-1"
            d="M110.8,66.83a26,26,0,0,1,25.71-8.24,29.79,29.79,0,0,1-1.21-8.44A30.19,30.19,0,0,1,192.17,36a24.28,24.28,0,0,1,41.16,23.4,25.68,25.68,0,0,1,20.29,25.39c0,12.92-7.62,21.53-19.53,24.91-.32.36-4.79.6-5.6.6H128c-6.09-.47-11.69-4-15.94-8.43" />
        </g>
        <g id="Layer_5" data-name="Layer 5">
          <g id="Layer_6" data-name="Layer 6">
            <path class="cls-2"
              d="M123.91,76.88l-.17-.29C121,72,117,70.75,115.22,70.74h0a.71.71,0,0,0-.56.3v0L113.41,73a.57.57,0,0,0,0,.56l.27.41a9.71,9.71,0,0,1,.93,1.55l-3,1.67-2.95-1.67a9.71,9.71,0,0,1,.93-1.55l.27-.41a.57.57,0,0,0,0-.56l-1.23-1.89,0,0a.71.71,0,0,0-.56-.3h0c-1.76,0-5.79,1.24-8.52,5.85l-.17.29c-2.62,4.67-1.67,8.78-.8,10.31h0a.69.69,0,0,0,.54.33h0l2.25.13a.64.64,0,0,0,.51-.26l.21-.44a10.09,10.09,0,0,1,.88-1.58l2.92,1.72,0,3.39a10.07,10.07,0,0,1-1.8,0l-.49,0a.58.58,0,0,0-.48.31l-1,2a0,0,0,0,1,0,0,.66.66,0,0,0,0,.63h0c.89,1.52,4,4.4,9.33,4.46h.33c5.35-.06,8.44-2.94,9.33-4.46h0a.68.68,0,0,0,0-.63s0,0,0,0l-1-2a.58.58,0,0,0-.47-.31l-.49,0a10.19,10.19,0,0,1-1.81,0l0-3.39,2.92-1.72a10.09,10.09,0,0,1,.88,1.58l.21.44a.61.61,0,0,0,.51.25l2.26-.12h0a.67.67,0,0,0,.54-.33h0c.87-1.53,1.82-5.64-.81-10.31m-.91,9c-.69-1.44-1.47-2.81-2.63-2.81a1.45,1.45,0,0,0-.73.19l-4.36,2.56h0v0l0,5a1.43,1.43,0,0,0,.19.74c.58,1,2.16,1,3.76.87-.82,1.19-3.22,3.17-7.38,3.21h-.34c-4.16,0-6.56-2-7.37-3.21,1.59.12,3.17.14,3.75-.87a1.44,1.44,0,0,0,.2-.74l0-5v0h0l-4.35-2.56a1.5,1.5,0,0,0-.73-.19c-1.17,0-2,1.37-2.64,2.81-.63-1.3-1.13-4.36.91-8l.17-.3c2.12-3.59,5-4.68,6.46-4.79-.9,1.32-1.7,2.69-1.12,3.7a1.61,1.61,0,0,0,.54.53l4.39,2.49h0L116,77.08a1.61,1.61,0,0,0,.54-.53c.58-1-.22-2.38-1.12-3.7,1.44.11,4.34,1.2,6.46,4.79l.17.29c2,3.63,1.54,6.69.91,8" />
          </g>
        </g>
        <text class="cls-3" transform="translate(27.55 96.45)">sk<tspan class="cls-4" x="34.88" y="0">AI</tspan>
        </text>
        <text class="cls-5" transform="translate(130.28 96.59)">
          <tspan class="cls-6">w</tspan>
          <tspan x="28.22" y="0">a</tspan>
          <tspan class="cls-7" x="47.36" y="0">t</tspan>
          <tspan class="cls-8" x="60.03" y="0">c</tspan>
          <tspan x="78.08" y="0">h</tspan>
        </text>
      </svg>
    </div>

    <div class="login-group" *ngIf="!loading; else loadSpinner">
      <div *ngIf="selectedLogin===null" class="select-login-container">
        <div class="login-type-description">
          Please select login method
        </div>
        <button mat-raised-button (click)="selectLogin(1)" class="login-type">
          <div style="display: flex; justify-content: center; align-items: center;">
            <img src="assets/img/logo/km_wheel_white.svg">
            skAIwatch
          </div>
        </button>
        <div class="break"></div>
        <button mat-raised-button (click)="startMicrosoftPopUpLogin()" class="login-type">
          <div style="display: flex; justify-content: center; align-items: center;">
            <img src="assets/img/logo/Microsoft_logo.svg">
            Microsoft
          </div>
        </button>
        <div class="login-failed">
          <span *ngIf="error">{{ error.other }}<br /></span>
        </div>
      </div>
      <div *ngIf="selectedLogin===1">
        <form class="login-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <button type="button" *ngIf="selectedLogin!==null" mat-button (click)="selectLogin(null)" style="color:white">
            <div style="display: flex; align-items: center; justify-content: center;">
              <img style="height: 12px;" src="assets/img/misc/arrow-left.svg">
              <span style="font-weight: normal; margin-left: 5px;">Return</span>
            </div>
          </button>

          <mat-form-field class="login-field">
            <input matInput placeholder="Email" formControlName="email" [ngClass]="{ 'is-invalid': f.email.errors }"
              required>
          </mat-form-field>
          <div class="login-failed" *ngIf="error.email">
            <span>{{error.email}}</span>
          </div>


          <mat-form-field class="login-field">
            <input matInput placeholder="Password" formControlName="password" type="password"
              [ngClass]="{ 'is-invalid': f.password.errors }" required>
          </mat-form-field>
          <div class="login-failed" *ngIf="error.password">
            <span>{{error.password}}</span>
          </div>

          <button mat-raised-button type="submit" class="login-type">Log in</button>

          <a style="font-weight: normal;" href="{{resetPasswordUrl}}">Reset password</a>
          <div class="login-failed col-sm-12">
            <span *ngIf="error">{{ error.other }}<br /></span>
          </div>
        </form>
      </div>
    </div>
    <div class="dnv-logo-container">
      <img src="assets/img/logo/dnv_darkmode.svg" class="dnv-logo">
    </div>
  </div>

  <!------------------->
  <!-- Start: footer -->
  <!------------------->
  <footer class="page-footer" id="footer">
    <div class="footer-copyright text-center py-3">© {{ currentYear }} Copyright
      <a href="https://karsten-moholt.no/" target="_blank"> IKM Karsten Moholt AS</a>
    </div>
  </footer>
  <!------------------->
  <!-- end: footer   -->
  <!------------------->
  <ng-template #loadSpinner>
    <mat-spinner></mat-spinner>
  </ng-template>

</div>
