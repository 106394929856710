import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { ThemeService } from '../_services/theme.service';
import { Observable } from 'rxjs';

// For making KM logo to icon
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

// For tooltips
import { UntypedFormControl } from '@angular/forms';
import { TooltipPosition } from '@angular/material/tooltip';
import { SidenavService } from '../_services/sidenav.service';

// For footer
import { AuthService } from '../_services/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  mobileQuery: MediaQueryList;
  isDarkTheme: Observable<boolean>;
  isMobile: boolean;
  private _mobileQueryListener: () => void;

  // For tooltips
  positionOptions: TooltipPosition[] = [
    'right',
    'after',
    'before',
    'above',
    'below',
    'left',
  ];
  position = new UntypedFormControl(this.positionOptions[0]);

  constructor(
    private themeService: ThemeService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public sidenavService: SidenavService,
    private authService: AuthService
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    // For KM logo as icon
    this.matIconRegistry.addSvgIcon(
      'km_wheel_white',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/img/logo/km_wheel_white.svg'
      )
    );
  }

  ngOnInit() {
    this.isDarkTheme = this.themeService.isDarkTheme;
  }

  ngOnDestroy() {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  toggleDarkTheme(checked: boolean) {
    this.themeService.setDarkTheme(checked);
    console.info(`dark theme=${checked}`);
  }

  logout() {
    this.authService.logout();
  }

  toggleRightSidenav() {
    this.sidenavService.toggle();
  }

  get featureToggle() {
    return this.authService.featureToggle;
  }
}
