import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OilReportInterface } from '../../backend-api/oil-report/oil-report';
import { OilReportService } from '../../backend-api/oil-report/oil-report.service';
import { generateOilReportFileName } from '../../backend-api/oil-report/tools';
import { AuthService } from '../../_services/auth.service';
@Component({
  selector: 'app-oil-report-dialog',
  templateUrl: './oil-report-dialog.component.html',
  styleUrls: ['./oil-report-dialog.component.scss'],
})
export class OilReportDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      oilReport: OilReportInterface;
    },
    private oilReportService: OilReportService
  ) {}

  loadingPdf: boolean = false;
  loadingVerbose: boolean = true;

  async savePdf(): Promise<void> {
    this.loadingPdf = true;

    await this.oilReportService.downloadFullPdfReport(
      this.data.oilReport.id,
      generateOilReportFileName(this.data.oilReport)
    );
    this.loadingPdf = false;
  }

  close(): void {
    this.dialogRef.close();
  }
}
