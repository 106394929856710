<div class="container-fluid row">
  <div class="col-md-12 info-container row" style="height: fit-content;">
    <div class="col-md-6">
      <!---------------------------------------------------------------------->
      <!--                    Vibration Reports          									  -->
      <!---------------------------------------------------------------------->
      <mat-toolbar class="mat-toolbar-elem info-container-header">
        <mat-toolbar-row>
          <span>
            <mat-icon>graphic_eq</mat-icon> Vibration reports
          </span>
          <span class="spacer"></span>
        </mat-toolbar-row>
      </mat-toolbar>

      <app-vibration-report-table *ngIf="!isVibrationReportsLoading; else loadingSpinner" [siteId]="site.id">
      </app-vibration-report-table>
    </div>
    <div class="col-md-6">
      <!---------------------------------------------------------------------->
      <!--                    Oil Reports                  									  -->
      <!---------------------------------------------------------------------->

      <mat-toolbar class="mat-toolbar-elem info-container-header">
        <mat-toolbar-row>
          <span>
            <mat-icon svgIcon="oil_report"></mat-icon> Oil reports
          </span>

          <span class="spacer"></span>
        </mat-toolbar-row>
      </mat-toolbar>

      <app-oil-report-table *ngIf="!isOilReportsLoading; else loadingSpinner" [siteId]="site.id">
      </app-oil-report-table>
    </div>

  </div>
</div>

<!-- <hr> -->

<!---------------------------------------------------------------------->
<!--                    Data Analytics section         							  -->
<!---------------------------------------------------------------------->
<!-- <div class="container-fluid row"
 >
  <div class="col-12 site-header" style="height: 150px;">

    <div class="title">
      <h2>Vibration data analytics</h2> -->
      <!-- <p>See the data behind the reports to gain a better understanding of the analysis.</p> -->
    <!-- </div>
  </div>
</div> -->

<!-- 3rd row -->
<div class="container-fluid" style="display: flex; flex-basis: 300px;">

  <!---------------------------------------------------------------------->
  <!--                    Data Analytics                							  -->
  <!---------------------------------------------------------------------->

  <!-- <div class="machine-structure-tree-container p-0 info-container">
    <app-machine-structure-tree *ngIf="site" [site]="site" [activeMeasurementSubject]="activeMeasurementSubject">
    </app-machine-structure-tree>
  </div> -->


  <!-- <div class="plot-container row"
    [ngClass]="{ 'plot-container-visible': displayMeasurements.value || isMeasurementsLoading }">
    <div class="col-12 p-0" id="data-analytics-chart">
      <app-waterfall class="chart-container" [data]="displayMeasurements.value" [loading]="isMeasurementsLoading">
      </app-waterfall>

      <app-linechart class="chart-container" [data]="displayMeasurements.value" [loading]="isMeasurementsLoading">
      </app-linechart>
    </div>
  </div> -->
</div>
<hr>


<ng-template #loadingSpinner>
  <div style="height: 100%; width: 100%; margin:30px">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
