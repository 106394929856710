import { Component, Input, OnInit } from '@angular/core';

import { ISiteSubscription } from '../backend-api/site-subscription/site-subscription';
import { SiteSubscriptionService } from '../backend-api/site-subscription/site-subscription.service';
import { SiteService } from '../backend-api/site/site.service';
import { AuthService } from '../_services/auth.service';

@Component({
  selector: 'app-site-subscribe-toolbar',
  templateUrl: './site-subscribe-toolbar.component.html',
  styleUrls: ['./site-subscribe-toolbar.component.scss'],
})
export class SiteSubscribeToolbarComponent implements OnInit {
  @Input() siteId: number;
  @Input() siteSubscription: ISiteSubscription | null = null;
  isLoadingMissingMeasurementEmail: boolean = false;

  constructor(
    private siteSubscriptionService: SiteSubscriptionService,
    private authService: AuthService,
    private siteService: SiteService
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.siteId && this.siteSubscription === null)
      this.siteSubscription = await this.getSubscribed();
  }

  private async getSubscribed(): Promise<ISiteSubscription> {
    const subscriptions = (
      await this.siteSubscriptionService.list({
        user: this.authService.loggedInUser.user_id,
        site: this.siteId,
      })
    ).results;

    return subscriptions[0];
  }

  public async toggleMachineStatus(): Promise<void> {
    if (this.siteSubscription == null) {
      await this.subscribeIfNotSubscribed();
      return;
    }

    const responds = await this.siteSubscriptionService.toggleMachineStatus(
      this.siteId,
      this.siteSubscription
    );
    if (responds == 200) {
      this.siteSubscription.machine_status =
        !this.siteSubscription.machine_status;
      this.checkIfUnsubscribeConditionMet();
    }
  }

  public async toggleMachineMissingMeasurement(): Promise<void> {
    if (this.siteSubscription == null) {
      await this.subscribeIfNotSubscribed();
    }
    const responds =
      await this.siteSubscriptionService.toggleMachineMissingMeasurement(
        this.siteId,
        this.siteSubscription
      );
    if (responds == 200) {
      this.siteSubscription.machine_missing_measurement =
        !this.siteSubscription.machine_missing_measurement;
      this.checkIfUnsubscribeConditionMet();
    }
  }

  private async subscribeIfNotSubscribed(): Promise<void> {
    await this.siteService.subscribe(this.siteId);
    this.siteSubscription = await this.getSubscribed();
  }

  private async checkIfUnsubscribeConditionMet(): Promise<void> {
    if (
      this.siteSubscription.machine_missing_measurement == false &&
      this.siteSubscription.machine_status == false
    ) {
      this.unsubscribeIfToggleOff();
    }
  }

  private async unsubscribeIfToggleOff(): Promise<void> {
    await this.siteService.unsubscribe(this.siteId);
    this.siteSubscription = null;
  }
  public async sendMissingMeasurementEmail() {
    this.isLoadingMissingMeasurementEmail = true;
    await this.siteService.sendMissingMeasurementEmail(this.siteId);
    this.isLoadingMissingMeasurementEmail = false;
  }
}
