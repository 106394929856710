import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { SourceFileUploadService } from '../backend-api/source-file/source-file.service';
import { startWith, map } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmActionDialog,
  ConfirmActionDialogComponent,
} from '../widgets/dialogs/confirm-action-dialog/confirm-action-dialog.component';
import { SiteInterface } from '../backend-api/site/site';
import { SiteService } from '../backend-api/site/site.service';
import { SourceFileInterface } from '../backend-api/source-file/source-file';
import { UploadObject } from '../backend-api/file-uploader';
import { AttachmentUploadService } from '../backend-api/attachment/attachment.service';
import { AttachmentInterface } from '../backend-api/attachment/attachment';
import { UploadJobService } from '../backend-api/upload-job/upload-job.service';
import { AuthService } from '../_services/auth.service';
import { UploadJobInterface } from '../backend-api/upload-job/upload-job';
import { getFileExtension } from '../ts-tools/files';

@Component({
  selector: 'app-upload-source-files',
  templateUrl: './upload-source-files.component.html',
  styleUrls: ['./upload-source-files.component.scss'],
})
export class UploadSourceFilesComponent implements OnInit {
  // Forms
  commentFormControl = new UntypedFormControl();
  selectSiteFormControl = new UntypedFormControl({ value: '', disabled: true });

  // Select site
  filteredSites: Observable<SiteInterface[]>;
  sites: SiteInterface[] = null;
  selectedSite: SiteInterface = null;

  // Submit flags
  isSubmitting = false;
  isSubmitError = false;
  isSubmitSuccess = false;

  // Upload job flags
  isCreatingUploadJob = false;
  uploadJobFinishedError = false;
  currentUploadJob: UploadJobInterface = null;

  // Files
  sourceFileUploadObjects: UploadObject<SourceFileInterface>[] = [];
  attachmentUploadObjects: UploadObject<AttachmentInterface>[] = [];

  //
  currentStep = 1;

  private validSourceFileExtensions = ['odx'];

  constructor(
    private sourceFileUploadService: SourceFileUploadService,
    private attachmentUploadService: AttachmentUploadService,
    private uploadJobService: UploadJobService,
    private authService: AuthService,
    public dialog: MatDialog,
    private siteService: SiteService
  ) {}

  ngOnInit(): void {
    this.filteredSites = this.selectSiteFormControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.autoCompleteFilter(value))
    );

    this.siteService.list({ page_size: 10000 }).then((response) => {
      this.sites = response.results;
      this.selectSiteFormControl.enable();
    });
  }

  private autoCompleteFilter(value: string): SiteInterface[] {
    if (!this.sites) return;
    const filterValue = value.toLowerCase();

    return this.sites.filter((site) =>
      site.name.toLowerCase().includes(filterValue)
    );
  }

  selectSite(site: SiteInterface) {
    this.selectedSite = site;
  }
  deselectSite() {
    this.selectSiteFormControl.setValue('');
    this.selectedSite = null;
  }

  goToNextStep() {
    this.currentStep++;
  }
  goToPreviousStep() {
    this.currentStep--;
  }

  onSourceFileAddition(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      let file = files.item(i);
      let fileExtension = getFileExtension(file.name);

      if (!fileExtension) return;
      if (
        !this.validSourceFileExtensions.includes(fileExtension.toLowerCase())
      ) {
        console.warn(`Invalid file extension ${fileExtension}`);
        continue;
      }

      this.uploadSourceFileFile(files.item(i));
    }
  }

  onAttachmentAddition(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      let file = files.item(i);
      let fileExtension = getFileExtension(file.name);

      if (
        this.validSourceFileExtensions.includes(fileExtension.toLowerCase())
      ) {
        let confirmAddition = confirm(
          'Vibration measurement files should be added in step 2. Do you still want to add to attachments?'
        );

        if (!confirmAddition) continue;
      }

      this.uploadAttachmentFile(file);
    }
  }

  uploadSourceFileFile(file: File, index = null) {
    let uploadObject = this.sourceFileUploadService.createUploadObject(file);
    if (typeof index === 'number')
      this.sourceFileUploadObjects.splice(index, 0, uploadObject);
    else this.sourceFileUploadObjects.push(uploadObject);
  }

  uploadAttachmentFile(file: File, index = null) {
    let uploadObject = this.attachmentUploadService.createUploadObject(file);
    if (typeof index === 'number')
      this.attachmentUploadObjects.splice(index, 0, uploadObject);
    else this.attachmentUploadObjects.push(uploadObject);
  }

  showSelectSiteClearButton(): boolean {
    return (
      this.selectSiteFormControl.value && this.selectSiteFormControl.enabled
    );
  }

  private async fixSourceFileUploadError(
    uploadObject: UploadObject<SourceFileInterface>
  ) {
    this.sourceFileUploadService.fixErrors(uploadObject, {
      upload_job: this.currentUploadJob.id,
      company: this.selectedSite.company,
    });
  }

  private async fixAttachmentUploadError(
    uploadObject: UploadObject<AttachmentInterface>
  ) {
    this.attachmentUploadService.fixErrors(uploadObject, {
      upload_job: this.currentUploadJob.id,
      company: this.selectedSite.company,
    });
  }

  async removeSourceFileUpload(
    uploadObject: UploadObject<SourceFileInterface>,
    index: number
  ) {
    // First remove data file from array
    this.removeSourceFileUploadObjectFromArray(uploadObject);
    let isDestroyed = await this.sourceFileUploadService.destroyUpload(
      uploadObject
    );
    // Add data file back if there were any errors while destroying the upload.
    if (!isDestroyed)
      this.sourceFileUploadObjects.splice(index, 0, uploadObject);
    return isDestroyed;
  }

  async removeAttachmentUpload(
    uploadObject: UploadObject<AttachmentInterface>,
    index: number
  ) {
    // First remove data file from array
    this.removeAttachmentUploadObjectFromArray(uploadObject);
    let isDestroyed = await this.attachmentUploadService.destroyUpload(
      uploadObject
    );
    // Add data file back if there were any errors while destroying the upload.
    if (!isDestroyed)
      this.attachmentUploadObjects.splice(index, 0, uploadObject);
    return isDestroyed;
  }

  private removeSourceFileUploadObjectFromArray(
    uploadObject: UploadObject<SourceFileInterface>
  ) {
    /**
     * Removes a sourceFileUploadObject from the sourceFileUploadObjects array based on object reference.
     */
    for (let i = 0; i < this.sourceFileUploadObjects.length; i++) {
      if (this.sourceFileUploadObjects[i] === uploadObject) {
        this.sourceFileUploadObjects.splice(
          i,
          1
        ) as UploadObject<SourceFileInterface>[];
        return;
      }
    }
  }

  private removeAttachmentUploadObjectFromArray(
    uploadObject: UploadObject<AttachmentInterface>
  ) {
    /**
     * Removes a Attachment from the attachmentUploadObjects array based on object reference.
     */
    for (let i = 0; i < this.attachmentUploadObjects.length; i++) {
      if (this.attachmentUploadObjects[i] === uploadObject) {
        this.attachmentUploadObjects.splice(
          i,
          1
        ) as UploadObject<AttachmentInterface>[];
        return;
      }
    }
  }

  confirmBeforeRemoveSourceFile(
    uploadObject: UploadObject<SourceFileInterface>,
    index: number
  ): void {
    const dialogRef = this.dialog.open(ConfirmActionDialogComponent, {
      width: '400px',
      data: {
        message: `Are you sure you want to remove ${uploadObject.file.name}?`,
        buttonName: 'Remove',
      } as ConfirmActionDialog,
    });

    dialogRef.afterClosed().subscribe((userIsSure) => {
      if (userIsSure) {
        this.removeSourceFileUpload(uploadObject, index);
      }
    });
  }
  confirmBeforeRemoveAttachment(
    uploadObject: UploadObject<AttachmentInterface>,
    index: number
  ) {
    const dialogRef = this.dialog.open(ConfirmActionDialogComponent, {
      width: '400px',
      data: {
        message: `Are you sure you want to remove ${uploadObject.file.name}?`,
        buttonName: 'Remove',
      } as ConfirmActionDialog,
    });

    dialogRef.afterClosed().subscribe((userIsSure) => {
      if (userIsSure) {
        this.removeAttachmentUpload(uploadObject, index);
      }
    });
  }

  confirmBeforeSubmit(): void {
    const dialogRef = this.dialog.open(ConfirmActionDialogComponent, {
      width: '400px',
      data: {
        message: `Changes cannot be made after submitting.\n\nAre you sure you want to submit?`,
        buttonName: 'Submit',
      } as ConfirmActionDialog,
    });

    dialogRef.afterClosed().subscribe((userIsSure) => {
      if (userIsSure) {
        this.goToNextStep();
        this.submitUpload();
      }
    });
  }

  async submitUpload() {
    this.isSubmitError = false;
    this.isSubmitting = true;

    this.isCreatingUploadJob = true;
    let uploadJob: UploadJobInterface;
    try {
      uploadJob = await this.uploadJobService.create({
        site: this.selectedSite.id,
        uploaded_by: this.authService.loggedInUser.user_id,
        comment: this.commentFormControl.value || '',
      });
    } catch (error) {
      this.isSubmitError = true;
      this.isSubmitting = false;
      this.goToPreviousStep();
      this.isCreatingUploadJob = false;
      return;
    }
    this.currentUploadJob = uploadJob;
    this.isCreatingUploadJob = false;

    this.sourceFileUploadObjects.forEach((uploadObject) => {
      this.sourceFileUploadService.startUpload(uploadObject, {
        upload_job: uploadJob.id,
        company: this.selectedSite.company,
      });
    });

    this.attachmentUploadObjects.forEach((uploadObject) => {
      this.attachmentUploadService.startUpload(uploadObject, {
        upload_job: uploadJob.id,
        company: this.selectedSite.company,
      });
    });

    const interval = setInterval(() => {
      let isAllFilesFinished = true;
      let isFileError = false;

      this.sourceFileUploadObjects.forEach((uploadObject) => {
        if (!uploadObject.isFinished) isAllFilesFinished = false;
        if (uploadObject.isError()) isFileError = true;
      });

      this.attachmentUploadObjects.forEach((uploadObject) => {
        if (!uploadObject.isFinished) isAllFilesFinished = false;
        if (uploadObject.isError()) isFileError = true;
      });

      if (isFileError) {
        this.isSubmitError = true;
        this.isSubmitSuccess = false;
      } else {
        this.isSubmitError = false;
      }

      if (isAllFilesFinished) {
        clearInterval(interval);
        this.reportUploadJobFinished();
      }
    }, 200);
  }

  async reportUploadJobFinished() {
    if (!this.currentUploadJob) return;
    this.isSubmitError = false;
    this.uploadJobFinishedError = false;

    try {
      await this.uploadJobService.finishUploadJob(this.currentUploadJob.id);
    } catch (error) {
      console.log(error);
      this.uploadJobFinishedError = true;
      this.isSubmitError = true;
      return;
    }

    this.isSubmitError = false;
    this.isSubmitSuccess = true;
    this.isSubmitting = false;
  }

  fixUploadErrors() {
    if (this.uploadJobFinishedError) {
      this.reportUploadJobFinished();
      return;
    }

    this.sourceFileUploadObjects.forEach((uploadObject) => {
      if (uploadObject.isError()) this.fixSourceFileUploadError(uploadObject);
    });

    this.attachmentUploadObjects.forEach((uploadObject) => {
      if (uploadObject.isError()) this.fixAttachmentUploadError(uploadObject);
    });
  }

  isContent(): boolean {
    let isSourceFiles = this.sourceFileUploadObjects.length > 0;
    let isAttachmentFiles = this.attachmentUploadObjects.length > 0;
    let isComment =
      this.commentFormControl.value != '' &&
      this.commentFormControl.value != null;

    return isSourceFiles || isAttachmentFiles || isComment;
  }
}
