import {
  Component,
  Input,
  OnChanges,
  SimpleChange,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';

import {
  Analyst,
  VibrationReport,
} from '../backend-api/vibration-report/vibration-report';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-vibration-report-view',
  templateUrl: './vibration-report-view.component.html',
  styleUrls: ['./vibration-report-view.component.scss'],
  providers: [DatePipe],
})
export class VibrationReportViewComponent implements OnChanges {
  @Input() reportVerbose: VibrationReport;
  @Input() companyLogoURL: string;

  @ViewChild('machineIconContainer')
  machineIconContainerElement: ElementRef<HTMLElement>;

  // Fields derived from report verbose
  reportDate: Date;
  machineId: number;
  machineName: string;
  analysedBy: string;
  approvedBy: string;

  kmLogoURL = 'assets/img/logo/km.svg';
  statusColorClass: string;

  constructor(
    public dialog: MatDialog,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {}

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (
      changes['reportVerbose'] &&
      changes['reportVerbose'].previousValue !=
        changes['reportVerbose'].currentValue
    ) {
      this.updateFieldsFromReportVerbose();
    }
  }

  updateFieldsFromReportVerbose(): void {
    const rv = this.reportVerbose;

    // Fields derived from report verbose
    this.reportDate = new Date(rv.date_created);
    this.machineId = rv.machine;
    this.analysedBy = VibrationReportViewComponent.getNameFromAnalyst(
      rv.analysed_by
    );
    this.approvedBy = VibrationReportViewComponent.getNameFromAnalyst(
      rv.approved_by
    );
    this.statusColorClass =
      VibrationReportViewComponent.severityLevel2statusColorClass(
        rv.severity_level
      );

    this.updateMachineIcon(rv.machine_category);
  }

  doesReportHaveStatuses() {
    return (
      this.reportVerbose.machine_statuses.length > 0 ||
      this.reportVerbose.bearing_statuses.length > 0 ||
      this.reportVerbose.oil_statuses.length > 0 ||
      this.reportVerbose.electrical_statuses.length > 0
    );
  }

  getSiteNameWithMachineGroups() {
    return this.reportVerbose.machine_group
      ? this.reportVerbose.site_name +
          ' • ' +
          this.reportVerbose.machine_group.replace(/\\/g, ' • ')
      : this.reportVerbose.site_name;
  }

  async updateMachineIcon(machineType: string) {
    const machineIconURL =
      VibrationReportViewComponent.machineType2machineIconPath(machineType);
    const machineIconURLSafe =
      this.sanitizer.bypassSecurityTrustResourceUrl(machineIconURL);

    // Create SVG Element
    const machineIconSVGElement = await this.iconRegistry
      .getSvgIconFromUrl(machineIconURLSafe)
      .toPromise();

    // Add new element to #machine-icon-container
    this.machineIconContainerElement.nativeElement.innerHTML = ''; // Remove children
    this.machineIconContainerElement.nativeElement.appendChild(
      machineIconSVGElement
    );
  }

  openImage(imgUrl: string): void {
    // Don't open image in Skaiwatch
  }

  private static getNameFromAnalyst(analyst: Analyst): string {
    if (!analyst?.user?.first_name) return '–';
    else return `${analyst.user.first_name} ${analyst.user.last_name}`;
  }

  private static machineType2machineIconPath(machineType: string) {
    let registeredIcons = [
      'propeller',
      'modbus',
      'ER-fan',
      'fan',
      'crane',
      'engine',
      'generator',
      'HPU',
      'pump',
      'motor',
      'el_motor',
      'gear',
      'gearbox',
      'thruster',
    ];
    let svgFileName = registeredIcons.includes(machineType)
      ? machineType
      : 'default-machine';
    return `assets/img/icon/${svgFileName}.svg`;
  }

  private static severityLevel2statusColorClass(severityLevel: string): string {
    switch (severityLevel) {
      case 'Good':
        return 'status-good';
      case 'Acceptance Report':
        return 'status-good';
      case 'Warning':
        return 'status-warning';
      case 'Incomplete Data':
        return 'status-warning';
      case 'Alarm':
        return 'status-alarm';
      case '':
        return 'status-none';
      default:
        console.error(`Severity level "${severityLevel}" is unknown`);
        return null;
    }
  }
}
