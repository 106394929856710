import { Component } from '@angular/core';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { environment } from 'src/environments/environment';
import { getDomainFromURL } from 'src/app/ts-tools/regex';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public backendDomain = getDomainFromURL(environment.BACKEND_BASE_URL);
  public isProductionBackend = (this.backendDomain == 'backend.skaiwatch.no');

  public constructor(
    private titleService: Title,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.titleService.setTitle('skAIwatch');

    // Register SVG icons
    this.matIconRegistry.addSvgIcon(
      'thruster',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/img/icon/thruster.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'gear',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/img/icon/gearbox.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'gearbox',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/img/icon/gearbox.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'el_motor',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/img/icon/el_motor.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'motor',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/img/icon/gas_motor.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'pump',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/img/icon/pump.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'HPU',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/img/icon/pump.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'oil_report',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/img/icon/oil_report.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'CM_class',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/img/icon/CM_class.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'CM_performance',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/img/icon/CM_performance.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'generator',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/img/icon/generator.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'engine',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/img/icon/engine.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'crane',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/img/icon/crane.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'fan',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/img/icon/ER_fan.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'ER-fan',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/img/icon/ER_fan.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'modbus',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/img/icon/modbus.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'propeller',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/img/icon/thruster.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'unknown',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/img/icon/unknown.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'default-machine',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/img/icon/default-machine.svg'
      )
    );
  }
}
