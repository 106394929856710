<div class="container">
  <p class="display-4">
    {{site.name}}
  </p>

  <table class="table" style="color: inherit;">
    <thead>
      <tr>
        <th>Fleet group:</th>
        <th>{{site.site_group_detail ? site.site_group_detail.site_group_family_detail.name : '-'}}</th>
        <th>Fleet:</th>
        <th>{{site.site_group_detail ? site.site_group_detail.name : '-'}}</th>
      </tr>
    </thead>
  </table>

  <!-- <div class="container" echarts [options]="pie_chart_option"></div> -->

  <hr>

  <div class="container-fluid" style="max-width: inherit !important; overflow-x: scroll;">
    <table class="table" style="color: inherit;">
      <thead>
        <tr>
          <th *ngFor="let month of month_summary_data">{{month.date.getMonth()+1}}/{{month.date.getFullYear()}}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td *ngFor="let month of month_summary_data">
            <div>
              <mat-checkbox [checked]="month.measurements_received_on_time === month.total_measurements"
                [disabled]="true"></mat-checkbox>
              <p>{{month.measurements_received_on_time}}/{{month.total_measurements}}</p>
              <button (click)="display_month(month)">View</button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div mat-dialog-actions>
    <span class="spacer"></span><button mat-button (click)="close()">Close</button>
  </div>

</div>
