
// Compile here so its faster
// Inspiration: https://regex101.com/r/wN6cZ7/365
const domainExtractorRegex = new RegExp(/^(?:https?:)?(?:\/\/)?(?:[^@\n]+@)?([^\/]+)/i);


/**
 * Extracts and returns the domain name from an URL
 *
 * @param url The URL to extract the domain from
 * @returns The domain name
 *
 * @example
 * // returns '127.0.0.1:8000'
 * getDomainFromURL('http://127.0.0.1:8000/api/v1/')
 *
 * @example
 * // returns 'www.google.com'
 * getDomainFromURL('http://user:pass@www.google.com/?a=b#asdd')
 */
 export function getDomainFromURL(url: string): string {

  const matches = url.match(domainExtractorRegex);

  if (matches === null) {
    throw new Error(`No domain name found in URL '${url}'`);
  } else {
    return matches[1];
  }
}
