<div class="row">
  <div class="col-12 mt-4 mb-1 site-header" style="height: 100px;">
    <div class="title">
      <h2>Key performance indicators</h2>
    </div>
  </div>
</div>

<div class="kpi-container">
  <div class="info-container fst-row-height">
    <div class="site-overview">

      <div class="toolbar-container">

        <mat-form-field class="search-field" data-isntro="Search for a site here.">
          <mat-label>
            <p class="fa fa-search"></p>
            Search
          </mat-label>
          <input matInput (keyup)="onSearchBoxChange($event)" placeholder="E.g. vessel name or type">
        </mat-form-field>


        <div class="date-time-picker-container">

          <mat-form-field appearance="fill">
            <mat-label>Choose a from date</mat-label>
            <input matInput [max]="endDate" [matDatepicker]="picker1" [(ngModel)]=startDate (dateChange)="onDate()">
            <mat-datepicker-toggle matSuffix [for]="picker1" startView="multi-year"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>

          <div style="width: 20px;"></div>

          <mat-form-field appearance="fill">
            <mat-label>Choose a to date</mat-label>
            <input matInput [min]="startDate" [matDatepicker]="picker2" [(ngModel)]=endDate (dateChange)="onDate()">
            <mat-datepicker-toggle matSuffix [for]="picker2" startView="multi-year"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>

      </div>

      <div class="site-table">

        <div #KPI_TABLE_DIV style="height: calc(100% - 150px); overflow: auto;">
          <table mat-table mat-list-item [dataSource]="kpiTableDataSource" matSort matSortActive="ranking"
            matSortDirection="asc" style="width: 100%;" class="ship-table">

            <!-- Subscribe Column -->
            <ng-container matColumnDef="subscribed">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Subscribe </th>
              <td mat-cell *matCellDef="let row">
                <app-site-subscribe [siteSubscription]="row.siteSubscription" [siteId]="row.site.id">
                </app-site-subscribe>
              </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="siteName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
              <td mat-cell *matCellDef="let row">
                <span>
                  {{row.siteName}}
                </span>
              </td>
            </ng-container>
            <!-- Site group family Column -->
            <ng-container matColumnDef="siteGroup">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Fleet </th>
              <td mat-cell *matCellDef="let row">
                {{row.siteGroupFamily ? row.siteGroupFamily.name : 'N/A'
                }}<ng-container *ngIf="row.siteGroup">: {{ row.siteGroup.name }}
                </ng-container>
              </td>
            </ng-container>

            <!-- Country Column -->
            <ng-container matColumnDef="siteFlag">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Flag </th>
              <td mat-cell *matCellDef="let row">
                <span class="fi" [ngClass]="'fi-' + row.siteFlag.toLowerCase()"
                  title="{{row.siteFlag}}"></span>
              </td>
            </ng-container>

            <!-- Site type Column -->
            <ng-container matColumnDef="siteType">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Vessel type
              </th>
              <td mat-cell *matCellDef="let row">
                <span>
                  {{row.site.site_type || '–'}}
                </span>
              </td>
            </ng-container>

            <!-- Site No new Measurement Column -->
            <ng-container matColumnDef="nmStat">
              <th mat-header-cell *matHeaderCellDef mat-sort-header
                matTooltip="The ratio between total amount reports containing a 'not measured' out of all reports submitted in the selected period">
                NM ratio
              </th>
              <td mat-cell *matCellDef="let row">
                <span>
                  {{statToString(row.nmStat)}}
                </span>
              </td>
            </ng-container>

            <!-- Site Alarm over total Column -->
            <ng-container matColumnDef="alarmStat">
              <th mat-header-cell *matHeaderCellDef mat-sort-header
                matTooltip="The ratio between machines with alarms and the total number of machines in the selected period">
                Alarm-to-machine
                ratio
              </th>
              <td mat-cell *matCellDef="let row">
                <span>
                  {{statToString(row.alarmStat)}}
                </span>
              </td>
            </ng-container>

            <!-- Site rank Column -->
            <ng-container matColumnDef="ranking">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Ranking
              </th>
              <td mat-cell *matCellDef="let row">
                <span>
                  {{row.ranking === defaultRanking? '–': row.ranking}}
                </span>
              </td>
            </ng-container>

            <!-- Loading Column -->
            <ng-container matColumnDef="loading">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
              </th>
              <td mat-cell *matCellDef="let row">
                <div *ngIf="row.isLoadingKpiData">
                  <mat-progress-spinner mode="indeterminate" diameter="15"></mat-progress-spinner>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="kpiTableColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let site; columns: kpiTableColumns;">
            </tr>
          </table>
        </div>

        <div class="table-footer">
          <span></span>
          <mat-paginator [pageSize]="100" hidePageSize showFirstLastButtons></mat-paginator>
          <button mat-raised-button color="primary" (click)="exportAsExcel()">Export as Excel</button>
        </div>
      </div>

    </div>
  </div>
</div>


<ng-template #SiteTableLoading>
  <mat-progress-bar mode="buffer"></mat-progress-bar>
</ng-template>

<ng-template #loadingSpinner>
  <mat-progress-spinner mode="indeterminate" diameter="100"></mat-progress-spinner>
</ng-template>
