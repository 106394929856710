import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LRUDMixin, PaginatedListResponse } from '../api-mixins';
import { AttachmentInterface } from './attachment';
import { environment } from 'src/environments/environment';
import { BlobStorageService } from '../../_services/azure-storage/azure-storage.service';
import { AuthService } from '../../_services/auth.service';
import { FileUploader } from '../file-uploader';

@Injectable({
  providedIn: 'root',
})
export class AttachmentService extends LRUDMixin<AttachmentInterface> {
  protected modelBaseUrl: string;

  constructor(protected http: HttpClient, private authService: AuthService) {
    super(environment.BACKEND_BASE_URL + 'attachment/', http);
  }

  public list(
    filter?: { [id: string]: string | number | boolean | string[] | number[] },
    search?: string,
    ordering?: string,
    pageSize?: number,
    customUrl?: string
  ): Promise<PaginatedListResponse<AttachmentInterface>> {
    // Return only files that are not empty
    let _filter = { ...filter, is_file_empty: false };
    return super.list(_filter, search, ordering, pageSize, customUrl);
  }

  async create(
    file: File,
    createData?: Partial<AttachmentInterface>
  ): Promise<AttachmentInterface> {
    /**
     * Creates and instance in the database with write permission url to allocated blob on datalake.
     */
    let _createData: Partial<AttachmentInterface> = {
      name: file.name,
      size: file.size,
      uploaded_by: this.authService.loggedInUser.user_id,
      is_file_empty: true,
    };

    if (createData) _createData = { ..._createData, ...createData };

    return this.http
      .post<AttachmentInterface>(this.modelBaseUrl, _createData)
      .toPromise();
  }
}

@Injectable({
  providedIn: 'root',
})
export class AttachmentUploadService extends FileUploader<AttachmentInterface> {
  constructor(
    protected attachmentService: AttachmentService,
    protected blobStorageService: BlobStorageService
  ) {
    super(attachmentService, blobStorageService);
  }
}
