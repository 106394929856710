import { MarineTrafficPS01 } from '../marine-traffic-ps01/marine-traffic-ps01';
import { Machine, MachineInterface } from '../machine/machine';
import { SiteGroup } from '../site-group/site-group';
import { BehaviorSubject } from 'rxjs';
import { OilReport } from '../oil-report/oil-report';
import { VibrationReport } from '../vibration-report/vibration-report';
import { ISiteSubscription } from '../site-subscription/site-subscription';

export interface SiteInterface {
  id: number;
  name: string;
  responsible_user?: number | null;
  site_type?: string;
  year_built?: number | null;
  company?: number;
  company_name?: string;
  country?: string;
  online?: boolean | null;
  tracking_enabled?: boolean;
  location_identifier?: string;
  date_created?: string;
  date_modified?: string;
  SO_number?: string;
  mmsi?: number | null;
  site_group?: number | null;
  marine_traffic_info?: MarineTrafficPS01[];
  img_url?: string | null;
  site_group_detail?: SiteGroup;
  graphic?: number;
  graphic_pos_X?: number;
  graphic_pos_Y?: number;
}
export class Site implements SiteInterface {
  name: string;
  responsible_user: number;
  site_type: string;
  year_built: number;
  company: number;
  company_name: string;
  country: string;
  online: boolean;
  tracking_enabled: boolean;
  location_identifier: string;
  date_created: string;
  date_modified: string;
  SO_number: string;
  mmsi: number;
  site_group: number;
  id: number;
  marine_traffic_info: MarineTrafficPS01[];
  img_url: string;
  site_group_detail: SiteGroup;
  graphic: number;
  graphic_pos_X: number;
  graphic_pos_Y: number;
  nGoodVibReports: number = null;
  nWarningVibReports: number = null;
  nAlarmVibReports: number = null;
  siteSubscription: ISiteSubscription = null;
  latestReportDate: Date = null;

  constructor(site: SiteInterface) {
    this.name = site.name;
    this.responsible_user = site.responsible_user;
    this.site_type = site.site_type;
    this.year_built = site.year_built;
    this.company = site.company;
    this.company_name = site.company_name;
    this.country = site.country;
    this.online = site.online;
    this.tracking_enabled = site.tracking_enabled;
    this.location_identifier = site.location_identifier;
    this.date_created = site.date_created;
    this.date_modified = site.date_modified;
    this.SO_number = site.SO_number;
    this.mmsi = site.mmsi;
    this.site_group = site.site_group;
    this.id = site.id;
    this.marine_traffic_info = site.marine_traffic_info;
    this.img_url = site.img_url;
    this.site_group_detail = site.site_group_detail;
    this.graphic = site.graphic;
    this.graphic_pos_X = site.graphic_pos_X;
    this.graphic_pos_Y = site.graphic_pos_Y;
  }
}
