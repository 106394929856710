import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  FormGroupDirective,
} from '@angular/forms';

import { AuthService } from '../_services/auth.service';

import { User } from 'src/app/backend-api/user/user';
import { UserService } from 'src/app/backend-api/user/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  showSidenavText: boolean;
  subscriptions: Subscription;
  updateUserForm: UntypedFormGroup;
  changePasswordForm: UntypedFormGroup;
  passwordLength = 8;

  hidePassword = true;
  isLoading = false;

  changePasswordSuccess = false;
  changePasswordErrors: string[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.updateUserForm = this.fb.group({
      givenName: this.loggedInUser.first_name,
      surname: this.loggedInUser.last_name,
    });

    this.changePasswordForm = this.fb.group({
      currentPassword: ['', [Validators.required, Validators.minLength(1)]],
      newPassword: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^(?:(?:(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]))|(?:(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@$%^&(){}[]:;<>,.?/~_+-=|]))|(?:(?=.*[0-9])(?=.*[A-Z])(?=.*[*.!@$%^&(){}[]:;<>,.?/~_+-=|]))|(?:(?=.*[0-9])(?=.*[a-z])(?=.*[*.!@$%^&(){}[]:;<>,.?/~_+-=|]))).{8,}$'
          ),
        ],
      ],
      repeatPassword: ['', [Validators.required]],
    });
  }

  // Getters

  get loggedInUser(): User {
    return this.authService.loggedInUser;
  }

  get newGivenName(): string {
    return this.updateUserForm.get('givenName').value || '';
  }

  get newSurName(): string {
    return this.updateUserForm.get('surname').value || '';
  }

  get currentPassword(): string {
    return this.changePasswordForm.get('currentPassword').value;
  }

  get newPassword(): string {
    return this.changePasswordForm.get('newPassword').value;
  }

  get newPasswordRepeat(): string {
    return this.changePasswordForm.get('repeatPassword').value;
  }

  // Setters
  set givenName(givenName: string) {
    this.updateUserForm.get('givenName').setValue(givenName);
  }

  set surname(surname: string) {
    this.updateUserForm.get('surname').setValue(surname);
  }

  /**
   * Function to change global password of
   * current user with values in `changePasswordForm`.
   */
  async changePasswordButtonClick(formDirective: FormGroupDirective) {
    try {
      await this.userService.changePassword(
        this.currentPassword,
        this.newPassword,
        this.newPasswordRepeat
      );
      this.changePasswordSuccess = true;
      this.changePasswordForm.reset();
      this.changePasswordForm.clearValidators();
      this.changePasswordErrors = [];
      formDirective.resetForm();
    } catch (e) {
      if (e.status == 400 && 'error' in e && 'errors' in e.error)
        this.changePasswordErrors = e.error.errors;
      else
        this.changePasswordErrors = [
          `Unknown error occurred. Status code ${e.status}.`,
        ];

      this.changePasswordSuccess = false;
    }
  }

  async updateUserButtonClick(formDirective: FormGroupDirective) {
    let newUserInfo: Partial<User> = {};

    if (
      this.newGivenName &&
      this.newGivenName.length > 0 &&
      this.newGivenName !== this.loggedInUser.first_name
    ) {
      newUserInfo.first_name = this.newGivenName;
    }
    if (
      this.newSurName &&
      this.newSurName.length > 0 &&
      this.newSurName !== this.loggedInUser.last_name
    ) {
      newUserInfo.last_name = this.newSurName;
    }

    if (Object.keys(newUserInfo).length === 0) {
      this.resetUserProfileForm(formDirective);
      return;
    }

    try {
      this.isLoading = true;
      await this.userService.update(this.loggedInUser.user_id, newUserInfo);
      // Refresh token to get new token with updated information.
      await this.authService.refreshToken();
      this.resetUserProfileForm(formDirective);
      this.isLoading = false;
    } catch (error) {
      console.error('Unable to update user.');
    }
  }

  resetUserProfileForm(formDirective: FormGroupDirective) {
    this.updateUserForm.reset();
    this.updateUserForm.clearValidators();
    formDirective.resetForm();

    this.givenName = this.loggedInUser.first_name;
    this.surname = this.loggedInUser.last_name;
  }

  // Functions for password sanitizing

  /**
   * Returns true if `word` contains an lowercase character
   * @param word word to be checked
   */
  containsLowerCase(word: string): boolean {
    return /(?=.*[a-z])/.test(word);
  }

  /**
   * Returns true if `word` contains an uppercase character
   * @param word word to be checked
   */
  containsUpperCase(word: string): boolean {
    return /(?=.*[A-Z])/.test(word);
  }

  /**
   * return true if `word` contains a number.
   * @param word word to be checked
   */
  containsNumber(word: string): boolean {
    return /(?=.*[0-9])/.test(word);
  }

  /**
   * Used to check if `word` is longer than `n`
   * Made for password-strength test
   * @param word Word to check
   * @param n minimum length of `word`
   */
  isLongerThanN(word: string, n: number): boolean {
    return word ? word.length >= n : false;
  }

  /**
   * Password Strength score
   *
   * Function to calculate a password strength score from 0 to 4.
   * Used in html/css for password strength bar
   *
   * @param word the word used to check the strength
   * @param n Min length of password. Used to check `isLongerThanN` function when calculating the score
   */
  passwordStrength(word: string, n: number): number {
    let s = 0;
    s += Number(this.containsLowerCase(word));
    s += Number(this.containsUpperCase(word));
    s += Number(this.containsNumber(word));
    s += Number(this.isLongerThanN(word, n));
    return s;
  }

  /**
   * Return `defaultName` if `name` is an empty string.
   * @param name input name to be displayed
   * @param defaultName name displayed if `name` is an empty string
   */
  checkIfNameElseReturnDefault(name: string, defaultName: string): string {
    if (name.length === 0) {
      return defaultName;
    } else {
      return name;
    }
  }
}
