<h2 *ngIf="data.title" [innerHTML]="data.title"></h2>
<p [innerHTML]="data.message" ngPreserveWhitespaces></p>

<div class="buttons">
  <button *ngIf="data.secondaryAction" mat-button color="primary" (click)="secondaryAction()"
    [innerHTML]="data.secondaryButtonName"></button>
  <button class="new-group" mat-button color="primary" (click)="cancel()">Cancel</button>
  <button mat-flat-button color="primary" (click)="primaryAction()" cdkFocusInitial
    [innerHTML]="data.buttonName"></button>
</div>
