<app-vibration-report-view *ngIf="reportVerbose" [reportVerbose]="reportVerbose">
</app-vibration-report-view>

<div class="loading-spinner">
  <mat-spinner *ngIf="!reportVerbose" diameter="100"></mat-spinner>
</div>

<div class="button-container">
  <button *ngIf="!loadingPdf" mat-raised-button color="primary" (click)="savePdf()" tabindex="-1">PDF</button>
  <mat-spinner *ngIf="loadingPdf" diameter="25"></mat-spinner>
  <button mat-raised-button color="primary" (click)="close()" tabindex="-1">Close</button>
</div>



<ng-template #loadingSpinner>
  <div>
    <mat-spinner diameter="25"></mat-spinner>
  </div>
</ng-template>
