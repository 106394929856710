import { Component, Input, OnInit } from '@angular/core';
import { MachineService } from '../../backend-api/machine/machine.service';
import { SiteInterface } from '../../backend-api/site/site';
import { VibrationReportInterface } from '../../backend-api/vibration-report/vibration-report';
import { VibrationReportService } from '../../backend-api/vibration-report/vibration-report.service';

@Component({
  selector: 'app-machine-group-status-summary',
  templateUrl: './machine-group-status-summary.component.html',
  styleUrls: ['./machine-group-status-summary.component.scss'],
})
export class MachineGroupStatusSummaryComponent implements OnInit {
  @Input() site: SiteInterface;

  groupStatus: {
    [groupName: string]: {
      good: number;
      warning: number;
      alarm: number;
    };
  } = {};

  machineGroups = [];

  constructor(
    private vibrationReportService: VibrationReportService,
    private machineService: MachineService
  ) {}

  async ngOnInit(): Promise<void> {
    let latestReports =
      await this.vibrationReportService.getSitePublishedReportStatus(
        this.site.id
      );

    for (let report of latestReports) {
      let mainGroup = this.findMainGroupName(report.machine_group);
      this.registerMachineGroup(mainGroup);
      this.incrementGroupStatusCounter(mainGroup, report.latest_report);
    }
    this.machineGroups.sort();

    // Make no group the last group always
    if (this.machineGroups.includes('')) {
      // First filter away ''
      let filteredValues = this.machineGroups.filter((value, index, arr) => {
        return value !== '';
      });
      // push it to the end
      filteredValues.push('');
      this.machineGroups = filteredValues;
    }
    //this.findAllMachineGroups();  // add empty group for show
  }

  async findAllMachineGroups() {
    let allMachinesOnSite = (
      await this.machineService.list({ site: this.site.id, page_size: 1000 })
    ).results;
    allMachinesOnSite.forEach((machine) => {
      let mainGroup = this.findMainGroupName(machine.group);
      this.registerMachineGroup(mainGroup);
    });
  }

  findMainGroupName(group: string): string {
    let groupName = '';
    if (group) groupName = group.split('\\')[0];
    return groupName;
  }

  registerMachineGroup(machineGroup: string) {
    if (!(machineGroup in this.groupStatus))
      this.groupStatus[machineGroup] = {
        good: 0,
        warning: 0,
        alarm: 0,
      };
    if (!this.machineGroups.includes(machineGroup))
      this.machineGroups.push(machineGroup);
  }

  incrementGroupStatusCounter(
    group: string,
    report: VibrationReportInterface | null
  ) {
    if (!report) return;
    let severityLevel = report.severity_level.toLowerCase();
    if (['good', 'acceptance report'].includes(severityLevel))
      this.groupStatus[group].good++;
    if (['warning', 'incomplete data'].includes(severityLevel))
      this.groupStatus[group].warning++;
    if (severityLevel === 'alarm') this.groupStatus[group].alarm++;
  }

  formatGroupName(group: string): string {
    if (group) return group;
    if (this.machineGroups.length > 1) {
      return 'Other machines';
    }
  }

  isGroups() {
    let numberOfGroups = Object.keys(this.groupStatus).length;
    if (numberOfGroups > 1) return true;
    if (numberOfGroups === 0) return false;

    let isMachinesWithNoGroup = '' in this.groupStatus;
    if (numberOfGroups === 1 && isMachinesWithNoGroup) return false;

    return true;
  }
}
