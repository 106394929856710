import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ListMixin, PaginatedListResponse } from '../api-mixins';
import { VibrationReportVerboseInterface } from './vibration-report';
import { LRCache } from '../api-cache';

@Injectable({
  providedIn: 'root',
})
export class VibrationReportVerboseService extends ListMixin<VibrationReportVerboseInterface> {
  protected lrCache = new LRCache(5 * 60 * 1000);
  constructor(protected http: HttpClient) {
    super(environment.BACKEND_BASE_URL + 'report/verbose/', http);
  }

  public retrieve(id: number): Promise<VibrationReportVerboseInterface> {
    return this.http
      .get<VibrationReportVerboseInterface>(
        `${environment.BACKEND_BASE_URL}report/${id}/verbose/`
      )
      .toPromise();
  }
  list(
    filter?: { [id: string]: string | number | boolean | string[] | number[] },
    search?: string,
    ordering?: string,
    pageSize?: number,
    customUrl?: string
  ): Promise<PaginatedListResponse<VibrationReportVerboseInterface>> {
    if (filter) {
      filter = { ...filter, published: true };
    } else {
      filter = { published: true };
    }
    return super.list(filter, search, ordering, pageSize, customUrl);
  }

  clearCache(): void {
    this.lrCache.clearCache();
  }
}
