import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CreateMixin } from '../api-mixins';
import { Ticket } from './ticket';

@Injectable({
  providedIn: 'root'
})
export class TicketService extends CreateMixin<Ticket> {
  protected modelBaseUrl: string;

  constructor(protected http: HttpClient) {
    super(environment.BACKEND_BASE_URL + 'tickets/', http);
  }

  public createWithAttachments(formData){
    return this.http
      .post(`${this.modelBaseUrl}`, formData)
      .toPromise();
  }
}
