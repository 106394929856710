<div class="main-container mat-app-background">
  <div class="col-md-12 no-gutters p-0">
    <!---------------------------------------------------------------------->
    <!--                    Header                    									  -->
    <!---------------------------------------------------------------------->
    <div class="row">
      <div class="col-12 mt-4 mb-1 site-header" style="height: 100px;">
        <div class="title">
          <h2>We are ready to help around the clock</h2>
        </div>
      </div>
    </div>


    <div class="row p-3 mt-6">
      <div class="w-100 p-5 info-container fst-row-height">
        <div class="row">
          <div class="col-md-6"
           >

            <h5><span class="fa fa-envelope"></span> Leave us a message</h5>

            <div class="row" style="height: 1rem;"></div>

            <form [formGroup]="newSupportTicketForm" #formDirective="ngForm"
              (ngSubmit)="submitNewTicket(formDirective)">

              <mat-form-field appearance="fill">
                <mat-label>Subject</mat-label>
                <input matInput formControlName="subject">
              </mat-form-field>

              <mat-form-field appearance="fill">
                <mat-label>How can we help?</mat-label>
                <textarea matInput formControlName="description" style="height: 8rem;"></textarea>
              </mat-form-field>


              <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop
                (onFileDropped)="uploadFile($event)"
               >
                <input hidden type="file" multiple #fileInput (change)="uploadFile($event.target.files)">

                <div class="elements-center">
                  <mat-icon [inline]="false">attach_file</mat-icon> Add up to 5 files
                </div>

              </div>
              <div class="files-list" *ngFor="let filename of Object.keys(attachments);let i= index">

                <p> {{ filename }}</p>
                <button class="delete-file" (click)="deleteAttachment(filename)">
                  <mat-icon [inline]="true">delete_outline</mat-icon>
                </button>

              </div>

              <button mat-raised-button color="primary">SUBMIT</button>

            </form>

          </div>

          <div class="col-md-6">
            <h5><span class="fa fa-map-marker"></span> Address</h5>
            <div class="row" style="height: 1rem;"></div>

            <div id="map" class="map" style="width: 100%; height: 15rem; background-color: darkgray;"
             ></div>

            <div class="row" style="height: 1rem;"></div>

            <p>
              Storebotn 90 <br>
              5309 Kleppestø <br>
              Norway <br>
            </p>
          </div>

        </div>

        <div class="row" style="height: 2rem;"></div>

        <div class="row mb-6">

          <div class="col-md-4">

            <h5>24/7 Support line</h5>
            <p>
              <span class="fa fa-phone"></span>&nbsp;(+47)&nbsp;932&nbsp;50&nbsp;800 <br>
              Reserved for emergencies.
            </p>
          </div>
          
          <div class="col-md-4">
            <h5>Switchboard</h5>
            <p>
              <span class="fa fa-phone"></span>&nbsp;(+47)&nbsp;559&nbsp;43&nbsp;400 <br>
            </p>
          </div>

        </div>

        <div class="row" style="height: 2rem;"></div>
      </div>
    </div>
  </div>
</div>
