import { MachineGraphic } from '../machine-graphic/machine-graphic';
import { VibrationReportInterface } from '../vibration-report/vibration-report';

export interface MachineInterface {
  name: string;
  id: number;
  group?: string;
  SFI_number?: string;
  site?: number;
  site_location?: string;
  date_created?: string;
  date_modified?: string;
  manufacturer?: string;
  type?: string;
  speed_rpm?: number | null;
  power_kw?: number | null;
  description?: string;
  note?: string;
  adjustable_pitch?: boolean | null;
  category?: string;

  main?: boolean;
  is_on_contract?: boolean;
  graphic?: number | MachineGraphic;
  graphic_pos_X?: number;
  graphic_pos_Y?: number;
  graphic_scale?: number;
  number_of_unresolved_public_machine_comment?: number;
  latest_published_report?: number | VibrationReportInterface;
}
export class Machine implements MachineInterface {
  name: string;
  SFI_number: string;
  site: number;
  site_location: string;
  date_created: string;
  date_modified: string;
  manufacturer: string;
  type: string;
  speed_rpm: number;
  power_kw: number;
  description: string;
  note: string;
  adjustable_pitch: boolean;
  id: number;
  main: boolean;
  is_on_contract: boolean;
  group: string;
  graphic: number | MachineGraphic;
  graphic_pos_X: number;
  graphic_pos_Y: number;
  graphic_scale: number;
  category: string;
  number_of_unresolved_public_machine_comment: number;
  latest_published_report: number | VibrationReportInterface;

  constructor(machine: MachineInterface) {
    this.name = machine.name;
    this.SFI_number = machine.SFI_number;
    this.site = machine.site;
    this.site_location = machine.site_location;
    this.date_created = machine.date_created;
    this.date_modified = machine.date_modified;
    this.manufacturer = machine.manufacturer;
    this.type = machine.type;
    this.speed_rpm = machine.speed_rpm;
    this.power_kw = machine.power_kw;
    this.description = machine.description;
    this.note = machine.note;
    this.adjustable_pitch = machine.adjustable_pitch;
    this.id = machine.id;
    this.main = machine.main;
    this.is_on_contract = machine.is_on_contract;
    this.graphic = machine.graphic;
    this.graphic_pos_X = machine.graphic_pos_X;
    this.graphic_pos_Y = machine.graphic_pos_Y;
    this.graphic_scale = machine.graphic_scale;
    this.group = machine.group;
    this.category = machine.category;
    this.number_of_unresolved_public_machine_comment =
      machine.number_of_unresolved_public_machine_comment;
    this.latest_published_report = machine.latest_published_report;
  }
}
