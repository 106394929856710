import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// import from apps to be injected in the main root-app

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTreeModule } from '@angular/material/tree';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AppHttpInterceptor } from './_configurations/AppHttpInterceptor';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { A11yModule } from '@angular/cdk/a11y';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MsalModule } from '@azure/msal-angular';
import {
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
} from '@azure/msal-browser';
import { environment } from '../environments/environment';
//COMPONENTS
import { SiteDashboardComponent } from './site-dashboard/site-dashboard.component';
import { AppComponent } from './app.component';
import { LoginLayoutComponent } from './_layouts/login-layout.component';
import { HomeLayoutComponent } from './_layouts/home-layout.component';
import { LoginComponent } from './login/login.component';
import { NavbarComponent } from './navbar/navbar.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ReportComponent } from './report/report.component';
import { ProfileComponent } from './profile/profile.component';
import { SiteManagementComponent } from './site-management/site-management.component';
import { AccountComponent } from './account/account.component';
import { ArchiveComponent } from './archive/archive.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SiteManagementDetailComponent } from './site-management/modal/site-management-detail.component';
import { MachineStructureTreeComponent } from './machine-structure-tree/machine-structure-tree.component';
import { MatNativeDateModule } from '@angular/material/core';
import { VibrationReportTableComponent } from './vibration-report-table/vibration-report-table.component';
import { OilReportTableComponent } from './oil-report-table/oil-report-table.component';
import { SiteSubscribeComponent } from './site-subscribe/site-subscribe.component';
import { SiteArchiveComponent } from './site-archive/site-archive.component';
import { SiteMapComponent } from './site-map/site-map.component';
import { SiteMachineListComponent } from './site-dashboard/site-machine-list/site-machine-list.component';
import { VibrationReportDialogComponent } from './dialogs/vibration-report-dialog/vibration-report-dialog.component';
import { VibrationReportViewComponent } from './vibration-report-view/vibration-report-view.component';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { SupportComponent } from './support/support.component';
import { OilReportViewComponent } from './oil-report-view/oil-report-view.component';
import { DragDropDirective } from './directives/drag-drop/drag-drop.directive';
import { MachineReportOverviewComponent } from './site-dashboard/machine-report-overview/machine-report-overview.component';
import { ImageDialogComponent } from './widgets/dialogs/image-dialog/image-dialog.component';
import { ConfirmActionDialogComponent } from './widgets/dialogs/confirm-action-dialog/confirm-action-dialog.component';
import { OilReportDialogComponent } from './dialogs/oil-report-dialog/oil-report-dialog.component';
import { SiteReportListComponent } from './site-report-list/site-report-list.component';
import { StatusTextComponent } from './status-text/status-text.component';
import { ReportArchiveComponent } from './archive/report-archive/report-archive.component';
import { UploadSourceFilesComponent } from './upload-source-files/upload-source-files.component';
import { VibrationReportArchiveTableComponent } from './archive/report-archive/vibration-report-archive-table/vibration-report-archive-table.component';
import { OilReportArchiveTableComponent } from './archive/report-archive/oil-report-archive-table/oil-report-archive-table.component';
import { SourceFileArchiveTableComponent } from './archive/report-archive/source-file-archive-table/source-file-archive-table.component';
import { MachineGroupStatusSummaryComponent } from './site-dashboard/machine-group-status-summary/machine-group-status-summary.component';
import { KpiComponent } from './kpi/kpi.component';
import { MarkdownModule } from 'ngx-markdown';
import { SiteSubscribeToolbarComponent } from './site-subscribe-toolbar/site-subscribe-toolbar.component';
import { MachineCommentsComponent } from './site-dashboard/machine-comments/machine-comments.component';
import { CreateThreadDialogComponent } from './widgets/dialogs/create-thread-dialog/create-thread-dialog.component';

@NgModule({
    declarations: [
        AppComponent,
        LoginLayoutComponent,
        HomeLayoutComponent,
        LoginComponent,
        NavbarComponent,
        DashboardComponent,
        SiteDashboardComponent,
        ReportComponent,
        ProfileComponent,
        SiteManagementComponent,
        AccountComponent,
        ArchiveComponent,
        PageNotFoundComponent,
        VibrationReportDialogComponent,
        SiteManagementDetailComponent,
        MachineStructureTreeComponent,
        VibrationReportTableComponent,
        OilReportTableComponent,
        SiteSubscribeComponent,
        SiteArchiveComponent,
        SiteArchiveComponent,
        SiteMapComponent,
        SiteMachineListComponent,
        VibrationReportViewComponent,
        SupportComponent,
        OilReportViewComponent,
        DragDropDirective,
        MachineReportOverviewComponent,
        ImageDialogComponent,
        ConfirmActionDialogComponent,
        OilReportDialogComponent,
        SiteReportListComponent,
        StatusTextComponent,
        ReportArchiveComponent,
        UploadSourceFilesComponent,
        VibrationReportArchiveTableComponent,
        OilReportArchiveTableComponent,
        SourceFileArchiveTableComponent,
        MachineGroupStatusSummaryComponent,
        KpiComponent,
        SiteSubscribeToolbarComponent,
        MachineCommentsComponent,
        CreateThreadDialogComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        MatToolbarModule,
        MatIconModule,
        MatGridListModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatTreeModule,
        MatSidenavModule,
        MatMenuModule,
        MatListModule,
        A11yModule,
        CdkStepperModule,
        CdkTableModule,
        CdkTreeModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatStepperModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatPaginatorModule,
        BrowserModule,
        MatSortModule,
        MatTreeModule,
        PortalModule,
        ScrollingModule,
        ReactiveFormsModule,
        MatButtonModule,
        MarkdownModule.forRoot(),
        MsalModule.forRoot(new PublicClientApplication({
            // MSAL Configuration
            auth: {
                clientId: environment.MSAL_CLIENT_ID,
                authority: environment.MSAL_AUTHORITY,
                redirectUri: environment.MSAL_REDIRECT_URL,
            },
            cache: {
                cacheLocation: BrowserCacheLocation.LocalStorage,
                storeAuthStateInCookie: true, // set to true for IE 11
            },
            system: {
                loggerOptions: {
                    loggerCallback: () => { },
                    piiLoggingEnabled: false,
                },
            },
        }), {
            interactionType: InteractionType.Redirect,
            authRequest: {
                scopes: ['openid', 'profile', 'User.Read'],
            },
        }, {
            interactionType: InteractionType.Redirect,
            protectedResourceMap: null,
        }),
    ],
    exports: [NavbarComponent],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AppHttpInterceptor,
            multi: true,
        },
        { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
