import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Site } from '../backend-api/site/site';

export interface SiteLoading {
  vibrationReports: boolean;
  oilReports: boolean;
  machines: boolean;
  site: boolean;
  measurements: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class SiteDashboardService {
  loading: SiteLoading = {
    site: true,
    machines: true,
    oilReports: true,
    vibrationReports: true,
    measurements: false,
  };
  private site: BehaviorSubject<Site> = new BehaviorSubject<Site>(null);

  get site$(): BehaviorSubject<Site> {
    return this.site;
  }

  set site$(siteSubject: BehaviorSubject<Site>) {
    this.site = siteSubject;
  }
}
