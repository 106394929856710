import { Component, Input, OnInit } from '@angular/core';
import { ISiteSubscription } from '../backend-api/site-subscription/site-subscription';
import { SiteSubscriptionService } from '../backend-api/site-subscription/site-subscription.service';
import { SiteService } from '../backend-api/site/site.service';
import { AuthService } from '../_services/auth.service';

@Component({
  selector: 'app-site-subscribe',
  templateUrl: './site-subscribe.component.html',
  styleUrls: ['./site-subscribe.component.scss'],
})
export class SiteSubscribeComponent implements OnInit {
  @Input() siteId: number;
  @Input() siteSubscription: ISiteSubscription | null = null;

  isLoading: boolean = false;

  constructor(
    private siteSubscriptionService: SiteSubscriptionService,
    private authService: AuthService,
    private siteService: SiteService
  ) {}

  async ngOnInit(): Promise<void> {
    //if (this.siteId && this.siteSubscription === null)
    //  this.siteSubscription = await this.getsiteSubscribed();
  }

  private async getsiteSubscribed(): Promise<ISiteSubscription> {
    try {
      this.isLoading = true;
      const subscriptions = (
        await this.siteSubscriptionService.list({
          user: this.authService.loggedInUser.user_id,
          site: this.siteId,
        })
      ).results;
      return subscriptions[0];
    } finally {
      this.isLoading = false;
    }
  }

  async toggleSubscribe(event: Event) {
    event.stopPropagation();
    try {
      if (this.siteSubscription) {
        await this.siteService.unsubscribe(this.siteId);
        this.siteSubscription = null;
      } else {
        await this.siteService.subscribe(this.siteId);
        this.siteSubscription = await this.getsiteSubscribed();
      }
    } finally {
    }
  }
}
