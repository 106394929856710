import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { SiteInterface } from '../../../backend-api/site/site';
import { FilterParameters } from '../report-archive.component';
import { SourceFileInterface } from '../../../backend-api/source-file/source-file';
import { SourceFileService } from '../../../backend-api/source-file/source-file.service';
import { user2String } from '../../../backend-api/user/user';
import { formatBytes } from '../../../ts-tools/files';
import { MatTableDataSource } from '@angular/material/table';
import { ApiListController } from '../../../backend-api/api-list-controller';

interface SourceFileTableRow {
  id: number;
  name: string;
  size: string;
  uploadedBy: string;
  uploadDate: string;
  isDownloading: boolean;
  siteName: string;
  siteId: number;
  sourceFile: SourceFileInterface;
}

@Component({
  selector: 'app-source-file-archive-table',
  templateUrl: './source-file-archive-table.component.html',
  styleUrls: ['./source-file-archive-table.component.scss'],
})
export class SourceFileArchiveTableComponent implements OnInit {
  sourceFileTableDataSource = new MatTableDataSource<SourceFileTableRow>([]);
  searchForm: UntypedFormGroup;
  paginationSize = 50;
  sourceFileApiListController: ApiListController<SourceFileInterface, any>;

  @Input() filterParameters: FilterParameters;
  @Input() selectedSite: SiteInterface;
  @Output() internalSiteToggle = new EventEmitter<SiteInterface>();

  @ViewChild('paginator') paginator: MatPaginator;

  destroy$ = new Subject<void>();
  displayedColumns: string[] = [
    'uploadedBy',
    'name',
    'siteName',
    'uploadDate',
    'size',
    'buttons',
  ];

  private currentApiFilter: any = {
    extend: ['user', 'site'],
  };
  private currentApiSearch = '';

  constructor(
    private fb: UntypedFormBuilder,
    private sourceFileService: SourceFileService
  ) {}

  initDone = false;
  async ngOnInit(): Promise<void> {
    this.initForm();
    this.initApiListController();
    this.applyApiSearchOnChanges();

    await this.loadInitialData();
    this.initDone = true;
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  ngOnChanges(changes) {
    if (this.initDone) {
      this.apiFilterOnFormChanges(this.filterParameters);
    }
  }

  filterParametersToApiParameters(filterParameters: FilterParameters) {
    let apiParameters = {};

    if (filterParameters.siteGroupFamily)
      apiParameters['site__site_group__site_group_family'] =
        filterParameters.siteGroupFamily;
    if (filterParameters.siteGroup)
      apiParameters['site__site_group'] = filterParameters.siteGroup;
    if (filterParameters.site) apiParameters['site'] = filterParameters.site;
    // if (filterParameters.machine)
    //   apiParameters['machine'] = filterParameters.machine;

    apiParameters['extend'] = ['user', 'site'];

    return apiParameters;
  }

  /**
   * Loads site group families, and loads first page for sites and machines.
   *  Select machine or site if url parameters ('machine' or 'site') are provided.
   */
  private async loadInitialData(): Promise<void> {
    this.sourceFileApiListController.isLoading = true;
    this.sourceFileApiListController.applyApiFilter({
      extend: ['user', 'site'],
    });
    this.sourceFileApiListController.changePage(1);
  }

  private initForm() {
    this.searchForm = this.fb.group({
      search: '',
    });
  }

  private initApiListController() {
    this.sourceFileApiListController = new ApiListController<
      SourceFileInterface,
      any
    >(this.paginationSize, this.sourceFileService);

    this.sourceFileApiListController.responseData
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.sourceFileTableDataSource = new MatTableDataSource(
          data.map((r) => this.sourceFile2SourceFileTableRow(r))
        );
      });
  }

  private sourceFile2SourceFileTableRow(
    sourceFile: SourceFileInterface
  ): SourceFileTableRow {
    return {
      id: sourceFile.id,
      name: sourceFile.name,
      size: sourceFile.size ? formatBytes(sourceFile.size) : null,
      uploadedBy: user2String(sourceFile.user),
      uploadDate: sourceFile.upload_date,
      isDownloading: false,
      siteName: (sourceFile.site as SiteInterface)?.name,
      siteId: (sourceFile.site as SiteInterface)?.id,
      sourceFile: sourceFile,
    };
  }

  private applyApiSearchOnChanges() {
    this.searchForm.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(500))
      .subscribe((changes) => {
        let search_encoded = encodeURIComponent(changes.search);
        if (search_encoded === this.currentApiSearch) return; // skip if changes not from report search bar.

        this.currentApiSearch = search_encoded;

        let nextFilter = { ...this.currentApiFilter };
        if (search_encoded.trim())
          nextFilter = { ...nextFilter, search: search_encoded.trim() };

        this.sourceFileApiListController.applyApiFilter(nextFilter);
        this.paginator.firstPage();
      });
  }

  private apiFilterOnFormChanges(filterParameters: FilterParameters) {
    this.currentApiFilter =
      this.filterParametersToApiParameters(filterParameters);
    let nextApiFilter = { ...this.currentApiFilter };

    if (this.currentApiSearch.trim()) {
      nextApiFilter = {
        ...nextApiFilter,
        search: this.currentApiSearch.trim(),
      };
    }

    this.sourceFileApiListController.applyApiFilter({
      ...nextApiFilter,
    });
    this.paginator.firstPage();
  }

  downloadSourceFile(tableRow: SourceFileTableRow) {
    window.location.assign(tableRow.sourceFile.file);
  }

  /**
   * When clicking on a site name in the report table. Converts ReportTableRow to a site object.
   * @param tableRow
   */
  selectSiteFromTable(tableRow: SourceFileTableRow) {
    this.internalSiteToggle.next({
      name: tableRow.siteName,
      id: tableRow.siteId,
    });
  }

  deselectSite() {
    this.internalSiteToggle.emit(null);
  }
}
