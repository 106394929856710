/**
 * This service is built around @azure/storage-blob and is created based of this article:
 * https://javascript.plainenglish.io/upload-to-azure-blob-storage-with-angular-8-2ed80dfc6672
 *
 * Original github repository:
 * https://github.com/stottle-uk/stottle-angular-blob-storage
 *
 */

import { from, Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { TransferProgressEvent } from '@azure/core-http-compat'
import { PagedAsyncIterableIterator } from '@azure/core-paging';
import { BlockBlobClient } from '@azure/storage-blob';
import { Subscriber } from 'rxjs';
import { distinctUntilChanged, scan } from 'rxjs/operators';
import { AbortController } from '@azure/abort-controller';

import {
  BlobContainerRequest,
  BlobFileRequest,
  BlobStorageRequest,
  BlobStorageUploadParameters,
} from './azure-storage';

import { BlobServiceClient } from '@azure/storage-blob';

@Injectable({
  providedIn: 'root',
})
export class BlobStorageService {
  constructor() {}

  getContainers(request: BlobStorageRequest) {
    const blobServiceClient = this.buildClient(request);
    return this.asyncToObservable(blobServiceClient.listContainers());
  }

  listBlobsInContainer(request: BlobContainerRequest) {
    const containerClient = this.getContainerClient(request);
    return this.asyncToObservable(containerClient.listBlobsFlat());
  }

  downloadBlobItem(request: BlobFileRequest) {
    const blockBlobClient = this.getBlockBlobClient(request);
    return from(blockBlobClient.download());
  }

  deleteBlobItem(request: BlobFileRequest) {
    const blockBlobClient = this.getBlockBlobClient(request);
    return from(blockBlobClient.delete());
  }

  uploadToBlobStorage(
    file: File,
    request: BlobFileRequest
  ): BlobStorageUploadParameters {
    const blockBlobClient = this.getBlockBlobClient(request);
    return this.uploadFile(blockBlobClient, file);
  }

  private getBlockBlobClient(request: BlobFileRequest) {
    const containerClient = this.getContainerClient(request);
    return containerClient.getBlockBlobClient(request.filename);
  }

  private getContainerClient(request: BlobContainerRequest) {
    const blobServiceClient = this.buildClient(request);
    return blobServiceClient.getContainerClient(request.containerName);
  }

  private buildClient(options: BlobStorageRequest) {
    return BlobServiceClient.fromConnectionString(
      `BlobEndpoint=${options.storageUri};` +
        `SharedAccessSignature=${options.storageAccessToken}`
    );
  }

  private uploadFile(
    blockBlobClient: BlockBlobClient,
    file: File
  ): BlobStorageUploadParameters {
    let abortController = new AbortController();
    let uploadProgress = new Observable<number>((observer) => {
      blockBlobClient
        .uploadBrowserData(file, {
          onProgress: this.onProgress(observer),
          blobHTTPHeaders: {
            blobContentType: file.type,
          },
          abortSignal: abortController.signal,
        })
        .then(
          this.onUploadComplete(observer, file),
          this.onUploadError(observer)
        );
    }).pipe(distinctUntilChanged());

    return {
      uploadAbortController: abortController,
      uploadProgressObservable: uploadProgress,
    };
  }

  private onUploadError(observer: Subscriber<number>) {
    return (error: any) => observer.error(error);
  }

  private onUploadComplete(observer: Subscriber<number>, file: File) {
    return () => {
      observer.next(file.size);
      observer.complete();
    };
  }

  private onProgress(observer: Subscriber<number>) {
    return (progress: TransferProgressEvent) =>
      observer.next(progress.loadedBytes);
  }

  private asyncToObservable<T, TService>(
    iterable: PagedAsyncIterableIterator<T, TService>
  ) {
    return new Observable<T>(
      (observer) =>
        void (async () => {
          try {
            for await (const item of iterable as AsyncIterable<T>) {
              if (observer.closed) return;
              observer.next(item);
            }
            observer.complete();
          } catch (e) {
            observer.error(e);
          }
        })()
    ).pipe(
      scan<T, T[]>((items, item) => [...items, item], []),
      startWith([] as T[])
    );
  }
}
