import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LRCache } from '../api-cache';
import { LRMixin } from '../api-mixins';
import { SiteGraphic } from './site-graphic';
@Injectable({
  providedIn: 'root',
})
export class SiteGraphicService extends LRMixin<SiteGraphic> {
  protected modelBaseUrl: string;

  protected lrCache = new LRCache(5 * 60 * 1000);

  constructor(protected http: HttpClient) {
    super(environment.BACKEND_BASE_URL + 'site-graphic/', http);
  }
}
