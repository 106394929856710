import {
  VibrationReportInterface,
  VibrationReportVerboseInterface,
} from './vibration-report';

export function getVibrationReportSeverityLevelLabelClass(
  vibrationReport: VibrationReportInterface | VibrationReportVerboseInterface
): string {
  let severityLevel = vibrationReport.severity_level.toLowerCase();

  if (severityLevel === null || severityLevel === 'incomplete data')
    return 'app-label__neutral';
  if (severityLevel == 'good' || severityLevel === 'acceptance report')
    return 'app-label__good';
  if (severityLevel == 'warning') return 'app-label__warning';
  if (severityLevel == 'alarm') return 'app-label__alarm';

  return 'app-label__neutral';
}
