export function argSort(data: number[]): number[] {
  let indices: number[] = new Array(data.length);
  for (var i = 0; i < data.length; ++i) indices[i] = i;
  indices.sort((a, b) => {
    return data[a] < data[b] ? -1 : data[a] > data[b] ? 1 : 0;
  });
  return indices;
}

export function sortObjects<T>(items: T[], sortAttrName: any): T[] {
  items.sort((a, b) => {
    let returnValue = 0;
    if (a[sortAttrName] < b[sortAttrName]) returnValue = -1;
    if (a[sortAttrName] > b[sortAttrName]) returnValue = 1;
    return returnValue;
  });
  return items;
}
