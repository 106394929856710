import { isNumber } from 'src/app/ts-tools/type-check';

export interface User {
  account_enabled: boolean;
  job_title: string | null;
  department: string | null;
  country: string | null;
  mobile: number | null;
  telephone_number: number | null;
  user_type: string | null;
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  is_superuser: boolean;
}

export interface UserProfileInterface {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
}

export function user2String(
  user: UserProfileInterface | User | string | number
): string {
  if (!user) return;
  if (typeof user === 'number') {
    return `user with ID ${user}`;
  } else if (typeof user === 'string') {
    return user;
  } else if (user.first_name && user.last_name) {
    return `${user.first_name} ${user.last_name}`;
  } else if (user.email) {
    return user.email;
  }
  return `user with ID ${user.id}`;
}

export class User {
  user_id: number;
  email: string;
  is_superuser: boolean;
  first_name: string;
  last_name: string;
  MSUser: boolean;

  company_id: number;
  company_name: string;
  company_logo_light_bg: string;
  company_logo_dark_bg: string;
  company_is_supercompany: boolean;

  constructor(
    user_id,
    email,
    is_superuser,
    first_name,
    last_name,

    company_id,
    company_name,
    company_logo_light_bg,
    company_logo_dark_bg,
    company_is_supercompany,
  ) {
    this.user_id = user_id;
    this.email = email;
    this.is_superuser = is_superuser;
    this.first_name = first_name;
    this.last_name = last_name;
    this.MSUser = false;

    this.company_id = company_id;
    this.company_name = company_name;
    this.company_logo_light_bg = company_logo_light_bg;
    this.company_logo_dark_bg = company_logo_dark_bg;
    this.company_is_supercompany = company_is_supercompany;
  }
}
