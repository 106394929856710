import { BehaviorSubject } from 'rxjs';
import { ListMixin } from './api-mixins';

export interface ApiListParameters<T> {
  filterParameters?: T;
  search?: string;
  ordering?: string;
  fieldsToExtend?: string[];
  setsToAdd?: string[];
  pageSize?: number;
  page?: number;
}

/**
 * A class for controlling API list endpoints.
 * You can change page, and specify endpoint parameters.
 *
 * The api list response data si emitted to 'responseData' which is a BehaviorSubject. Subscribe to this
 * to get the latest page change data.
 */
export class ApiListController<DatabaseType, DatabaseApiFilterType> {
  responseData = new BehaviorSubject<DatabaseType[]>([]); // Data from current page
  dataTotal: number = null; // Total number of elements
  isLoading = false; // Is paginator loading data

  private currentPage: number = null;
  private maxPageNumber: number = null;
  private currentApiFilter: object = null;

  constructor(
    public pageSize: number,
    private apiListService: ListMixin<DatabaseType>,
    private baseApiListParameters: ApiListParameters<DatabaseApiFilterType> = null
  ) {
    if (this.baseApiListParameters)
      this.currentApiFilter = {
        ...this.formatApiListParameters(this.baseApiListParameters),
      };
  }

  private formatApiListParameters(
    apiListParameters: ApiListParameters<DatabaseApiFilterType>
  ): { [apiParameter: string]: string } {
    let apiParameters = {};
    if (apiListParameters.filterParameters)
      apiParameters = {
        ...apiParameters,
        ...apiListParameters.filterParameters,
      };
    if (apiListParameters.search)
      apiParameters['search'] = apiListParameters.search;
    if (apiListParameters.ordering)
      apiParameters['ordering'] = apiListParameters.ordering;
    if (apiListParameters.fieldsToExtend)
      apiParameters['extend'] = apiListParameters.fieldsToExtend;
    if (apiListParameters.setsToAdd)
      apiParameters['sets'] = apiListParameters.setsToAdd;
    if (apiListParameters.page) apiParameters['page'] = apiListParameters.page;
    if (apiListParameters.pageSize)
      apiParameters['pageSize'] = apiListParameters.pageSize;

    return apiParameters;
  }

  /**
   * Load page 1 with provided filter parameters and vales
   * @param filter filter parameters and values
   */
  applyApiFilter(filter: object) {
    this.currentApiFilter = { ...filter };
    if (this.baseApiListParameters)
      this.currentApiFilter = {
        ...this.currentApiFilter,
        ...this.formatApiListParameters(this.baseApiListParameters),
      };

    this.currentPage = null;
    this.maxPageNumber = null;
    this.changePage(1);
  }

  /**
   * Resets filter and loads page 1
   */
  clearApiFilter() {
    this.currentApiFilter = null;
    if (this.baseApiListParameters)
      this.currentApiFilter = this.formatApiListParameters(
        this.baseApiListParameters
      );
    this.resetAndLoadPage1();
  }

  private resetAndLoadPage1() {
    this.currentPage = null;
    this.maxPageNumber = null;
    this.dataTotal = null;
    this.changePage(1);
  }

  /**
   * Change pagination page
   * @param pageNumber Page to change to
   * @returns
   */
  async changePage(pageNumber: number) {
    if (pageNumber === this.currentPage) return;
    if (this.pageSize === 0) return;
    if (!this.maxPageNumber) pageNumber = 1;
    else if (pageNumber > this.maxPageNumber) return;

    if (pageNumber < 1) return;

    this.responseData.next([]);

    this.isLoading = true;
    this.currentPage = pageNumber;
    let currentApiFilter = this.currentApiFilter;

    let response = await this.apiListService.list({
      page_size: this.pageSize,
      page: this.currentPage,
      ...this.currentApiFilter,
    });

    if (
      this.currentPage === pageNumber && // If page has changed, skip updating data.
      currentApiFilter === this.currentApiFilter // If filter has changed, skip updating data.
    ) {
      this.isLoading = false;
      this.responseData.next(response.results);
      this.dataTotal = response.count;
      this.maxPageNumber = Math.ceil(this.dataTotal / this.pageSize);
    }
  }
}
