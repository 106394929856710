<div class="machine-group-status-summary-main-container" *ngIf="isGroups()">
  <div class="status-container" *ngFor="let group of machineGroups">
    <span class="groupName">
      {{formatGroupName(group)}}
    </span>
    <span class="label__alarm" matTooltip="Machines with status: Alarm"
      [class.zero]="groupStatus[group].alarm == 0">{{groupStatus[group].alarm}}</span>
    <span class="label__warning" matTooltip="Machines with status: Warning"
      [class.zero]="groupStatus[group].warning == 0">{{groupStatus[group].warning}}</span>
    <span class="label__good" matTooltip="Machines with status: Good"
      [class.zero]="groupStatus[group].good == 0">{{groupStatus[group].good}}</span>
  </div>
</div>
