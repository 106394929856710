<div class="display-4" style="text-align: center;">CM Program Performance</div>

<hr>

<div class="container-fluid row">
  <div class="col-md-12">
    <mat-form-field>
      <input matInput #FromDate [ngModel]="filter.from_date | async" [min]="minDate" [max]="filter.to_date"
        [matDatepicker]="picker1" placeholder="Choose a start date"
        (ngModelChange)="updateDate(filter.from_date, FromDate.value)">
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
      <input matInput #ToDate [ngModel]="filter.to_date | async" [min]="filter.from_date.value" [max]="maxDate"
        [matDatepicker]="picker2" placeholder="Choose an end date"
        (ngModelChange)="updateDate(filter.to_date, ToDate.value)">
      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
      <mat-datepicker #picker2></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="col-md-4">
    <!-- <mat-form-field>
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyTreeFilter($event)">
        </mat-form-field> -->
    <div class="mat-elevation-z8" style="padding: 30px;">
      <div *ngFor="let family of siteTreeStructure">
        <mat-checkbox (change)="toggle_site_group_family($event.checked, family.name)" [(ngModel)]="family.checked">
          <b style="font-size: 22;">
            {{family.name}}
          </b>
        </mat-checkbox>

        <hr>

        <div *ngFor="let group of family.site_groups">
          <button mat-icon-button disabled></button>

          <mat-checkbox (change)="toggle_site_group($event.checked, group.name)" [(ngModel)]="group.checked">
            <b style="font-size: 14;">
              {{group.name}}
            </b>
          </mat-checkbox>

          <div *ngFor="let siteNode of group.site_nodes">
            <button mat-icon-button disabled></button>
            <button mat-icon-button disabled></button>
            <mat-checkbox (change)="toggle_site($event.checked, siteNode.id)" [(ngModel)]="siteNode.checked">
              {{siteNode.name}}
            </mat-checkbox>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="col-md-8">
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="tableDataSource" matSort class="container-fluid">

        <ng-container matColumnDef="site_group_family">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Fleet Group </th>
          <td mat-cell *matCellDef="let row"> {{row.site_group_family || '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="site_group">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Fleet </th>
          <td mat-cell *matCellDef="let row"> {{row.site_group || '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="site_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Vessel </th>
          <td mat-cell *matCellDef="let row"> {{row.site_name}} </td>
        </ng-container>

        <ng-container matColumnDef="good">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Measurements received on time </th>
          <td mat-cell *matCellDef="let row">
            <div class="container">
              <mat-progress-bar mode="determinate" [value]="(100/row.month_summary.length)*row.good"
                matTooltip="{{row.good}}/{{row.month_summary.length}}"></mat-progress-bar>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="detail">
          <th mat-header-cell *matHeaderCellDef> Detail </th>
          <td mat-cell *matCellDef="let row"> <button (click)="openDialog(row)">Detail</button> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

    </div>
  </div>
</div>
